import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UIStateService } from './ui-state.service';
import { DialogWrapper } from '../components/flex-dialog/dialog-wrapper.component';
import { ClassRegistry } from './class-registry';
import { SingletonBase } from '../utils/singleton';
import { IFlexDialogConfig, DialogLayoutConfig, DialogComponentClass } from '../components/flex-dialog/flex-dialog.i';

@Injectable()
export class DialogService extends SingletonBase {
  private _forcedPannelClass: string;

  constructor(
    public dialog: MatDialog,
    public screenSize: UIStateService
  ) {
    super();
  }

  setForcedPannelClass(val: string): void {
    this._forcedPannelClass = val;
  }

  openDialog(
    component,
    options: IFlexDialogConfig,
    layoutOptions?: DialogLayoutConfig,
    onValueReturned?: (data) => any
  ): MatDialogRef<any> {
    const matOptions: MatDialogConfig = options ? options : {};
    matOptions.data = matOptions.data || {};
    matOptions.data.component = component;
    if (layoutOptions) {
      matOptions.data.layoutOptions = layoutOptions;
    }

    return this.openModal(DialogWrapper, matOptions, onValueReturned);
  }

  /*
  * This method should be used to open a dialog that has a title, content and action buttons.
  * Documentation: https://github.com/qmphan/smartcontact-projects/wiki/S%E1%BB%AD-d%E1%BB%A5ng-Angular-Material-Dialog
  */
  openDialog2(viewComponent: DialogComponentClass | string, options?: IFlexDialogConfig, onValueReturned?: (data) => any): MatDialogRef<any> {
    const matOptions: MatDialogConfig = {};
    if (typeof viewComponent === 'string') {
      viewComponent = ClassRegistry.getClassByName(viewComponent);
    }
    if (!viewComponent) {
      throw new Error(`Invalid dialog component ${viewComponent}`);
    }

    if (viewComponent['modalConfig']) {
      _.extend(matOptions, viewComponent['modalConfig']);
    }
    if (options) {
      _.extend(matOptions, options);
    }
    matOptions.data = matOptions.data || {};
    matOptions.data.viewComponent = viewComponent;
    matOptions.data.paddingContent = options && options.padding === false ? false : true;

    return this.openModal2(DialogWrapper, matOptions, onValueReturned);
  }

  openDialog3(title: string, viewComponent: DialogComponentClass | string, options?: IFlexDialogConfig, onValueReturned?: (data) => any): MatDialogRef<any> {
    const matOptions: MatDialogConfig = {};
    if (typeof viewComponent === 'string') {
      viewComponent = ClassRegistry.getClassByName(viewComponent);
    }
    if (!viewComponent) {
      throw new Error(`Invalid dialog component ${viewComponent}`);
    }

    if (viewComponent['modalConfig']) {
      _.extend(matOptions, viewComponent['modalConfig']);
    }
    if (options) {
      _.extend(matOptions, options);
    }
    matOptions.data = matOptions.data || {};
    matOptions.data.viewComponent = viewComponent;
    matOptions.data.paddingContent = options && !!options.padding;
    matOptions.data.dialogTitle = title;

    return this.openModal2(DialogWrapper, matOptions, onValueReturned);
  }

  // tslint:disable-next-line:max-line-length
  openSideDialog(viewComponent: DialogComponentClass | string, options: IFlexDialogConfig, onValueReturned?: (data) => any): MatDialogRef<any> {
    const matOptions: MatDialogConfig = {};
    if (typeof viewComponent === 'string') {
      viewComponent = ClassRegistry.getClassByName(viewComponent);
    }
    if (!viewComponent) {
      throw new Error(`Invalid dialog component ${viewComponent}`);
    }

    if (viewComponent['modalConfig']) {
      _.extend(matOptions, viewComponent['modalConfig']);
    }
    if (options) {
      _.extend(matOptions, options);
    }
    matOptions.data = matOptions.data || {};
    matOptions.data.viewComponent = viewComponent;
    matOptions.data.paddingContent = options && options.padding === false ? false : true;
    matOptions.position = { top: '0', right: '0', bottom: '0' };

    return this.openSideModal(DialogWrapper, matOptions, onValueReturned);
  }

  waitModal(dialogComponentOrName: any, options?: IFlexDialogConfig, onValueReturned?: (data) => any): Promise<any> {
    return this.openModal(dialogComponentOrName, options, onValueReturned).afterClosed().toPromise();
  }

  /*
  * @Deprecated: This method will soon be removed and should not be used to open a dialog
  * Use method openDialog2 instead
  */
  openModal(dialogComponentOrName: any, options?: IFlexDialogConfig, onValueReturned?: (data) => any): MatDialogRef<any> {
    let dialogComponent = dialogComponentOrName;
    if (typeof dialogComponent === 'string') {
      dialogComponent = ClassRegistry.getClassByName(dialogComponent);
    }
    if (!dialogComponent) {
      throw new Error(`Invalid dialog component ${dialogComponentOrName}`);
    }

    const mat_options: MatDialogConfig = options ? options : {};
    _.defaults(mat_options, {
      autoFocus: false
    });

    // custom size
    const attr_table = {
      none: {
      },
      xs: {
        width: '80%',
        // height: '80%',
        minWidth: '200px',
        maxWidth: '400px',
        // maxHeight: '300px'
      },
      s: {
        width: '80%',
        // height: '80%',
        minWidth: '300px',
        maxWidth: '600px'
      },
      l: {
        width: '80%',
        // height: '80%',
        minWidth: '300px',
        maxWidth: '90%'
      },
      fs: {}
    };

    const componentDialogAttrs: IFlexDialogConfig = dialogComponent.wephoneDialogAttrs;
    const size = (options && options.size) || (componentDialogAttrs && componentDialogAttrs.size) || 'l';

    _.defaults(mat_options, componentDialogAttrs, attr_table[size]);
    mat_options.panelClass = this._forcedPannelClass || `wephone-dialog-panel-${size}`;

    const dialogRef: MatDialogRef<any> = this.dialog.open(dialogComponent, mat_options);

    if (onValueReturned) {
      dialogRef.afterClosed().subscribe(result => {
        return onValueReturned(result);
      });
    }

    return dialogRef;
  }

  private openModal2(dialogComponentOrName: any, options?: IFlexDialogConfig, onValueReturned?: (data) => any): MatDialogRef<any> {
    let dialogComponent = dialogComponentOrName;
    if (typeof dialogComponent === 'string') {
      dialogComponent = ClassRegistry.getClassByName(dialogComponent);
    }
    if (!dialogComponent) {
      throw new Error(`Invalid dialog component ${dialogComponentOrName}`);
    }

    const mat_options: MatDialogConfig = options ? options : {};

    _.defaults(mat_options, {
      autoFocus: false,
      disableClose: true,
    });

    // custom size
    const attr_table = {
      none: {
      },
      xs: {
        width: '80%',
        height: '80%',
        minWidth: '200px',
        maxWidth: '400px',
      },
      s: {
        width: '80%',
        height: '80%',
        minWidth: '300px',
        maxWidth: '600px'
      },
      l: {
        width: '80%',
        height: '80%',
        minWidth: '300px',
        maxWidth: '90%'
      },
    };

    const componentDialogAttrs: IFlexDialogConfig = dialogComponent.modalConfig as IFlexDialogConfig;
    const size = (options && options.size) || (componentDialogAttrs && componentDialogAttrs.size) || 'l';

    _.defaults(mat_options, componentDialogAttrs, attr_table[size]);
    mat_options.panelClass = this._forcedPannelClass || `wephone-dialog-panel-${size}`;
    console.log('mat_options', mat_options);

    const dialogRef: MatDialogRef<any> = this.dialog.open(dialogComponent, mat_options);

    if (onValueReturned) {
      dialogRef.afterClosed().subscribe(result => {
        return onValueReturned(result);
      });
    }

    return dialogRef;
  }

  openSideModal(dialogComponentOrName: any, options?: IFlexDialogConfig, onValueReturned?: (data) => any): MatDialogRef<any> {
    let dialogComponent = dialogComponentOrName;
    if (typeof dialogComponent === 'string') {
      dialogComponent = ClassRegistry.getClassByName(dialogComponent);
    }
    if (!dialogComponent) {
      throw new Error(`Invalid dialog component ${dialogComponentOrName}`);
    }

    const mat_options: MatDialogConfig = options ? options : {};
    _.defaults(mat_options, {
      autoFocus: false,
      disableClose: true
    });

    // custom size
    const attr_table = {
      none: {
      },
      xs: {
        width: '30%',
        height: '100%',
        minWidth: '300px',
        maxWidth: '100%',
      },
      s: {
        width: '40%',
        height: '100%',
        minWidth: '400px',
        maxWidth: '100%',
      },
      l: {
        width: '50%',
        height: '100%',
        minWidth: '500px',
        maxWidth: '100%',
      }
    };

    const componentDialogAttrs: IFlexDialogConfig = dialogComponent.wephoneDialogAttrs;
    const size = (options && options.size) || (componentDialogAttrs && componentDialogAttrs.size) || 'l';

    _.defaults(mat_options, componentDialogAttrs, attr_table[size]);
    mat_options.panelClass = `wephone-side-dialog-panel`;

    console.log('mat_options: ', mat_options);
    const dialogRef: MatDialogRef<any> = this.dialog.open(dialogComponent, mat_options);

    if (onValueReturned) {
      dialogRef.afterClosed().subscribe(result => {
        return onValueReturned(result);
      });
    }

    return dialogRef;
  }

  confirmDialog(title: string, message: string, ok_callback: () => any): Promise<any> {
    const dlg = this.openModal(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        onConfirm: ok_callback
      },
      size: 'none'
    });

    return dlg.afterClosed().toPromise();
  }

  showAlert(title: string, message: string): Promise<any> {
    const dlg = this.openModal(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        showButtons: ['ok']
      },
      size: 'none'
    });

    return dlg.afterClosed().toPromise();
  }
}
