import { Component, Inject, OnInit } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { IFlexDialogConfig, DialogActionButton, DialogService, Colors } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-turn-on-mfa',
  templateUrl: './user-turn-on-mfa.component.html',
  styleUrls: ['./user-turn-on-mfa.component.scss']
})
export class UserTurnOnMfaComponent extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    hasBackdrop: true,
    size: 'xs'
  };

  dialogTitle = _tk('login.mfa_confirm_turn_on');
  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.ok'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY
    }
  ];

  mfaQrCodeUrl: string;

  constructor(
    dialogRef: MatDialogRef<UserTurnOnMfaComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      mfaQrCodeUrl: string;
    },
    private readonly fb: FormBuilder,
    private readonly authService: AuthenticationService,
    // private readonly dialogService: DialogService
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.mfaQrCodeUrl = this.dialogData.mfaQrCodeUrl;

    this.form = this.fb.group({
      mfa_code: ['', Validators.compose([Validators.required])]
    });
  }

  async submit(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      const result = await this.authService.confirmTurnOnMafAuth({
        _mfa_code: this.form.get('mfa_code').value
      });
  
      console.log('result', result);
  
      this.dialogRef.close(this.form.value);
      this.showInfo(_ti('public.message.update_success'));
    } catch (error) {
      this.showErrorMessage(error, _ti('public.message.update_failed'));
    }
  }
}
