import { datafield, Entity } from '@wephone-core/model/model';

export class EnterpriseObjectTemplateEntity extends Entity {
  static object_type_id = 'enterprise_object_template';

  @datafield
  id: number;
  @datafield
  enterprise_id: number;
  @datafield
  template_name: string;
  @datafield
  template_variables: { [key: string]: string };
  @datafield
  template_configs: { [key: string]: string | boolean | number };
}
