<div class="page-root">
  <div class="page-header">
    <h2>{{ 'enterprise_info.title'|translate }}</h2>
  </div>

  <div class="table-container">
    <form [formGroup]="form" fxLayout="column" fxFlex="grow" *ngIf="form">
      <mat-card class="item-detail shadow-none" fxLayout="column" fxFlex="grow">
        <div fxLayout="column" fxFlex="grow">
          <mat-toolbar class="mat-primary" fxFlex="62px" fxLayout="row" fxLayoutAlign="start center">
            <div class="button-container" *ngIf="formHasChanged()">
              <button type="button" mat-button class="border-white-button" (click)="update()">
                {{ 'public.save'|translate }}
              </button>
              <button type="button" mat-button class="border-white-button ml-1" (click)="resetForm()">
                {{ 'public.cancel'|translate }}
              </button>
            </div>
          </mat-toolbar>

          <div fxLayout="column" fxFlex="grow" class="side-pannel-content">

            <div class="wrapper">
              <div class="col-left">
                <div class="inner">
                  <ul class="stepper stepper-vertical">
                    <li *ngFor="let step of steps; let i=index;">
                      <a (click)="mainArea.scrollToElement(step.id)" class="step {{ step.id }}"
                        [ngClass]="{'active': mainArea.activeAnchor === step.id}">
                        <span class="circle">{{ i+1 }}</span>
                        <span class="label">{{ step.label | translate }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
                <div class="inner">
                  <mat-card class="shadow-none" id="info" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">info</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.title' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.name' | translate }}</mat-label>
                          <input matInput placeholder="{{ 'enterprise_info.name' | translate }}" formControlName="name">
                          
                          <mat-error [flexFormControlError]="form.get('name')"></mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.id' | translate }}</mat-label>
                          <input matInput formControlName="domain">
                        </mat-form-field>
                      </div>
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.country' | translate }}</mat-label>
                          <app-country-global-select formControlName="country"
                            [placeholder]="'enterprise_info.country' | translate">
                          </app-country-global-select>

                          <mat-error [flexFormControlError]="form.get('country')"></mat-error>
                        </mat-form-field>

                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.timezone' | translate }}</mat-label>
                          <timezone-select-form-input formControlName="timezone" [country]="form.get('country').value"
                            [placeholder]="'enterprise_info.timezone' | translate">
                          </timezone-select-form-input>
                          
                          <mat-error [flexFormControlError]="form.get('timezone')"></mat-error>

                          <!-- <mat-error *ngIf="form.get('country').hasError('timezone')">
                            {{ 'form.validator.required'|translate }}
                          </mat-error> -->
                        </mat-form-field>
                      </div>

                      <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'input.address.label' | translate }}</mat-label>
                          <textarea matInput placeholder="{{ 'input.address.placeholder' | translate }}"
                            formControlName="address"></textarea>
                        </mat-form-field>
                      </div>

                      <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.vat_number' | translate }}</mat-label>
                          <input matInput placeholder="{{ 'enterprise_info.vat_number' | translate }}"
                            [(ngModel)]="myEnterprise.vat_number">
                        </mat-form-field>
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.billing_contact_email' | translate }}</mat-label>
                          <input matInput placeholder="{{ 'enterprise_info.billing_contact_email' | translate }}"
                            [(ngModel)]="myEnterprise.billing_contact_email">
                        </mat-form-field>
                      </div> -->

                      <!-- <div fxLayout="row" fxLayoutAlign="end center">
                        <button fxFlex="initial" mat-stroked-button color="green" (click)="update()">
                          {{ 'public.update' | translate }}
                        </button>
                      </div> -->
                    </mat-card-content>
                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <!--
                  <mat-card class="shadow-none " id="subscription_and_payment" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">subscriptions</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.subscription_and_payment' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex class="pr-1">
                          <span>{{ 'enterprise_info.transform_premium.go_premium' | translate }}</span>
                        </div>
                        <div fxFlex="25" align="end">
                          <button mat-button color="red"
                            (click)="openModalTransformPremium()">{{ 'public.go' | translate }}</button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                  <mat-divider class="section-divider"></mat-divider>
                  

                  <mat-card class="shadow-none " id="payment_info" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.payment_info' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <enterprise-payment-info></enterprise-payment-info>
                     <div fxLayout="row" fxLayoutAlign="start center" class="pt-1">
                        <div fxFlex class="pr-1">
                          <strong>{{ 'credit_recharge.content.remaining_credit' | translate }}: </strong>
                          {{ (myEnterprise.remaining_credit || '0') | currency:(myEnterprise.currency || 'EUR') }}
                        </div>
                        <div fxFlex="25" align="end">
                          <button mat-button color="blue" (click)="openModalPayCreditRecharge()">
                            {{'credit_recharge.content.recharge' | translate }}
                          </button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
            
                  <mat-divider class="section-divider"></mat-divider> -->

                  <mat-card class="shadow-none " id="outbound_limit" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">call</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.calling_number' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'enterprise_info.default_calling_number' | translate }}</mat-label>
                        <calling-profile-select-form-input formControlName="calling_profile_id" [noDefaultOption]="true"
                          placeholder="{{ 'enterprise_info.default_calling_number' | translate}}">
                        </calling-profile-select-form-input>
                        
                        <mat-error [flexFormControlError]="form.get('calling_profile_id')"></mat-error>
                      </mat-form-field>

                      <mat-form-field class="full-width" *ngIf="isBusinessHotel">
                        <mat-label>{{ 'enterprise_info.default_room_calling_number' | translate }}</mat-label>
                        <calling-profile-select-form-input formControlName="room_calling_profile_id"
                          [noDefaultOption]="true"
                          placeholder="{{ 'enterprise_info.default_room_calling_number' | translate}}">
                        </calling-profile-select-form-input>
                        
                        <mat-error [flexFormControlError]="form.get('room_calling_profile_id')"></mat-error>
                      </mat-form-field>
                    </mat-card-content>
                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <mat-card class="shadow-none" id="default_bo_url" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">link</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{'call_queue.content.open_url' | translate}}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <mat-form-field class="full-width">
                        <mat-label>{{ 'call_queue.content.bo_url'|translate }}</mat-label>
                        <input matInput formControlName="default_bo_url"
                          placeholder="{{ 'call_queue.content.bo_url'|translate }}">
                        <button matSuffix mat-icon-button type="button" (click)="clearValue($event, 'default_bo_url')"
                          [disabled]="!form.get('default_bo_url').value">
                          <mat-icon>clear</mat-icon>
                        </button>
                        
                        <mat-error [flexFormControlError]="form.get('default_bo_url')"></mat-error>
                      </mat-form-field>
                    </mat-card-content>
                  </mat-card>

                  <mat-divider class="section-divider"></mat-divider>

                  <mat-card class="shadow-none" id="phone_settings" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">sip</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{'enterprise_info.step.phone_settings' | translate}}</h2>
                      </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="form-group">
                      <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.telecom_params.vlan_phone'|translate }}</mat-label>
                          <input matInput type="number" formControlName="vlan_phone"
                            placeholder="{{ 'enterprise_info.telecom_params.vlan_phone'|translate }}">
                          <button matSuffix mat-icon-button type="button" (click)="clearValue($event, 'vlan_phone')"
                            [disabled]="!form.get('vlan_phone').value">
                            <mat-icon>clear</mat-icon>
                          </button>
                          
                          <mat-error [flexFormControlError]="form.get('vlan_phone')"></mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex>
                          <mat-label>{{ 'enterprise_info.telecom_params.vlan_pc'|translate }}</mat-label>
                          <input matInput type="number" formControlName="vlan_pc"
                            placeholder="{{ 'enterprise_info.telecom_params.vlan_pc'|translate }}">
                          <button matSuffix mat-icon-button type="button" (click)="clearValue($event, 'vlan_pc')"
                            [disabled]="!form.get('vlan_pc').value">
                            <mat-icon>clear</mat-icon>
                          </button>
                          
                          <mat-error [flexFormControlError]="form.get('vlan_pc')"></mat-error>
                        </mat-form-field>
                      </div> -->

                      <div>
                        <mat-form-field class="full-width">
                          <did-select-form-input formControlName="default_did_id" [placeholder]="'enterprise.content.default_outgoing_number' | translate"></did-select-form-input>
                          <button mat-icon-button matSuffix type="button" (click)="removeControlValue('default_did_id')" [disabled]="!hasControlValue('default_did_id')">
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>

                      <div class="full-width sound-container mt-1" *ngIf="!isBusinessSipTrunk">
                        <sound-upload fxFlex class="wrapper-sound-upload" [attr.data-ci]="'sound-upload-onhold-music'"
                          [fileEntry]="form.get('onhold_music_id').value"
                          title="{{'enterprise_info.telecom_params.onhold_music'|translate}}" [removable]="true"
                          (onChange)="updateOnholdMusic($event)">
                        </sound-upload>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <!-- <mat-divider class="section-divider"></mat-divider>
            
                  <mat-card class="shadow-none " id="billing_plan" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.billing_plan' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <app-enterprise-billing-plan></app-enterprise-billing-plan>
                    </mat-card-content>
            
                  </mat-card>
            
                  <mat-divider class="section-divider"></mat-divider>
            
                  <mat-card class="shadow-none " id="subscription" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">payment</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'client.content.subscription'|translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group" *ngIf="subscription && subscriptionPack">
                      <div>
                        <p>
                          <label>{{ 'client.content.subscription_package'|translate }}</label>
                          <strong class="mx-1" [attr.data-ci]="'subscription-name'">{{ subscriptionPack?.name }}</strong> -->
                  <!-- <button mat-stroked-button color="primary"
                            (click)="changeSubscription()">{{ 'did.form.change'|translate }}
                          </button> -->
                  <!-- </p>
                        <p *ngIf="subscription.expired_dt;else NoExpiredDtBlock">
                          <label>
                            {{ 'public.from'|translate }}
                            <strong [attr.data-ci]="'subscription-start_dt'">{{ subscription.start_dt | timeFormatDateShort }}</strong>
                          </label>
                          <label class="ml-1">
                            {{ 'public.to'|translate }}
                            <strong [attr.data-ci]="'subscription-expired_dt'">{{ subscription.expired_dt | timeFormatDateShort }}</strong>
                          </label> -->
                  <!-- <label>{{ 'client.content.subscription_expire_date'|translate }}</label> -->
                  <!-- </p>
                        <ng-template #NoExpiredDtBlock>
                        <p>
                          <label>
                            {{ 'public.since'|translate }}
                            <strong [attr.data-ci]="'subscription-start_dt'">{{ subscription.start_dt | timeFormatDateShort }}</strong>
                          </label>
                        </p>
                        </ng-template>
                      </div>
                    </mat-card-content>
                  </mat-card>
            
                  <mat-divider class="section-divider"></mat-divider>
            
                  <mat-card class="shadow-none " id="invoice_history" anchorPart>
                    <mat-card-header>
                      <div mat-card-avatar>
                        <h2><i class="material-icons">history</i></h2>
                      </div>
                      <mat-card-title>
                        <h2>{{ 'enterprise_info.step.invoice_history' | translate }}</h2>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="form-group">
                      <table mat-table [dataSource]="dataSource" class="flat-table" matSort>
                        <ng-container matColumnDef="invoice_number">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'invoice.content.invoice_id' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let invoice"> -->
                  <!-- <div [innerHTML]="renderId(invoice_number)"></div> -->
                  <!-- {{ '#' + invoice.invoice_number }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="invoice_dt">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'invoice.content.invoice_date' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let invoice">
                            {{ invoice.invoice_dt | timeFormatDateTimeShort }} -->
                  <!-- <div [innerHTML]="renderDate(invoice_dt)"></div> -->
                  <!-- </td>
                        </ng-container>
                        <ng-container matColumnDef="total">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'invoice.content.total' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <span>{{ element.total | currency:element.currency:'symbol-narrow': '0.2-2' }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="download">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'public.button.download' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <button mat-icon-button aria-label="'public.button.download' | translate" color="primary"
                              [disabled]="!element.isPdfReady" (click)="downloadCsv(element)">
                              <mat-icon>cloud_download</mat-icon>
                            </button>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                    </mat-card-content>
                  </mat-card> -->
                </div>
              </div>
            </div>


          </div>
        </div>
      </mat-card>
    </form>
  </div>
</div>
