import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { FileEntryEntity, DefaultSoundType } from '@wephone-core/model/entity/fileentry';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { DialogService, ToastService } from '@wephone-utils';
import { ChangeSoundModal } from '../change-sound/change-sound';
import { TranslatePipe } from '@ngx-translate/core';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { LocalManager } from '@wephone-core/service/local_manager';
import { _ti } from '@wephone-translation';
import { FlexBaseComponent } from '@wephone-core-ui';
import { EnterpriseTelecomParamsRepository } from '@wephone-core/model/repository/enterprise_telecom_params';
import { EnterpriseTelecomParamsEntity } from '@wephone-core/model/entity/enterprise_telecom_params';

/**
 * Generated class for the SoundUploadComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'sound-upload',
  templateUrl: 'sound-upload.html',
  styleUrls: ['sound-upload.scss'],
  providers: [TranslatePipe]
})
export class SoundUploadComponent extends FlexBaseComponent implements OnInit, OnChanges {
  @Input() fileEntry: FileEntryEntity;
  // @Input() uploadReplace: number; // File-entry-id
  @Input() lang: string;
  @Input() defaultSound: DefaultSoundType;
  @Input() removable = true;
  @Input() title: string;
  @Output() readonly onChange = new EventEmitter<any>();

  language: string;
  isDefault: boolean;
  private hasInitialized: boolean;
  private currentEnterpriseTelecomParams: EnterpriseTelecomParamsEntity;

  constructor(
    private readonly fileEntryService: FileEntryService,
    private readonly toast: ToastService,
    private readonly em: EntityManager,
    private readonly localeManager: LocalManager,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.hasInitialized = true;
    await super.ngOnInit();
    this.language = this.lang || this.localeManager.getLangForDefaultFileEntry();
    console.log('this.language', this.language);

  }

  protected async resolveData(): Promise<void> {
    await FileEntryRepository.getInstance().findAll();
    this.currentEnterpriseTelecomParams = await (EnterpriseTelecomParamsRepository.getInstance() as EnterpriseTelecomParamsRepository)
      .getCurrentEnterprsieTelecomParams();

    this.init();
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    this.init();
  }

  init(): void {
    this.isDefault = false;
    // Default language to upload is 'default'
    if (this.defaultSound && (!this.fileEntry || !this.fileEntry.id)) {
      if (this.defaultSound === 'music_on_hold') {
        this.fileEntry = this.currentEnterpriseTelecomParams && this.currentEnterpriseTelecomParams.onhold_music;
        this.isDefault = true;
      }

      if (!this.fileEntry) {
        this.fileEntry = this.em.getRepository<FileEntryRepository>('FileEntryRepository').getDefaultFileEntry(this.defaultSound);
        this.isDefault = true;
      }
    }

    console.log('is_ready', this.is_ready);
  }

  openDialogChangeSound(): void {
    const data = {
      fileEntry: this.fileEntry,
      lang: this.language,
      // uploadReplace: this.uploadReplace,
      // reusable: false,
      markFileAsTemp: false, // Mark as temporary in case of instant creation
      selectExisting: true,
      defaultSound: this.defaultSound,
      showInputName: true,
    };

    this.dialogService.openSideDialog(ChangeSoundModal, { data, size: 's' }, data_response => {
      if (!data_response) {
        console.log('Do cancel dialog');

        return;
      }
      const new_file_entry = data_response.file_entry;
      // const old_file_entry = this.fileEntry;

      // Not replace content anymore because all editable sound is sharing
      // this.hasReplaced = false;
      // If new file entry is not shared and old file entry is not shared too,
      // replace the content of file, that means nothing changed except file content
      // if (
      //   new_file_entry.id &&
      //   new_file_entry.is_shared !== 1 &&
      //   old_file_entry &&
      //   old_file_entry.id &&
      //   old_file_entry.is_shared !== 1
      // ) {
      //   this.fileEntryService.replaceFileEntry(new_file_entry.id, old_file_entry.id).then(
      //     response => {
      //       // this.hasReplaced = true;
      //       console.log('Replace file entry success', response);
      //     },
      //     response => {
      //       // this.hasReplaced = true;
      //       console.error('Replace file entry failure', response);
      //       if (response && response.error && response.error.message) {
      //         this.toast.showError(response.error.message);
      //       }
      //     }
      //   );
      // } else {
      // on change callback
      // this.hasReplaced = false;

      this.fileEntry = new_file_entry;
      console.log('onChange sound upload', new_file_entry);
      this.onChange.emit({ new_file_entry });
      // this.hasReplaced = true;
      // }
    });
  }

  removeEntry(): void {
    if (!this.fileEntry) {
      console.error('No file entry selected');

      return;
    }

    // this.dialogService.confirmDialog(
    //   _ti('dialogs.confirmation'),
    //   _ti('dialogs.fileentry.delete_confirm_msg'),
    //   () => {
    // if file entry is_shared, not remove the entry, otherwise remove the entry then remove the reference
    if (!this.fileEntry.is_shared) {
      this.fileEntryService.removeFileEntries([this.fileEntry.public_id]).then(
        response => {
          // this.fileEntry = null;
          if (response.data && response.data.error) {
            this.toast.showError(response.data.error.message);
          } else {
            this.onChange.emit({ new_file_entry: undefined, remove: true });
          }
        },
        error => {
          const error_message =
            _ti('fileentry.error.remove') +
            _ti(`fileentry.error.error_${error.code}`);
          this.toast.showError(error_message);
        }
      );
    } else {
      this.onChange.emit({ new_file_entry: undefined, remove: true });
    }
    //   }
    // );
  }
}
