<form [formGroup]="form" *ngIf="form">
    <table class="flex-table-info fix-table-info full-width">
        <tbody formArrayName="key_config">
            <tr>
                <td class="idxcolumn">#</td>
                <td class="lblcolumn">{{ 'sipphone.content.key_configs.type'|translate }}</td>
                <td class="lblcolumn">{{ 'sipphone.content.key_configs.data'|translate }}</td>
                <td class="lblcolumn">{{ 'sipphone.content.key_configs.label'|translate }}</td>
            </tr>

            <ng-container *ngFor="let keyConfigControl of keyConfigsControl.controls; let i=index;">
                <tr id="row_{{ i }}">
                    <td class="idxcolumn">
                        {{ i + 1 }}
                    </td>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>{{ 'sipphone.content.key_configs.type'|translate }}</mat-label>

                            <mat-select [attr.data-ci]="'sipphone-input-type'" 
                                [formControl]="keyConfigControl.get('type')"
                                (selectionChange)="onChangeType(keyConfigControl)">
                                <mat-option *ngFor="let t of types" [value]="t.value">{{ t.label|translate }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="keyConfigControl.get('type').invalid">
                                {{ 'form.validator.data_invalid' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <ng-container *ngIf="keyConfigControl.get('type').value !== 'undefined' && keyConfigControl.get('type').value !== 'phoneline'">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'sipphone.content.key_configs.data'|translate }}</mat-label>

                                <sipphone-select-form-input *ngIf="keyConfigControl.get('type').value == 'supervision'"
                                    [attr.data-ci]="'sipphone-input-data'"
                                    [formControl]="keyConfigControl.get('data')">
                                </sipphone-select-form-input>

                                <input type="text" matInput *ngIf="keyConfigControl.get('type').value == 'speeddial'"
                                    [attr.data-ci]="'sipphone-input-data'"
                                    [formControl]="keyConfigControl.get('data')"
                                    placeholder="{{ 'sipphone.content.key_configs.data'|translate }}">
                                <mat-error *ngIf="keyConfigControl.get('data').invalid">
                                    {{ 'form.validator.data_invalid' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="keyConfigControl.get('type').value !== 'undefined'">
                            <mat-form-field class="full-width">
                                <mat-label>{{ 'sipphone.content.key_configs.label'|translate }}</mat-label>

                                <input type="text" matInput [formControl]="keyConfigControl.get('label')"
                                    [attr.data-ci]="'sipphone-input-label'"
                                    placeholder="{{ 'sipphone.content.key_configs.label'|translate }}">

                                <mat-error *ngIf="keyConfigControl.get('label').invalid">
                                    {{ 'form.validator.data_invalid' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</form>
