<div fxFill fxLayout="column" *ngIf="!loading && fileEntry">
  <div class="wrapper">

    <div class="col-left" [fxHide.xs]="true">
      <div class="inner">
        <ul class="stepper stepper-vertical">
          <li *ngFor="let step of stepKeys; let i=index;">
            <a (click)="mainArea.scrollToElement(step)" class="step {{ step }}"
              [ngClass]="{'active': mainArea.activeAnchor === step}">
              <span class="circle">{{ i+1 }}</span>
              <span class="label">{{ steps[step] | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-right activeAnchor" flexAnchorArea #mainArea="flexAnchorArea">
      <div class="inner">
        <div id="characteristic" anchorPart>
          <div fxLayout="row" fxLayout.xs="column" class="md-grey-100" fxLayoutGap="10px">
            <h1 fxFlex class="font-weight-bold">{{ 'reusable_sound.content.characteristic' | translate }}</h1>
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="end center">
              <span country-flag *ngIf="currentLang.value != 'default'" [countryCode2]="currentLang.region">
              </span>
              <ng-container *ngIf="fileEntry.hasLang(currentLang.value); else addSoundByLang">
                <fileentry-player-button [filePublicId]="fileEntry.public_id" [lang]="currentLang.value"
                  [disabled]="!fileEntry.ready_for_playing" class="button-play">
                </fileentry-player-button>
                <button title="{{ 'reusable_sound.content.edit_sound' | translate }}" color="accent" mat-icon-button
                  mat-button-sm class="mx-1" (click)="editFileEntry(fileEntry)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button title="{{ 'public.button.download' | translate }}" *ngIf="fileEntry.ready_for_playing"
                  color="accent" mat-icon-button mat-button-sm (click)="downloadEntry(fileEntry)">
                  <mat-icon>save_alt</mat-icon>
                </button>
              </ng-container>
              <button title="{{ 'public.button.delete' | translate }}" color="warn" mat-icon-button mat-button-sm
                class="mx-1" (click)="deleteEntry()">
                <mat-icon>delete</mat-icon>
              </button>
              <ng-template #addSoundByLang>
                <button type="button" mat-icon-button (click)="openDialogChangeSound(fileEntry, currentLang.value)">
                  <mat-icon>add</mat-icon>
                </button>
              </ng-template>
            </div>
          </div>

          <form [formGroup]="form" (ngSubmit)="submitForm()">
            <div fxLayout="row" fxLayoutAlign="start end" fxFlexFill fxLayoutGap="10px" fxLayoutGap.xs="0"
              class="full-width">
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'reusable_sound.content.name' | translate }}</mat-label>
                  <input matInput placeholder="{{'reusable_sound.content.name' | translate}}" formControlName="name">

                  <mat-error [flexFormControlError]="form.get('name')"></mat-error>
                </mat-form-field>
              </div>
              <div fxFlex>
                <mat-form-field class="full-width">
                  <mat-label>{{ 'reusable_sound.content.alias' | translate }}</mat-label>
                  <input matInput placeholder="{{'reusable_sound.content.alias' | translate}}" formControlName="alias">

                  <mat-error [flexFormControlError]="form.get('alias')"></mat-error>
                </mat-form-field>
              </div>
              <input type="hidden" formControlName="replacedPublicId">
            </div>
          </form>

          <table class="flex-table-info full-width mt-2">
            <tbody>
              <tr>
                <td class="label font-weight-bold">{{ 'reusable_sound.content.creation_method' | translate }}</td>
                <td>{{ fileEntry.creation_method_label | translate }}</td>
              </tr>
              <ng-container *ngIf="fileEntry.tts_data">
                <tr>
                  <td class="label font-weight-bold">{{ 'sound_manager.content.text' | translate }}</td>
                  <td>{{ fileEntry.tts_data.text }}</td>
                </tr>
                <tr *ngIf="ttsLang">
                  <td class="label font-weight-bold">{{ 'sound_manager.content.lang' | translate }}</td>
                  <td>{{ ttsLang }}</td>
                </tr>
                <tr *ngIf="ttsVoice">
                  <td class="label font-weight-bold">{{ 'public.voice' | translate }}</td>
                  <td>{{ ttsVoice }}</td>
                </tr>
              </ng-container>
              <tr>
                <td class="label font-weight-bold">{{ 'reusable_sound.content.last_changed' | translate }}</td>
                <td>{{ fileEntry.modified_dt | timeFormatDateShort }}</td>
              </tr>
              <tr>
                <td class="label font-weight-bold">{{ 'reusable_sound.content.created_dt' | translate }}</td>
                <td>{{ fileEntry.created_dt | timeFormatDateShort }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-3">
          <mat-divider [inset]="true" class="section-divider"></mat-divider>
        </div>

        <!--2-->
        <div id="inused" anchorPart>
          <h1 class="font-weight-bold">{{ 'reusable_sound.content.using'|translate }}</h1>

          <ng-container *ngIf="noObjectUsing; else elementsUsedSoundEntry">
            <p>{{ 'reusable_sound.content.no_data'|translate }}</p>
          </ng-container>

          <ng-template #elementsUsedSoundEntry>
            <p>{{ 'reusable_sound.content.sound_entry_used_following_elements'|translate }}</p>

            <ng-container *ngIf="inUsedObjects['did']">
              <p class="font-weight-bold">{{ 'did.content.inused'|translate }}</p>
              <div class="pb-1" fxLayout="row wrap">
                <div *ngFor="let did of inUsedObjects['did']" class="inused-item" (click)="gotoPageDid(did)">
                  <app-did-mini-block [did]="did"></app-did-mini-block>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['call_queue']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.call_queue'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <div *ngFor="let q of inUsedObjects['call_queue']" class="inused-item" (click)="gotoPageQueue(q)">
                  <app-queue-mini-block [callQueue]="q"></app-queue-mini-block>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['ivr_menu']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.ivrmenus'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <!-- <ng-container *ngFor="let ivrmenu of inUsedObjects['ivr_menu']">
                  <button mat-stroked-button color="accent" (click)="gotoPageIvrMenu(ivrmenu)">{{ ivrmenu.name
                    }}</button>
                </ng-container> -->
                <div *ngFor="let ivrmenu of inUsedObjects['ivr_menu']" class="inused-item"
                  (click)="gotoPageIvrMenu(ivrmenu)">
                  <app-ivr-menu-mini-block [ivrMenu]="ivrmenu"></app-ivr-menu-mini-block>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['user']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.voicemail_of_user'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <div class="inused-item" *ngFor="let user of inUsedObjects['user']" fxFlex.xs="100"
                  fxFlex.gt-xs="0 0 calc(50% - 10px)" fxFlex.gt-md="0 0 calc(33.33% - 10px)">
                  <div fxFlexFill fxLayout="column" class="agent-wrapper" (click)="gotoPageUser(user)">
                    <div fxFlex>
                      <agent-item [avatar]="true" [agent]="user.agent"></agent-item>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['user_wtc']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.users'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <div class="inused-item" *ngFor="let user of inUsedObjects['user_wtc']" fxFlex.xs="100"
                  fxFlex.gt-xs="0 0 calc(50% - 10px)" fxFlex.gt-md="0 0 calc(33.33% - 10px)">
                  <div fxFlexFill fxLayout="column" class="agent-wrapper" (click)="gotoPageUser(user)">
                    <div fxFlex>
                      <agent-item [avatar]="true" [agent]="user.agent"></agent-item>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['crm_routing_rule']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.crm_routing_rules'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <!-- <ng-container *ngFor="let item of inUsedObjects['crm_routing_rule']">
                  <button class="inused-item" mat-stroked-button color="accent" (click)="gotoPageCrmRoutingRule(item)">{{ item.name
                    }}</button>
                </ng-container> -->
                <div *ngFor="let item of inUsedObjects['crm_routing_rule']" class="inused-item"
                  (click)="gotoPageCrmRoutingRule(item)">
                  <app-crm-routing-rule-mini-block [crmRoutingRule]="item"></app-crm-routing-rule-mini-block>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['ivr_evaluation']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.ivr_evaluation'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <!-- <ng-container *ngFor="let item of inUsedObjects['ivr_evaluation']">
                  <button class="inused-item" mat-stroked-button color="accent" (click)="gotoPageIvrEvaluation(item)">{{ item.name
                    }}</button>
                </ng-container> -->
                <div *ngFor="let item of inUsedObjects['ivr_evaluation']" class="inused-item"
                  (click)="gotoPageIvrEvaluation(item)">
                  <app-ivr-evaluation-mini-block [ivrEvaluation]="item"></app-ivr-evaluation-mini-block>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['work_calendar']">
              <h4 class="font-weight-bold mb-0">{{ 'reusable_sound.content.work_calendars'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <div *ngFor="let item of inUsedObjects['work_calendar']" class="inused-item"
                  (click)="gotoPageCalendar(item)">
                  <app-calendar-mini-block [calendar]="item"></app-calendar-mini-block>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="inUsedObjects['enterprise_telecom_params']">
              <h4 class="font-weight-bold mb-0">{{ 'enterprise_info.title'|translate }}</h4>
              <div class="pb-1" fxLayout="row wrap">
                <div class="inused-item" (click)="gotoPageEnterpriseInfo()">
                  <app-sip-teminal-setting-mini-block></app-sip-teminal-setting-mini-block>
                </div>
              </div>
            </ng-container>

          </ng-template>
        </div>

      </div>

    </div>
  </div>
</div>
