<form [formGroup]="form" class="call-destination-wrap">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="5px" class="mt-1">
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ placeholder | translate }}</mat-label>
        <mat-select [formControl]="routingAppControl" data-ci="routing-app-control"
          [placeholder]="'components.calldestination.not_config'|translate" [compareWith]="routingAppCompareWith"
          (selectionChange)="clearValidations()">
          <mat-option>{{ 'components.calldestination.not_config' | translate }}</mat-option>
          <mat-option [attr.data-ci]="item.application" [value]="item" *ngFor="let item of routingAppList">
            {{ getRoutingAppName(item) }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="routingAppControl.touched && errors.call_application">
          {{ errors.call_application | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="70" *ngIf="routingAppControl.value?.params?.length" fxLayout="row" fxLayout.xs="column"
      fxLayoutAlign="start center">
      <ng-container *ngFor="let param of routingAppControl.value.params">
        <ng-container [ngSwitch]="routingAppControl.value.application">
          <ng-container *ngSwitchCase="'call_phone_number'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'did.content.number' | translate }}</mat-label>
              <input matInput *ngIf="param.param_name == 'number'" [placeholder]="'did.content.number' | translate"
                [formControl]="callPhoneNumberControl" [maxlength]="20" data-ci="phone-number"
                [required]="param.required == 1">

              <mat-hint class="full-width text-right">
                {{ callPhoneNumberControl.value && callPhoneNumberControl.value.length || 0 }}/20
              </mat-hint>

              <mat-error *ngIf="callPhoneNumberControl.touched && callPhoneNumberControl.hasError('call_phone_number')">
                {{ errors.call_phone_number | translate }}</mat-error>

            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'play_then_hangup'">
            <div fxLayout="column">
              <sound-upload defaultSound="service_closed" fxFill [fileEntry]="playThenHangupControl.value"
                [removable]="false" (onChange)="updateSoundB4HangupFile($event)"></sound-upload>

              <mat-error *ngIf="playThenHangupControl.touched && playThenHangupControl.hasError('play_then_hangup')">{{
                errors.play_then_hangup | translate }}</mat-error>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'digitalsecretary'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'routing_application.params.default_ext' | translate }}</mat-label>
              <input matInput *ngIf="param.param_name == 'default_ext'" type="text"
                [formControl]="digitalsecretaryControl" [required]="param.required == 1">

              <mat-error
                *ngIf="digitalsecretaryControl.touched && digitalsecretaryControl.hasError('digitalsecretary')">
                {{ errors.digitalsecretary | translate }}</mat-error>

            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'callqueue'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'routing_application.params.queue' | translate }}</mat-label>
              <queue-select-form-input [formControl]="callqueueControl" [excludeQueueIds]="excludeQueueIds"
                [placeholder]="'routing_application.params.queue'|translate"></queue-select-form-input>

              <mat-error *ngIf="callqueueControl.touched && callqueueControl.hasError('callqueue')">{{ errors.callqueue
                | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'conference'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'routing_application.params.conference' | translate }}</mat-label>
              <conference-select-form-input [formControl]="conferenceControl" [placeholder]="'Conference'">
              </conference-select-form-input>

              <mat-error *ngIf="conferenceControl.touched && conferenceControl.hasError('conference')">{{
                errors.conference | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'runvxmlscript'">
            <mat-form-field class="full-width" *ngIf="param.param_name == 'ivrscript_id'">
              <mat-label>{{ 'routing_application.params.ivrscript_id' | translate }}</mat-label>
              <ivr-script-select-form-input [formControl]="runvxmlscriptControl" [placeholder]="'IVR Script'">
              </ivr-script-select-form-input>

              <mat-error *ngIf="runvxmlscriptControl.touched && runvxmlscriptControl.hasError('runvxmlscript')">
                {{ errors.runvxmlscript | translate }}</mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'call_phone'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'routing_application.params.call_phone' | translate }}</mat-label>
              <sip-number-select-form-input [formControl]="callPhoneControl" [isConfigured]="true"
                [excludeIds]="excludeSipPhoneId && [excludeSipPhoneId]"
                [placeholder]="'routing_application.params.call_phone' | translate" [required]="param.required == 1">
              </sip-number-select-form-input>

              <mat-error *ngIf="callPhoneControl.touched && callPhoneControl.hasError('call_phone')">{{
                errors.call_phone | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'remote_application'">
            <mat-form-field class="full-width" *ngIf="param.param_name == 'id'">
              <mat-label>{{ 'routing_application.params.remote_application' | translate }}</mat-label>
              <remote-application-select-form-input [formControl]="remoteApplicationControl"
                [placeholder]="'routing_application.runvxmlscript.app_name' | translate"></remote-application-select-form-input>

              <mat-error
                *ngIf="remoteApplicationControl.touched && remoteApplicationControl.hasError('remote_application')">
                {{ errors.remote_application | translate }}</mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'call_user'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'routing_application.params.call_user' | translate }}</mat-label>
              <user-select-form-input [hideDisableUsers]='true' [formControl]="callUserControl"
                [placeholder]="'user_type_names.user' | translate"></user-select-form-input>

              <mat-error *ngIf="callUserControl.touched && callUserControl.hasError('call_user')">
                {{ errors.call_user | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'ivr_custom_menu'">
            <div fxLayout="column" class="full-width">
              <mat-form-field fxFlex class="full-width" *ngIf="!canAddSubMenu">
                <mat-label>{{ 'routing_application.params.ivr_menu' | translate }}</mat-label>
                <ivr-menu-select-form-input [formControl]="ivrCustomMenuControl" [isConfigured]="true"
                  [excludeIds]="excludeIvrMenuId && [excludeIvrMenuId]"
                  [placeholder]="'routing_application.params.ivr_menu' |translate"></ivr-menu-select-form-input>
                
                <mat-error *ngIf="ivrCustomMenuControl.hasError('ivr_custom_menu')">
                  {{ errors.ivr_custom_menu | translate }}</mat-error>
              </mat-form-field>
              <div *ngIf="shouldViewSubMenu" fxLayout="column">
                <div>
                  <button fxFlex="180px" type="button" mat-stroked-button color color="accent" data-ci="view-sub-menu"
                    (click)="openEditIvrSubMenu(ivrCustomMenuControl.value)">
                    {{ 'components.calldestination.view_submenu'|translate }}
                  </button>
                </div>
                <div class="mat-error text-red mr-2" *ngIf="ivrCustomMenuControl.hasError('ivr_custom_menu')">
                  {{ 'components.calldestination.ivr_submenu_required' | translate }}</div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'ivr_custom_main_menu'">
            <div fxLayout="column" class="full-width">
              <mat-form-field fxFlex class="full-width">
                <mat-label>{{ 'routing_application.params.ivr_menu' | translate }}</mat-label>
                <ivr-menu-select-form-input [formControl]="ivrCustomMainMenuControl" [isConfigured]="true"
                  [excludeIds]="excludeIvrMenuId && [excludeIvrMenuId]"
                  [placeholder]="'routing_application.params.ivr_menu' |translate"></ivr-menu-select-form-input>
                <mat-error *ngIf="ivrCustomMainMenuControl.hasError('ivr_custom_main_menu')">
                  {{ errors.ivr_custom_main_menu | translate }}</mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'to_did'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'routing_application.params.to_did' | translate }}</mat-label>
              <did-select-form-input [formControl]="toDidControl" [onlyConfigured]="true"
                [excludeIds]="invalidRoutingDidIds" [placeholder]="'routing_application.params.to_did' | translate">
              </did-select-form-input>

              <mat-error *ngIf="toDidControl.touched && toDidControl.hasError('to_did')">{{ errors.to_did | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'send_sms'">
            <div class="sms-template" fxFlex="initial" *ngIf="hasSMSFeature && param.param_name === 'sms_id'">
              <mat-form-field class="full-width">
                <mat-label>{{ 'routing_application.params.send_sms' | translate }}</mat-label>
                <mat-select [formControl]="sendSMSControl" data-ci="send-sms-control"
                  [compareWith]="smsTemplateCompareWith" (selectionChange)="clearValidations()">
                  <mat-select-trigger>
                    <span [matTooltip]="sendSMSControl.value && sendSMSControl.value.name">
                      {{sendSMSControl.value && sendSMSControl.value.name}}</span>
                  </mat-select-trigger>
                  <mat-option [attr.data-ci]="smsTemplate.id" [value]="smsTemplate"
                    *ngFor="let smsTemplate of smsTemplates">
                    {{ smsTemplate.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="sendSMSControl.touched && sendSMSControl.hasError('send_sms')">
                  {{ errors.send_sms | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex="grow" *ngIf="hasSMSFeature && param.param_name === 'sound_id'">
              <sound-upload fxFill [title]="'send_sms.content.play_message'|translate"
                [fileEntry]="playAfterSendSMSControl.value" [removable]="false"
                (onChange)="updateSoundAfterSendSMSFile($event)">
              </sound-upload>
              <mat-error
                *ngIf="playAfterSendSMSControl.touched && playAfterSendSMSControl.hasError('play_after_send_sms')">
                {{ errors.play_after_send_sms | translate }}
              </mat-error>
            </div>
          </ng-container>

        </ng-container>
      </ng-container>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayout.xs="column"
    *ngIf="routingAppControl.value?.application == 'runvxmlscript' && runvxmlscriptControl.value?.params_desc">
    <ng-container *ngFor="let field of getObjectKeys(runvxmlscriptControl.value.params_desc)">
      <ng-container [ngSwitch]="runvxmlscriptControl.value.params_desc[field].type">
        <div fxFlex *ngSwitchCase="'int'">
          <mat-form-field class="full-width">
            <mat-label *ngIf="runvxmlscriptControl.value.params_desc[field].desc">
              {{ runvxmlscriptControl.value.params_desc[field].desc }}</mat-label>
            <input matInput type="number" pattern="/^[0-9]{1,}$/"
              [placeholder]="runvxmlscriptControl.value?.params_desc[field]?.desc || ''"
              [(ngModel)]="routingAppControl.value?.params?.script_params[field]"
              [required]="runvxmlscriptControl.value?.params_desc[field]?.required == 1 ? true : false">
          </mat-form-field>
        </div>
        <div fxFlex *ngSwitchCase="'str'">
          <mat-form-field class="full-width">
            <mat-label *ngIf="runvxmlscriptControl.value.params_desc[field].desc">
              {{ runvxmlscriptControl.value.params_desc[field].desc }}</mat-label>
            <input matInput [placeholder]="runvxmlscriptControl.value.params_desc[field].desc || ''"
              [(ngModel)]="routingAppControl.value.params?.script_params[field]"
              [required]="runvxmlscriptControl.value.params_desc[field].required == 1 ? true : false">
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
