import { Component, OnInit } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { IFlexDialogConfig, DialogActionButton, DialogService } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { UserEntity } from '@wephone-core/model/entity/user';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeUserPasswordModal } from '@wephone/modals/change-user-password/change-user-password';
import { UserParam } from '@wephone-core/system';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UserTurnOnMfaComponent } from '../user-turn-on-mfa/user-turn-on-mfa.component';

@Component({
  selector: 'user-profile',
  templateUrl: 'user-profile.html',
  styleUrls: ['user-profile.scss']
})
export class UserProfileDialog extends DialogComponentBase implements OnInit {
  static modalConfig: IFlexDialogConfig = {
    hasBackdrop: true,
    size: 'xs',
  };

  dialogTitle = _tk('menu.manage_profile');
  dialogRightActions: DialogActionButton[] = [];

  loggedUser: UserEntity;
  mfaEnabled: boolean;
  mfaQrCodeUrl: string;

  constructor(
    dialogRef: MatDialogRef<UserProfileDialog>,
    // private readonly userProfile: UserProfileService,
    private readonly configService: ConfigManager,
    
    private readonly authService: AuthenticationService,
    private readonly dialogService: DialogService,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loggedUser = this.authService.getUser() as any;
    this.mfaEnabled = !!this.configService.getUserParamValue(UserParam.mfa_enabled, 0);
  }

  openChangeUserPasswordDialog($event: Event): MatDialogRef<any> {
    $event.preventDefault();
    $event.stopPropagation();
    return this.dialogService.openDialog2(ChangeUserPasswordModal, {
      data: { id: this.loggedUser.id }
    });
  }

  async onChangeMfaEnabled($event): Promise<void> {
    const currentValue = this.mfaEnabled;
    const mfaEnabled = !currentValue;

    try {
      if (mfaEnabled) {
        const result = await this.authService.requestTurnOnMafAuth();
        this.mfaQrCodeUrl = result.qrCodeUrl;

        this.dialogService.openDialog2(UserTurnOnMfaComponent, {
          data: {
            mfaQrCodeUrl: this.mfaQrCodeUrl
          }
        }, (data: any) => {
          if (data) {
            this.configService.setUserParamValue(UserParam.mfa_enabled, 1);
            this.mfaEnabled = mfaEnabled;
          } else {
            this.mfaEnabled = currentValue;
          }
        });
      } else {
        this.dialogService.confirmDialog(_ti('dialogs.confirmation'), _ti('enterprise.message.confirm_turn_off_mfa'), async () => {
          try {
            await this.authService.turnOffMafAuth();
            this.configService.setUserParamValue(UserParam.mfa_enabled, 0);
            this.mfaQrCodeUrl = '';
            this.mfaEnabled = mfaEnabled;
          } catch (error) {
            this.mfaEnabled = currentValue;
          }
        });
      }

      this.mfaEnabled = mfaEnabled;
    } catch (error) {
      this.mfaEnabled = currentValue;
      console.error('Toggle mfa error', error);
      $event.preventDefault();
      this.showErrorMessage(error, _ti('public.message.update_failed'));
    }
  }
}
