import * as _ from 'lodash';
import { ElectronService } from 'ngx-electron';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { StorageService } from '@wephone-core/service/storage.service';
import { MessageService } from '@wephone-utils/services/message.service';
import { CookieService } from '@wephone-utils/services/cookie.service';
import { SingletonBase } from '@wephone-utils/utils/singleton';
import {
  UserRole,
  BusinessType,
  UI_MODE,
  UserPhoneMode,
  AccessRight,
  EnterpriseParam,
  BoDomain,
} from '@wephone-core/system';
import { IUserEntity } from '@wephone-core/model/entity/user.i';
import { Deferred } from 'ts-deferred';
import { StaticServiceLocator } from '@wephone-core/service/static_service_locator';
import {
  IAuthenticationService,
  IAuthSuccessInfo,
  LoginPreAuthSessionResp,
} from '@wephone-core/service/authentication.i';
import { IEntityManager } from '@wephone-core/service/entity_manager.i';
import { TranslationService, _tk, _ti } from '@wephone-translation';
import { UserEntity } from '@wephone-core/model/entity/user';
import { UserRepository } from '@wephone-core/model/repository/user';

const DOMAIN_NAME_KEY = '_app_domain';

export interface IDomainDict {
  name: string;
  business_type: BusinessType;
}

export type TDomainDict = Record<string, IDomainDict>;
export interface AuthenInfo {
  authenticated?: boolean;
  auth_token?: string;
  user_display_name?: string;
  xsrf_token?: string;
  email?: string;
  user_id?: number;
  user_role?: UserRole;
  agent_id?: number;
  objectcache_url?: string;
  domain?: string;
  access_granted?: string[];
  business_type?: BusinessType;
  user?: UserEntity;
  user_phone_mode?: number;
  system_user?: number;
}

export interface CsrfData {
  PHPSESSID: string;
  CSRF_TOKEN: string;
  XSRF_TOKEN: string;
  authenticated: boolean;
  user_id?: number;
  agent_id?: number;
  user_display_name?: string;
  email?: string;
  user_role?: UserRole;
  user_phone_mode?: number;
  system_user?: number;
  access_granted?: string[];
  domain?: string;
  business_type?: BusinessType;
}

@Injectable()
export class AuthenticationService extends SingletonBase implements IAuthenticationService {
  static URL_REQUEST_TURN_ON_MFA_AUTH = 'auth/2fa/request-turn-on';
  static URL_CONFIRM_TURN_ON_MFA_AUTH = 'auth/2fa/confirm-turn-on';
  static URL_TURN_OFF_MFA_AUTH = 'auth/2fa/turn-off';

  authInfo: AuthenInfo = {};
  isReloadingSession = false;
  private login_form_csrf: string;
  private d_login_cookie: Deferred<boolean>;
  private d_login_token: Deferred<boolean>;
  private reconnection_tried = false;
  // private php_session_name: string;
  private enterprise_domain: string;
  // private registrationUrl = 'api/user/update_firebase_device';
  private readonly registerUrl = 'noauth/api/register';
  private globalPHPSessId = false;
  private loggedOut = false;

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly storage: StorageService,
    private readonly settings: FlexIvrSettings,
    private readonly cookieService: CookieService,
    private readonly translationService: TranslationService,
    private readonly messageService: MessageService,
    // private readonly router: Router,
    private readonly electron: ElectronService
  ) {
    super();

    StaticServiceLocator.setServiceByName('AuthenticationService', this);

    this.resetAuthInfo();
    // this.php_session_name = this.settings.php_session_name || 'PHPSESSID';
  }

  static getInstance(): AuthenticationService {
    return super.getInstance();
  }

  private get configService(): any {
    return StaticServiceLocator.getServiceByName('ConfigManager');
  }

  private get em(): IEntityManager {
    return StaticServiceLocator.getServiceByName('EntityManager');
  }

  hasLoggedOut(): boolean {
    return this.loggedOut;
  }

  setGlobalPHPSessId(val: boolean): void {
    this.globalPHPSessId = val;
  }

  private getLocalStorageValue(keyName: string): Promise<any> {
    return this.storage.getAppConfig(keyName);
  }
  private setLocalStorageValue(keyName: string, value: any): Promise<any> {
    return this.storage.setAppConfig(keyName, value);
  }

  has_tried_reconnect(): boolean {
    return this.reconnection_tried;
  }

  getAuthToken(): string {
    return this.authInfo.auth_token;
  }

  getXSRFToken(): string {
    return this.authInfo.xsrf_token;
  }

  private resetAuthInfo(): void {
    this.authInfo.authenticated = false;
    this.authInfo.auth_token = undefined;
    this.authInfo.user_display_name = undefined;
    this.authInfo.email = undefined;
    this.authInfo.user_id = undefined;
    this.authInfo.user_role = undefined;
    this.authInfo.agent_id = undefined;
    this.authInfo.objectcache_url = undefined;
    this.authInfo.domain = window[DOMAIN_NAME_KEY];
    this.authInfo.access_granted = [];
    this.authInfo.business_type = undefined;
    this.authInfo.user = undefined;
  }

  isAuthenticated(): boolean {
    return this.authInfo.authenticated;
  }

  isPowerUser(): boolean {
    return (
      this.authInfo &&
      (this.authInfo.business_type === BusinessType.RESELLER || this.authInfo.user_role === UserRole.SUPER_ADMIN)
    );
  }

  isBusinessHotel(): boolean {
    return this.authInfo && this.authInfo.business_type === BusinessType.HOTEL;
  }

  isBusinessSipTrunk(): boolean {
    return this.authInfo && this.authInfo.business_type === BusinessType.SIPTRUNK;
  }

  isSystemUser(): boolean {
    return this.authInfo && this.authInfo.system_user === 1;
  }

  getEnterpriseDomain(): string {
    return this.enterprise_domain;
  }

  setEnterpriseDomain(domain: string): void {
    this.enterprise_domain = domain;
  }

  getUserDisplayName(): string {
    return this.authInfo.user_display_name;
  }

  getUserEmail(): string {
    return this.authInfo.email;
  }

  getUserId(): number {
    return this.authInfo.user_id;
  }

  getAgentId(): number {
    return this.authInfo.agent_id;
  }

  getUserRole(): UserRole {
    return this.authInfo && this.authInfo.user_role;
  }

  isRole(roles: string): boolean {
    if (!this.authInfo) {
      return false;
    }
    const role_list = _.split(roles, '|');
    for (const r of role_list) {
      if (this.authInfo.user_role === r) {
        return true;
      }
    }

    return false;
  }

  getBusinessType(): BusinessType {
    return this.settings.bt_type;
  }

  getObjectCacheUrl(): string {
    return this.authInfo.objectcache_url + `?domain=${this.getEnterpriseDomain()}`;
  }

  isAdmin(): boolean {
    return this.authInfo && [UserRole.ADMIN, UserRole.SUPER_ADMIN].indexOf(this.authInfo.user_role) >= 0;
  }

  isUser(): boolean {
    return this.authInfo && [UserRole.USER].indexOf(this.authInfo.user_role) >= 0;
  }

  isSuperAdmin(): boolean {
    return this.authInfo && this.authInfo.user_role === UserRole.SUPER_ADMIN;
  }

  isSuperAccount(): boolean {
    return this.authInfo && this.authInfo.user_role === UserRole.SUPER_ACCOUNTANT;
  }

  isSuperLevel(): boolean {
    return this.isSuperAdmin() || this.isSuperAccount();
  }

  isGrantedAccess = (access_name: string) => {
    return this.authInfo && this.authInfo.access_granted && this.authInfo.access_granted.indexOf(access_name) >= 0;
  }

  isSupervisor(): boolean {
    return this.authInfo && this.authInfo.user_role === UserRole.SUPERVISOR;
  }

  isAccountant(): boolean {
    return this.authInfo && this.authInfo.user_role === UserRole.ACCOUNTANT;
  }

  isAgent(): boolean {
    return this.authInfo && this.authInfo.user_role === UserRole.AGENT;
  }

  hasRole(role: UserRole): boolean {
    return this.authInfo && this.authInfo.user_role === role;
  }

  isWatcher(): boolean {
    return this.authInfo && this.authInfo.user_role === UserRole.WATCHER;
  }

  getUser(): UserEntity {
    if (!this.authInfo.user) {
      this.authInfo.user = this.em.getRepository<UserRepository>('UserRepository').getUserById(this.authInfo.user_id);
    }

    return this.authInfo.user;
  }

  autoAnwser(): boolean {
    const user = this.getUser();
    if (!user) {
      return false;
    }
    return !!user.auto_pickup;
  }

  getDefaultReturnUrl(): string {
    console.log('this.authInfo', this.authInfo);
    if (!this.authInfo.user_id) {
      return '/';
    }

    if (this.isAccountant()) {
      return '/enterprise/invoice';
    }

    if (this.isSuperAccount()) {
      return '/manage-invoices/invoices';
    }

    if (this.authInfo.business_type === BusinessType.RESELLER) {
      return '/reseller-enterprises';
    }

    if (this.authInfo.business_type === BusinessType.SIPTRUNK) {
      return '/number-routing';
    }

    return '/dashboard';
  }

  async getUserAsync(): Promise<IUserEntity> {
    if (this.authInfo.user) {
      return this.authInfo.user as any;
    }
    await this.em.waitForPushedConfig();
    return this.getUser();
  }

  getAvatarUrl(): string {
    const user = this.getUser();
    if (!user.avatar_id) {
      return 'assets/images/avatar.png';
    }
    return FlexIvrSettings.getInstance().getEnterpriseUrl(
      '/resource/user/avatar/' + user.id + '?avatar_id=' + user.avatar_id
    );
  }

  async getUserType(): Promise<string> {
    const userTypeList = await this.configService.getUserTypes();
    const user = this.getUser();
    const roleName: any = _.find(userTypeList, { user_type: user.user_type });

    return roleName ? roleName.user_type_name : 'Undefined';
  }

  async loginToken(returnUrl: string = '/'): Promise<boolean> {
    if (this.d_login_token) {
      return this.d_login_token.promise;
    }

    if (!this.enterprise_domain) {
      this.enterprise_domain = await this.cookieService.get('preauth_domain');
    }

    // console.log('this.enterprise_domain', this.enterprise_domain);
    if (!this.validDomain(this.enterprise_domain)) {
      console.error('Invalid domain', this.enterprise_domain);
      // return Promise.reject();
      this.d_login_token.reject();
      this.d_login_token = undefined;
      return;
    }

    this.authInfo.auth_token = await this.cookieService.get('preauth_token');
    // console.log('this.authInfo.auth_token', this.authInfo.auth_token);
    this.d_login_token = new Deferred();

    let logged_in = false;
    try {
      logged_in = await this.reloadSession();
      console.log('logged_in loginToken', logged_in);
    } catch (e) {
      console.error('Exception when trying to login', e);

      return false;
    }

    if (logged_in) {
      await this.onLoginSuccess(returnUrl);
      if (this.authInfo.domain) {
        this.setLoggedEnterprises(this.authInfo.domain);
      }
      this.notifyLoginSuccess(returnUrl);
    }

    if (this.d_login_token) {
      this.d_login_token.resolve(logged_in);
      this.d_login_token = undefined;
    }

    return logged_in;
  }

  notifyLoginSuccess(returnUrl: string = null): void {
    const params: IAuthSuccessInfo = {
      username: this.authInfo.user_display_name,
      domain: this.authInfo.domain,
      returnUrl
    };
    this.messageService.broadcast('auth:login:success', params);
  }

  async loginCookie(returnUrl: string = null): Promise<boolean> {
    try {
      if (this.d_login_cookie) {
        return this.d_login_cookie.promise;
      }
      this.d_login_cookie = new Deferred();

      if (!this.enterprise_domain) {
        this.enterprise_domain = await this.getLastDomain();
      }
      if (!this.validDomain(this.enterprise_domain)) {
        console.error('Invalid domain', this.enterprise_domain);
        // throw new Error('Invalid domain');
        this.d_login_cookie.resolve(false);
        return this.d_login_cookie.promise;
      }
      this.authInfo.auth_token = await this.storage.getDomainTempData<string>('auth_token', this.enterprise_domain);

      // Try logging in cookie preauth-token first
      const preAuthToken: string = await this.cookieService.get('PREAUTH_TOKEN');

      await this.cookieService.del('PREAUTH_TOKEN');

      if (preAuthToken) {
        let dataLoggedIn: LoginPreAuthSessionResp;

        dataLoggedIn = await this.loginPreAuthSession(preAuthToken);

        if (dataLoggedIn && dataLoggedIn.logged_in) {
          this.d_login_cookie.resolve(true);
          // this.d_login_cookie = undefined;
          await this.onLoginSuccess(returnUrl);
          return this.d_login_cookie.promise;
        }
      }

      // Try logging in cookie username+password
      let logged_in = false;
      logged_in = await this.reloadSession();

      if (logged_in) {
        await this.onLoginSuccess(returnUrl);
      }

      this.d_login_cookie.resolve(logged_in);
      // this.d_login_cookie = undefined;
      return this.d_login_cookie.promise;
    } catch (e) {
      console.error('Exception when trying to login', e);
      this.d_login_cookie.reject(e);
      // this.d_login_cookie = undefined;

      return this.d_login_cookie.promise;
    }

  }

  async reloadSession(): Promise<boolean> {
    const login_get_csrf: string = this.settings.getAbsoluteUrl(
      '/noauth/api/get_session_info?global_sid=' + (this.globalPHPSessId ? '1' : '0')
    );
    this.isReloadingSession = true;
    try {
      const csrf_data: CsrfData = await this.httpEngine.get(login_get_csrf);

      this.reconnection_tried = true;

      // Store the token in SharedPreferences for Android, and Keychain for iOS
      // localStorage is not very secure
      this.authInfo.auth_token = csrf_data.PHPSESSID;

      this.authInfo.xsrf_token = csrf_data.XSRF_TOKEN;
      this.login_form_csrf = csrf_data.CSRF_TOKEN;
      console.log('csrf_data', csrf_data);
      if (csrf_data.authenticated) {
        if (
          (this.settings.isProjectAdmin() || this.settings.isProjectPhone()) && csrf_data.business_type === BusinessType.RESELLER ||
          this.settings.isProjectSuperAdmin() && csrf_data.business_type && csrf_data.business_type !== BusinessType.RESELLER
        ) {
          this.clearLastDomain();
          this.gotoLoginPage();
          return;
        }

        // Check if user is accountant and login to phone
        if (this.settings.isProjectPhone() && csrf_data.user_role === UserRole.ACCOUNTANT) {
          return false;
        }

        const userPhoneMode = csrf_data.user_phone_mode;
        this.authInfo.authenticated = true;
        this.authInfo.agent_id = csrf_data.agent_id;
        this.authInfo.user_id = csrf_data.user_id;
        this.authInfo.user_display_name = csrf_data.user_display_name;
        this.authInfo.email = csrf_data.email;
        this.authInfo.user_role = csrf_data.user_role;
        this.authInfo.user_phone_mode = userPhoneMode;
        this.authInfo.system_user = csrf_data.system_user;
        this.authInfo.objectcache_url = csrf_data['objectcache_url']; // Need confirm because not response from PHP
        this.authInfo.domain = csrf_data.domain;
        this.authInfo.access_granted = csrf_data.access_granted;
        this.authInfo.business_type = csrf_data.business_type;

        if (this.authInfo.domain) {
          this.settings.setBusinessType(this.authInfo.business_type);
        }

        this.settings.setUserRole(this.authInfo.user_role);
        this.settings.enterprise_domain = this.authInfo.domain;

        if (_.includes([0, 1], userPhoneMode)) {
          this.settings.setUIMode(
            userPhoneMode === UserPhoneMode.CALLCENTER ? UI_MODE.CALLCENTER : UI_MODE.ENTERPRISE
          );
        } else {
          console.error('Invalid user phone mode: ', this.authInfo.user_phone_mode);
        }

        await this.storage.setDomainTempData('auth_token', this.authInfo.auth_token, csrf_data['domain']);

        return true;
      }

      if (this.authInfo.authenticated) {
        this.authInfo.authenticated = false;
        this.messageService.broadcast('auth:logged_out');
      }

    } finally {
      this.isReloadingSession = false;
    }

    return false;
  }

  validDomain(domain: string): boolean {
    return domain && (
      (this.settings.isProjectSuperAdmin()) ||
      (!this.settings.isProjectSuperAdmin() && domain !== BoDomain)
    );
  }

  getSavedUsername(domain: string): Promise<any> {
    if (!domain) {
      return Promise.resolve();
    }
    return this.storage.getDomainConfig<string>('username', domain);
  }

  private saveUsername(domain: string, username): Promise<any> {
    if (!domain) {
      return Promise.resolve();
    }
    return this.storage.setDomainConfig<string>('username', username, domain);
  }

  private async saveLastDomain(domain: string): Promise<void> {
    await this.setLastDomain(domain);
    await this.setLoggedEnterprises(domain);
  }

  async setLastDomain(domain: string): Promise<void> {
    await this.setLocalStorageValue(this.getLastDomainKeyStorage(), domain);
  }

  async clearLastDomain(): Promise<void> {
    await this.setLocalStorageValue(this.getLastDomainKeyStorage(), undefined);
  }

  async getLastDomain(): Promise<string> {
    const lastDomain = await this.getLocalStorageValue(this.getLastDomainKeyStorage());
    if (lastDomain === BoDomain && !this.settings.isProjectSuperAdmin()) {
      return;
    }

    return lastDomain;
  }

  private getLastDomainKeyStorage(): string {
    const isProjectSA = this.settings.isProjectSuperAdmin();
    return isProjectSA ? 'last_domain_sa' : 'last_domain';
  }

  register(credentials): Promise<any> {
    return this.httpEngine.post(this.registerUrl, credentials);
  }

  async getDomainsBy(username: string): Promise<TDomainDict> {
    // Call reload session to update login form csrf token. User may have left the login page
    // for a long time and the csrf token may have changed
    await this.reloadSession();
    const login_url: string = this.settings.getAbsoluteUrl('/noauth/api/login_check_domain');

    return this.httpEngine.postFormData(login_url, {
      _username: username,
      // _csrf_token: this.login_form_csrf
    });
  }

  private async onLoginSuccess(returnUrl: string): Promise<void> {
    if (this.authInfo.domain) {
      await this.saveLastDomain(this.authInfo.domain);
      await this.setLoggedEnterprises(this.authInfo.domain);
    }
    await this.setLocale();

    this.notifyLoginSuccess(returnUrl);
  }

  private async loginPreAuthSession(preAuthToken: string): Promise<LoginPreAuthSessionResp> {
    const preauth_login_url: string = this.settings.getAbsoluteUrl(
      `/noauth/api/login_preauth?token=${preAuthToken}`
    );

    const data: {
      PHPSESSID: string,
      onboarding_done: number,
    } = await this.httpEngine.get(preauth_login_url);

    this.authInfo.auth_token = data.PHPSESSID;
    const logged_in = await this.reloadSession();

    return {
      logged_in,
      onboarding_done: data.onboarding_done
    };
  }

  async login(user_name: string, password: string, mfaCode: string, domain: string, returnUrl: string): Promise<{
    logged_in: boolean;
    mfa_required: boolean;
  }> {
    this.loggedOut = false;

    this.enterprise_domain = domain.toLowerCase();
    if (!this.validDomain(this.enterprise_domain)) {
      console.error('Invalid domain', this.enterprise_domain);
      return {
        logged_in: false,
        mfa_required: false
      };
    }

    // Call reload session to update login form csrf token. User may have left the login page
    // for a long time and the csrf token may have changed
    const ret: any = await this.reloadSession();
    this.d_login_cookie = undefined;

    let username = user_name;
    if (this.enterprise_domain) {
      username = user_name + '@' + this.enterprise_domain;
    }

    let loggedIn = false;
    const preauthData: {
      PREAUTH_TOKEN: string;
      MFA_REQUIRED: number;
    } = await this.httpEngine.postFormData(this.settings.getAbsoluteWSv2URL('/login_check'), {
      _username: username,
      _password: password,
      _mfa_code: mfaCode
    });

    if (!preauthData.MFA_REQUIRED) {

      const dataLoggedIn: LoginPreAuthSessionResp = await this.loginPreAuthSession(preauthData.PREAUTH_TOKEN);
      loggedIn = dataLoggedIn.logged_in;
  
      if (loggedIn) {
        if (this.authInfo.domain) {
          this.saveUsername(this.authInfo.domain, user_name);
        }
  
        await this.onLoginSuccess(returnUrl);
      } else {
        this.messageService.broadcast('auth:login:failure');
      }
    }

    return {
      logged_in: loggedIn,
      mfa_required: !!preauthData.MFA_REQUIRED
    };
  }

  async logout(): Promise<boolean> {
    this.loggedOut = true;
    const logout_get_csrf: string = this.settings.getAbsoluteUrl(`/api/user/logout/${this.settings.app_type}`);
    const logout = await this.httpEngine.get(logout_get_csrf);
    if (logout && logout[0] === 'ok') {
      console.log('logout data', logout);
      this.resetAuthInfo();

      await this.clearLastDomain();
      this.storage.setDomainTempData('auth_token', undefined, this.enterprise_domain);
      this.messageService.broadcast('auth:logged_out');
      return true;
    }

    return false;
  }

  private async setLocale(): Promise<void> {
    try {
      const langCode: string = await this.cookieService.get(TranslationService.cookieName);

      if (langCode) {
        console.log('authentication set locale', langCode);
        // App language set from cookie (set from server before)
        this.translationService.setAppLanguage(langCode);
      }
    } catch (error) {
      console.error('Cannot set locale from cookie', error);
    }
  }

  async setLoggedEnterprises(domain: string): Promise<void> {
    let logged_enterprise_domains: string[] = await this.getLocalStorageValue('logged_enterprise_domains');
    if (!_.isArray(logged_enterprise_domains)) {
      logged_enterprise_domains = [];
    }

    if (logged_enterprise_domains.indexOf(domain) === -1) {
      logged_enterprise_domains.push(domain);
      await this.setLocalStorageValue('logged_enterprise_domains', logged_enterprise_domains);
    }
  }

  hasAccessRight(access_name: AccessRight, state: string = null): boolean {
    if (this.isAdmin() || this.isSupervisor()) {
      return true;
    }

    if (access_name === AccessRight.Recording) {
      // If granted access, go ahead
      if (this.isGrantedAccess(access_name)) {
        return true;
      }

      // Check with enterprise param my_own_recorded_calls
      if (state === 'recorded-call-agent' && !!this.configService.getEnterpriseParamValue(EnterpriseParam.my_own_recorded_calls)) {
        return true;
      }

      return false;
    }

    return this.isGrantedAccess(access_name);
  }

  gotoLoginPage(domain?: string): void {
    const suffix = domain ? `${domain}/s` : 's';

    if (this.electron.isElectronApp) {
      this.electron.ipcRenderer.send('mainwindow:reload');
      return;
    }

    let prefix = 'v3';
    if (this.settings.isProjectPhone()) {
      prefix = 'phone';
    } else if (this.settings.isProjectSuperAdmin()) {
      prefix = 'bo';
    }

    document.location.href = `/${prefix}/${suffix}/signin`;
  }

  async logoutAndGoToLoginPage(): Promise<void> {
    await this.logout();
    this.gotoLoginPage(this.enterprise_domain);
  }

  async requestTurnOnMafAuth(): Promise<{
    qrCodeUrl: string;
  }> {
    const url: string = AuthenticationService.URL_REQUEST_TURN_ON_MFA_AUTH;
    const result = await HttpEngine.getInstance().apiPostV2(url);
    return result;
  }

  async confirmTurnOnMafAuth(params: {
    _mfa_code: string;
  }): Promise<{
    qrCodeUrl: string;
  }> {
    const url: string = AuthenticationService.URL_CONFIRM_TURN_ON_MFA_AUTH;
    const result = await HttpEngine.getInstance().apiPostV2(url, params);
    return result;
  }

  async turnOffMafAuth(): Promise<void> {
    const url: string = AuthenticationService.URL_TURN_OFF_MFA_AUTH;
    await HttpEngine.getInstance().apiPostV2(url);
  }
}
