import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { LocalManager } from '@wephone-core/service/local_manager';
import { _ti } from '@wephone-translation';

enum GoogleCloudSsmlVoiceGender {
  SSML_VOICE_GENDER_UNSPECIFIED = 'SSML_VOICE_GENDER_UNSPECIFIED',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NEUTRAL = 'NEUTRAL',
}

export interface GoogleCloudVoiceItem {
  'languageCodes': string[];
  'name': string;
  'ssmlGender': GoogleCloudSsmlVoiceGender;
  'naturalSampleRateHertz': number;
}

export interface Text2SpeechLangItem {
  label: string;
  value: string;
}

export interface GoogleCloudVoiceParam {
  languageCode: string;
  ssmlGender: GoogleCloudSsmlVoiceGender;
}

export interface TTSFileOption {
  name: string;
  create_temp_file: number;
  file_entry_id?: number;
}

export interface Text2SpeechVoiceItem {
  label: string;
  gender: string;
  position: number;
  value: GoogleCloudVoiceParam;
}

export interface Text2SpeechLangVoice {
  [key: string]: Text2SpeechVoiceItem[];
}

export interface ElevenLabsVoiceItem {
  voice_id: string; // '21m00Tcm4TlvDq8ikWAM',
  name: string; // 'Rachel',
  samples: any; // null,
  category: string; // 'premade',
  fine_tuning: any; // [Object],
  labels: any; // [Object],
  description: string; // null,
  preview_url: string; // 'https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3',
  available_for_tiers: string[]; // [],
  settings: any; // null,
  sharing: any; // null,
  high_quality_base_model_ids: string[]; // []
}

@Injectable()
export class Text2SpeechDataService {
  static NumberGenderLimited = 1; // Undefined means no limit
  private _text2speech_lang_list: Text2SpeechLangItem[];
  private _text2speech_lang_voices: Text2SpeechLangVoice;

  private URL_FILE_ENTRY_TTS_VOICES_GGCLOUD = 'fileentry/text2speech/voices/google_cloud';
  private URL_FILE_ENTRY_TTS_VOICES_ELVLABS = 'fileentry/text2speech/voices/eleven_labs';

  private URL_FILE_ENTRY_TTS_RECORD_GGCLOUD = 'fileentry/text2speech/record/google_cloud';
  private URL_FILE_ENTRY_TTS_RECORD_ELVLABS = 'fileentry/text2speech/record/eleven_labs';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly localeManager: LocalManager,
  ) {
  }

  /**
   * 
   * @param locale: // en_GB
   * @returns string // en-GB
   */
  private getLocaleForTTS(locale: string): string {
    return locale.replace('_', '-');
  }

  getDefaultTTSLocaleForEnterprise(): string {
    const locale = this.localeManager.getMyEnterpriseDefaultLocale();
    return this.getLocaleForTTS(locale);
  }

  async listElevenLabsVoices(): Promise<ElevenLabsVoiceItem[]> {
    const voices =
      await this.httpEngine.apiGetV2(this.URL_FILE_ENTRY_TTS_VOICES_ELVLABS, {});

    return voices;
  }
  
  async listGGCloudVoices(): Promise<GoogleCloudVoiceItem[]> {
    const voices: GoogleCloudVoiceItem[] =
      await this.httpEngine.apiGetV2(this.URL_FILE_ENTRY_TTS_VOICES_GGCLOUD, {});

    const langConfig: any = {};
    const genderVoicesNumber = {};

    for (const voice of voices) {
      const codes = voice.languageCodes;
      for (const code of codes) {
        // Limit number of voice and set position
        if (!genderVoicesNumber[code]) {
          genderVoicesNumber[code] = {};
        }

        // Increase position
        if (!genderVoicesNumber[code][voice.ssmlGender]) {
          genderVoicesNumber[code][voice.ssmlGender] = 0;
        }
        genderVoicesNumber[code][voice.ssmlGender] += 1;

        // Limit number of voice
        if (Text2SpeechDataService.NumberGenderLimited &&
          genderVoicesNumber[code][voice.ssmlGender] > Text2SpeechDataService.NumberGenderLimited) {
          continue;
        }

        // Set position
        if (!langConfig[code]) {
          langConfig[code] = [];
        }
        langConfig[code].push({
          name: voice.name,
          gender: voice.ssmlGender,
          position: !Text2SpeechDataService.NumberGenderLimited || Text2SpeechDataService.NumberGenderLimited > 1 ?
            genderVoicesNumber[code][voice.ssmlGender] : ''
        });
      }
    }

    // Set text2speech_lang_list
    const text2speechLangList = [];
    for (const lang of Object.keys(langConfig)) {
      const langSpliter = lang.split('-');
      if (langSpliter.length !== 2) {
        console.error('Incorrect lang format', lang);
        continue;
      }
      const langTrans = _ti(`language_codes.${langSpliter[0].toLowerCase()}`);
      const countryTrans = _ti(`country_codes.${langSpliter[1].toLowerCase()}`);

      text2speechLangList.push({
        value: lang,
        label: `${langTrans} - ${countryTrans}`
      });
    }
    this.text2speech_lang_list = text2speechLangList;

    // Set text2speech_lang_voices
    const text2speechLangVoices = {};
    for (const lang of Object.keys(langConfig)) {
      if (!text2speechLangVoices[lang]) {
        text2speechLangVoices[lang] = [];
      }
      for (const config of langConfig[lang]) {
        text2speechLangVoices[lang].push({
          position: config.position,
          gender: config.gender,
          label: `${_ti(`genders.${config.gender.toLowerCase()}`)} ${config.position}`,
          value: {
            languageCode: lang,
            ssmlGender: config.gender
          },
        });
      }
    }

    this.text2speech_lang_voices = text2speechLangVoices;

    return voices;
  }

  get text2speech_lang_list(): { label: string; value: string }[] {
    return this._text2speech_lang_list;
  }

  set text2speech_lang_list(list) {
    this._text2speech_lang_list = list;
  }

  get text2speech_lang_voices(): Text2SpeechLangVoice {
    return this._text2speech_lang_voices;
  }

  set text2speech_lang_voices(voices: Text2SpeechLangVoice) {
    this._text2speech_lang_voices = voices;
  }

  getGGCloudLangByValue(lang: string): Text2SpeechLangItem {
    return this.text2speech_lang_list.find(x => x.value === lang);
  }

  getGGCloudVoiceByValue(lang: string, voice: string): Text2SpeechVoiceItem {
    const voices = this.text2speech_lang_voices;
    if (!voices[lang]) {
      console.error(`${lang} might not be used`);
      return;
    }
    return voices[lang].find(x => x.gender === voice);
  }

  recordText2SpeechGGCloud(
    text: string,
    soundOptions: TTSFileOption,
    voice: GoogleCloudVoiceParam
  ): Promise<any> {
    const params: any = {
      text,
      voice,
      name: soundOptions.name,
      create_temp_file: soundOptions.create_temp_file
    };

    if (soundOptions.file_entry_id) {
      params.file_entry_id = soundOptions.file_entry_id;
    }

    return this.httpEngine.apiPostV2(this.URL_FILE_ENTRY_TTS_RECORD_GGCLOUD, params, undefined, true);
  }

  recordText2SpeechElevenLabs(
    text: string,
    soundOptions: TTSFileOption,
    voice: string
  ): Promise<any> {
    const params: any = {
      text,
      voice,
      name: soundOptions.name,
      create_temp_file: soundOptions.create_temp_file
    };

    if (soundOptions.file_entry_id) {
      params.file_entry_id = soundOptions.file_entry_id;
    }

    return this.httpEngine.apiPostV2(this.URL_FILE_ENTRY_TTS_RECORD_ELVLABS, params, undefined, true);
  }
}
