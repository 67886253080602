import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import {
  DialogActionButton,
  Colors,
  localNow,
  parseDateTime,
  FormService,
  NoWhitespaceValidator,
} from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { SipPhoneProvisioningTokenRepository } from '@wephone-core/model/repository/sip_phone_provisioning_token';
import { SipPhoneProvisioningTokenEntity } from '@wephone-core/model/entity/sip_phone_provisioning_token';
import * as _ from 'lodash';
import { DateAdapter } from '@angular/material/core';

interface PhoneModelType {
  readonly label: string;
  readonly value: string;
}

interface DataType {
  item: SipPhoneProvisioningTokenEntity;
  customize_config?: boolean;
}

@Component({
  selector: 'app-update-sip-phone-provisioning-token',
  templateUrl: './update-sip-phone-provisioning-token.component.html',
  styleUrls: ['./update-sip-phone-provisioning-token.component.scss'],
})
export class UpdateSipPhoneProvisioningTokenModalComponent extends DialogComponentBase implements OnInit {
  minDate = new Date();

  constructor(
    dialogRef: MatDialogRef<UpdateSipPhoneProvisioningTokenModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DataType,
    private readonly fb: FormBuilder,
    private readonly configManager: ConfigManager,
    private readonly formService: FormService,
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
  ) {
    super(dialogRef, cdr);
    this.dateAdapter = dateAdapter;
  }

  private readonly sipPhoneProvisioningTokenRepo: SipPhoneProvisioningTokenRepository = SipPhoneProvisioningTokenRepository.getInstance();
  dialogTitle = _tk('public.create');

  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('public.ok'),
      action: () => {
        this.submit();
      },
      visible: () => {
        return true;
      },
      color: Colors.PRIMARY,
    }
  ];

  _cancelButton: DialogActionButton = {
    label: _tk('public.button.close'),
    action: () => {
      this.dismiss();
    }
  };

  updateData = {
    id: undefined,
    name: '',
    phone_model: undefined,
    valid_until: undefined,
    config_template: undefined
  };

  isEdit = false;
  customizeConfig: boolean;
  phoneModels: PhoneModelType[] = [];
  form: FormGroup;
  setExpirationDate = false;

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.isEdit = this.data.item && !!this.data.item.id;
    this.customizeConfig = !!this.data.customize_config;

    if (this.isEdit) {
      this.dialogTitle = _tk('public.edit');
      this.updateData.name = this.data.item.name;
      this.updateData.phone_model = this.data.item.phone_model;
      this.updateData.config_template = this.data.item.config_template;
      this.updateData.valid_until = this.data.item.valid_until
        ? parseDateTime(this.data.item.valid_until).toJSDate()
        : undefined;
      this.setExpirationDate = !!this.updateData.valid_until;
      this.minDate = undefined;
    }

    this.form = this.fb.group({
      name: [this.updateData.name, [Validators.required, NoWhitespaceValidator]],
      phone_model: [this.updateData.phone_model, [NoWhitespaceValidator]],
      valid_until: this.updateData.valid_until,
      config_template: [this.updateData.config_template, [NoWhitespaceValidator]],
    });

    this.phoneModels = await this.configManager.getPhoneModels();
    // if (!this.isEdit && this.phoneModels.length) {
    //   this.form.get('phone_model').setValue(this.phoneModels[0].value);
    // }

    if (this.customizeConfig) {
      this.setFormControlConfigTemplateValue();
    }
  }

  private async setFormControlConfigTemplateValue(): Promise<void> {
    if (!this.data.item || !this.data.item.id) {
      return;
    }

    let configTemplate: string = this.data.item.config_template;

    if (!this.data.item.config_template) {
      configTemplate = await this.sipPhoneProvisioningTokenRepo.getDefaultTemplateConfig(this.data.item.id);
    }

    const configTemplateControl = this.form.get('config_template');
    configTemplateControl.setValue(configTemplate);
  }

  updateModelExpirationDate(enable: boolean): void {
    this.setExpirationDate = enable;
  }

  changeExpirationDate($event): void {
    const control = this.form.get('valid_until');
    if (!$event.checked) {
      control.markAsDirty();
      control.markAsTouched();
      control.setValue(null);
      return;
    }
    const now = localNow().toJSDate();
    if (!control.value) {
      control.markAsDirty();
      control.markAsTouched();
      control.setValue(now);
    }
  }

  private updateFormValidator(): void {
    if (this.customizeConfig) {
      const configTemplateControl = this.form.get('config_template');
      configTemplateControl.setValidators([Validators.required, NoWhitespaceValidator]);
      configTemplateControl.updateValueAndValidity();
    }
  }

  async submit(): Promise<void> {
    this.updateFormValidator();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.toastService.showError(_ti('public.message.data_invalid'));
      return;
    }

    try {
      let updatedEntity: SipPhoneProvisioningTokenEntity;
      if (!this.isEdit) {
        const formData = this.form.value;
        const newEntity = this.sipPhoneProvisioningTokenRepo.create();
        updatedEntity = await this.sipPhoneProvisioningTokenRepo.createAndSave(newEntity, {
          name: _.trim(formData.name),
          phone_model: formData.phone_model,
          valid_until: formData.valid_until,
        });
      } else {
        const formDataUpdated = this.formService.getChangeSet(this.form);
        updatedEntity = this.data.item;
        updatedEntity = await this.sipPhoneProvisioningTokenRepo.saveAttrs(updatedEntity, Object.keys(formDataUpdated), formDataUpdated);
      }

      const createdEntity = this.sipPhoneProvisioningTokenRepo.getObjectById(updatedEntity.id);
      const msgKey = this.isEdit ? 'public.message.update_success' : 'public.message.create_success';
      this.toastService.showSuccess(_ti(msgKey));
      this.dismiss(createdEntity, false);
    } catch (error) {
      const errorMSG = this.isEdit ? _ti('public.message.update_failure') : _ti('public.message.create_failure');
      this.showErrorMessage(error, errorMSG);
    }
  }

  clearPhoneModel($event): void {
    $event.stopPropagation();
    this.form.get('phone_model').markAsDirty();
    this.form.get('phone_model').setValue(null);
  }
}
