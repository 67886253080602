<ng-template #tplDataInvalid>
  {{ 'form.validator.data_invalid'|translate }}
</ng-template>

<form [formGroup]="form">
  <div class="form-group padding-none margin-none">
    <mat-card class="shadow-none header-text-no-margin">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon color="primary" class="title-icon">person</mat-icon>
        </div>
        <mat-card-title>
          <h2>{{ 'user.heading.general'|translate }}</h2>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="form-group">
        <div fxLayout="row">
          <!--Avatar-->
          <div fxFlex.xs="50%" fxFlex.gt-xs="160px">
            <div fxLayoutAlign="center">
              <user-avatar [uploadAvatar]="true" [hidden]="!user" [user]="user" width="80px" height="80px">
              </user-avatar>
            </div>

            <div fxLayoutAlign="center" class="mt-1" *ngIf="isAdmin">
              <a class="link" (click)="openChangeUserPasswordDialog($event)">{{ 'resetpass.btmchange' | translate }}</a>
            </div>
          </div>
          <div fxFlex.xs="0px" fxFlex.gt-xs="20px"></div>

          <div fxFlex.xs="50%" fxFlex.gt-xs="calc(100%-160px)" fxLayout="column">
            
            <div fxLayout="column" *ngIf="isAdmin">
              <!--User-type-->
              <div fxFlex>
                <mat-form-field class="form-item-container">
                  <mat-label>{{'account_basic_settings.content.user_role' | translate }}</mat-label>
                  <mat-select data-ci="user_role" [formControl]="form.controls['user_type']">
                    <mat-option *ngFor="let item of user_type_list" [value]="item.user_type"
                      selected="user.user_type==item.user_type">
                      {{ ('user_type_names.'+ item.user_type) | translate }}
                    </mat-option>
                  </mat-select>

                  <mat-error [flexFormControlError]="form.get('user_type')"></mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayout.xs="column">
                <!--First-Name-->
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'user_page.firstname' | translate }}</mat-label>
                    <input data-ci="firstname" matInput type="text" [formControl]="form.controls['firstname']">
                    
                    <mat-error [flexFormControlError]="form.get('firstname')"></mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="20px"></div>
                <!--Last Name-->
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'user_page.lastname' | translate }}</mat-label>
                    <input data-ci="lastname" matInput [formControl]="form.controls['lastname']">

                    <mat-error [flexFormControlError]="form.get('lastname')"></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayout.xs="column">
                <!--Phone-->
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'input.phone.label' | translate }}</mat-label>
                    <input matInput formControlName="phone" data-ci="secondary_number"
                      placeholder="{{'user_page.user_phone.placeholder' | translate}}">

                    <mat-error [flexFormControlError]="form.get('phone')"
                      [customMessages]="{
                        'isForbidden': 'user.message.phone_number_forbidden'|translate
                      }"></mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="20px"></div>
                <!--Email-->
                <div fxFlex>
                  <mat-form-field class="full-width">
                    <mat-label>{{ 'input.email.label' | translate }}</mat-label>
                    <input data-ci="mail" matInput [formControl]="form.controls['email']">

                    <mat-error [flexFormControlError]="form.get('email')"
                      [customMessages]="{
                        'wrongEmail': 'user.validators.email_incorrect'|translate,
                        'name_exist': 'user.validators.exists'|translate
                      }"></mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="column" *ngIf="!uiSipTrunk">
          <!--Recording mode & Voicemail enabled-->
          <div fxLayout="row" class="mt-1" *ngIf="user.user_type !== 'accountant' && !user.isWatcher()">
            <div fxLayout="column" fxFlex="calc(50%-10px)" fxFlex.xs="100">
              <mat-slide-toggle fxFlex="100" [formControl]="form.controls['recording_mode']"
                [checked]="form.controls['recording_mode'].value == 1">
                <span class="fontfamily-bold">{{'campaign.record_all_call'|translate}}</span>
              </mat-slide-toggle>
              <small [flexFormControlError]="form.controls['recording_mode']"
                class="mat-text-warn"></small>
            </div>
          </div>

          <!-- <div fxLayout="row" class="mt-1" *ngIf="user.user_type !== 'accountant' && !user.isWatcher()">
            <div fxLayout="column" fxFlex="calc(50%-10px)" fxFlex.xs="100">
              <mat-slide-toggle fxFlex="100" [formControl]="form.controls['voicemail_enabled']"
                [checked]="form.controls['voicemail_enabled'].value == 1">
                <span class="fontfamily-bold">{{'voicemail.enable_voicemail'|translate}}</span>
              </mat-slide-toggle>
              <small *ngIf="form.controls['voicemail_enabled'].invalid && form.controls['voicemail_enabled'].touched"
                class="mat-text-warn">
                {{ 'public.message.data_invalid'|translate }}</small>
            </div>
          </div> -->

          <!-- <div class="voicemail-options" *ngIf="!user.isWatcher()"> -->
          <!--Greeting files-->
          <!-- <div class="wrapper-sound-upload" fxLayout="row" *ngIf="form.controls['voicemail_enabled'].value == 1">
              <sound-upload [title]="'account_basic_settings.content.greating_message'|translate"
                [fileEntry]="form.get('voicemail_welcome_file').value" class="full-width" [removable]="false"
                (onChange)="updateMailboxWelcomeFile($event)">
              </sound-upload>
            </div> -->

          <!--Voicemail by email enabled-->
          <!-- <div fxLayout="row" class="mt-1" *ngIf="form.controls['voicemail_enabled'].value == 1">
              <div fxLayout="column" fxFlex="calc(50%-10px)" fxFlex.xs="100">
                <mat-slide-toggle fxFlex="100" [formControl]="form.controls['voicemail_by_mail_enabled']"
                  [checked]="form.controls['voicemail_by_mail_enabled'].value == 1">
                  <span class="fontfamily-bold">
                    {{'call_queue.content.email_addr.voicemail_by_mail_addr'|translate}}</span>
                </mat-slide-toggle>
                <small
                  *ngIf="form.controls['voicemail_by_mail_enabled'].invalid && form.controls['voicemail_by_mail_enabled'].touched"
                  class="mat-text-warn">{{ 'public.message.data_invalid'|translate }}</small>
              </div>
            </div> -->

          <!-- <div fxLayout="row" class="mt-1" *ngIf="form.controls['voicemail_enabled'].value == 1">
              <div class="" fxLayout="column" fxFlex="calc(50%-10px)" fxFlex.xs="100"
                *ngIf="form.controls['voicemail_by_mail_enabled'].value == 1">
                <mat-slide-toggle fxFlex="100" [formControl]="form.controls['voicemail_in_attachement']"
                  [checked]="form.controls['voicemail_in_attachement'].value == 1">
                  <span class="fontfamily-bold">
                    {{'call_queue.content.email_addr.enable_mp3_attachment'|translate}}</span>
                </mat-slide-toggle>
                <small
                  *ngIf="form.controls['voicemail_in_attachement'].invalid && form.controls['voicemail_in_attachement'].touched"
                  class="mat-text-warn">Voice mail in attachment enabled is incorrect.</small>
              </div>
            </div> -->
          <!-- </div> -->
          <!--User-group-->

          <div fxLayout="column" *ngIf="hasServiceGroup && !isAccountantUser()">
            <mat-form-field class="full-width">
              <mat-label>{{'call_queue.content.group'|translate}}</mat-label>
              <usergroup-select-form-input 
                [enabled]="isAdmin || canChangeUser(user)"
                [formControl]="form.controls['group_ids']" [multiple]="true">
              </usergroup-select-form-input>
              <button mat-icon-button matSuffix type="button" (click)="form.controls['group_ids'].setValue([])"
                [disabled]="(!isAdmin && !canChangeUser(user)) || !form.controls['group_ids'].value || !form.controls['group_ids'].value.length">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <small [flexFormControlError]="form.controls['group_ids']"
              class="mat-text-warn"></small>
          </div>

          <!--Team-group-->
          <div fxLayout="column">
            <mat-form-field class="full-width team-group-select">
              <label>{{ 'components.userupdate.team'|translate }}</label>
              <teamgroup-select-form-input [formControl]="form.controls['team_id']"
                [placeholder]="'components.userupdate.team'|translate">
              </teamgroup-select-form-input>
              <button mat-icon-button matSuffix type="button" (click)="form.controls['team_id'].setValue(null)"
                [disabled]="!form.controls['team_id'].value">
                <mat-icon>close</mat-icon>
              </button>
              
              <mat-error [flexFormControlError]="form.get('team_id')"></mat-error>
            </mat-form-field>
          </div>

          <!--Tag-->
          <div fxLayout="column">
            <mat-form-field class="full-width">
              <mat-label>{{'call_queue.content.tags' | translate}}</mat-label>
              <user-tag-select-form-input [formControl]="form.controls['tags']" [multiple]="true">
              </user-tag-select-form-input>
              <button mat-icon-button matSuffix type="button" (click)="form.controls['tags'].setValue([])"
                [disabled]="!form.controls['tags'].value || !form.controls['tags'].value.length">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error [flexFormControlError]="form.get('tags')"></mat-error>
            </mat-form-field>
          </div>

        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="shadow-none" *ngIf="showVoicemailTimeout">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon color="primary" class="title-icon">timer</mat-icon>
        </div>
        <mat-card-title>{{ 'user.content.section_timeout'|translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'user.content.section_timeout_hint'|translate }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="form-group">
        <div fxLayout="column">
          <mat-form-field class="full-width">
            <mat-label>{{ 'user.content.vm_timeout'|translate }}</mat-label>
            <flex-duration-input formControlName="vm_timeout" [predefined_values]="{
              '15': 'call_queue.content.wait_after_call_time.n_seconds'|translate:{ n: 15 },
              '30': 'call_queue.content.wait_after_call_time.n_seconds'|translate:{ n: 30 },
              '0': 'call_queue.content.max_inqueue.never'|translate
            }">
            </flex-duration-input>
            
            <mat-error *ngIf="vmTimeoutControl.invalid" [flexFormControlError]="vmTimeoutControl"></mat-error>
          </mat-form-field>

          <ng-container *ngIf="vmTimeoutControl.value">

            <mat-label class="mat-card-title mt-1">
              {{ 'call_queue.content.wait_timeout_config'|translate }}
            </mat-label>

            <div class="full-width -mt-2" fxLayout="row" fxLayoutAlign="start center">
              <call-destination fxFlex data-id="wait_timeout_config" #waitTimeoutConfigDestination
                [destinations]="waitTimeoutConfigDestinationList" [excludeSipPhoneId]="user.sipphone && user.sipphone.id"
                [routeAppLabelObject]="{voicemail: 'call_queue_edit.content.voicemail'|translate}"
                [voicemail]="{ voicemail_enabled: true, mailbox_id: user.mailbox_id }" [value]="user.wait_timeout_config"
                [required]="false" (valueChanged)="updateWaitTimeoutConfig($event)">
              </call-destination>

              <div fxFlex="50" *ngIf="isVoicemailEnabled()">
                <sound-upload [title]="'account_basic_settings.content.greating_message'|translate"
                  [fileEntry]="form.get('voicemail_welcome_file').value" class="full-width"
                  defaultSound="voicemail_prompt"
                  (onChange)="updateMailboxWelcomeFile($event)">
                </sound-upload>
                <mat-error [flexFormControlError]="form.controls['voicemail_welcome_file']"
                  *ngIf="form.get('voicemail_welcome_file').touched && form.get('voicemail_welcome_file').invalid"></mat-error>
              </div>
            </div>

            <!--Voicemail by email enabled-->
            <div fxLayout="row" class="mt-1" *ngIf="isVoicemailEnabled()">
              <div fxLayout="column" fxFlex="calc(50%-10px)" fxFlex.xs="100">
                <mat-slide-toggle fxFlex="100" [formControl]="form.controls['voicemail_by_mail_enabled']"
                  [checked]="form.controls['voicemail_by_mail_enabled'].value == 1">
                  <span class="fontfamily-bold">
                    {{'call_queue.content.email_addr.voicemail_by_mail_addr'|translate}}</span>
                </mat-slide-toggle>
                <small [flexFormControlError]="form.controls['voicemail_by_mail_enabled']" class="mat-text-warn"></small>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
