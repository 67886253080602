import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { PhoneNumberService } from '@wephone-utils';
import { Observable, BehaviorSubject } from 'rxjs';
import { ConfigManager } from '@wephone-core/wephone-core.module';
import { DidRepository } from '@wephone-core/model/repository/did';
import { SystemParam } from '@wephone-core/system';
import { DidEntity } from '@wephone-core/model/entity/did';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { StorageService } from '@wephone-core/service/storage.service';

export interface CallingNumber {
  id: number;
  number: string;
  name: string;
  sms: number;
  is_default: number;
}

export interface IStoragePhoneCallingInfo {
  calling_number?: string;
  calling_queue_id?: number;
}

@Injectable()
export class UserProfileService {
  static URL_SET_DEFAULT_CALLING_NUMBER = 'user/set_default_calling_number';
  static URL_SET_DEFAULT_QUEUE = 'user/set_default_queue';

  private readonly CALLING_NUMBERS_URL = 'user/calling_numbers';
  private defaultCallingNumber: CallingNumber;
  private defaultQueueId: number;
  private readonly RINGTONES_PATH = 'fileentry/ringtones';
  private readonly DELETE_RINGTONES_PATH = 'fileentry/ringtones/delete';

  _callingNumberList: BehaviorSubject<CallingNumber[]>;

  private readonly configManager = ConfigManager.getInstance() as ConfigManager;
  private readonly didRepo = DidRepository.getInstance<DidRepository>();
  private readonly queueRepo = CallQueueRepository.getInstance<CallQueueRepository>();

  anonymousCallingNumber: CallingNumber;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly storage: StorageService,
  ) {
    this.anonymousCallingNumber = this.getAnonymousCallingNumber();
    this._callingNumberList = new BehaviorSubject<CallingNumber[]>([]);

  }

  get callingNumberList(): Observable<CallingNumber[]> {
    return this._callingNumberList.asObservable();
  }

  getAnonymousCallingNumber(): CallingNumber {
    const anonymous = this.didRepo.getAnonymousDid();
    return {
      id: anonymous.id,
      number: anonymous.number,
      name: anonymous.name,
      sms: 0,
      is_default: 0
    };
  }

  async setDefaultCallingNumber(callingNumber: CallingNumber): Promise<void> {
    try {
      if (this.defaultCallingNumber && _.isEqual(callingNumber, this.defaultCallingNumber)) {
        console.warn('No need to set default calling number because nothing changed');
        return;
      }

      await this.setPhoneCallingInfoCallingNumber(callingNumber && callingNumber.number || null);
      this.defaultCallingNumber = callingNumber;
    } catch (e) {
      throw e;
    }
  }

  async setDefaultQueue(queueId: number, setDefaultCallingNumber = true): Promise<void> {
    if (this.defaultQueueId && this.defaultQueueId === queueId) {
      console.warn('No need to set default queue because nothing changed');
      return;
    }

    // Set into local storage
    await this.setPhoneCallingInfoCallingQueue(queueId);
    this.defaultQueueId = queueId;

    if (setDefaultCallingNumber) {
      const defaultCallingNumber = await this.getDefaultCallingNumberByCallQueue(queueId);
  
      await this.setDefaultCallingNumber(defaultCallingNumber);
    }
  }

  private async getDefaultCallingNumberByCallQueue(callQueueId: number): Promise<CallingNumber> {
    // Reload calling numbers to update default calling number
    const filteredList = await this.reloadCallingNumbers();
    if (_.isEmpty(filteredList)) {
      return;
    }

    let defaultCallingNumber: CallingNumber;

    const defaultQueue = callQueueId && this.queueRepo.getObjectById(callQueueId) || null;
    // First preferred item is default did of default queue
    if (defaultQueue && defaultQueue.default_did_id) {
      defaultCallingNumber = filteredList.find(x => x.id === defaultQueue.default_did_id);
    }

    // Next preferred item is random did that routed to default queue
    if (!defaultCallingNumber && callQueueId) {
      const didsRoutedQueue = this.didRepo.getObjectListRoutedToQueue(callQueueId);
      if (!_.isEmpty(didsRoutedQueue)) {
        const didNumbersRoutedQueue = _.map(didsRoutedQueue, (d: DidEntity) => d.number);
        const callingNumbersRoutedQueue = _.filter(filteredList, (n: CallingNumber) => {
          return _.includes(didNumbersRoutedQueue, n.number);
        });

        if (!_.isEmpty(callingNumbersRoutedQueue)) {
          defaultCallingNumber = _.sample(callingNumbersRoutedQueue);
        }
      }
    }

    // Next preferred item is the anonymous number
    if (!defaultCallingNumber && this.configManager.getSystemParam(SystemParam.telecom_has_anonymous)) {
      defaultCallingNumber = this.anonymousCallingNumber;
    }

    // Last preferred item is first item from list
    if (!defaultCallingNumber) {
      defaultCallingNumber = _.first(filteredList);
    }

    return defaultCallingNumber;
  }

  private requestCallingNumbers(userId: number = null): Promise<CallingNumber[]> {
    const params: any = userId ? { user_id: userId } : {};
    return HttpEngine.getInstance().apiGetV2(this.CALLING_NUMBERS_URL, params);
  }

  getUserCallingNumbers(userId: number = null): Promise<CallingNumber[]> {
    return this.requestCallingNumbers(userId);
  }

  getCallingNumbers(): Promise<CallingNumber[]> {
    return this.requestCallingNumbers();
  }

  async reloadCallingNumbers(): Promise<CallingNumber[]> {
    const numberList: CallingNumber[] = await this.requestCallingNumbers();
    
    const currentUser = this.authService.getUser();

    let filteredList: CallingNumber[] = [];
    let defaultQueue: CallQueueEntity;
    // Should check also id because there's the case of empty call-queue
    if (this.defaultQueueId) {

      defaultQueue = this.queueRepo.getObjectById(this.defaultQueueId);
      for (const c of numberList) {
        let foundNumber = null;

        const did: DidEntity = this.didRepo.getObjectById(c.id);
        if (!did) {
          continue;
        }
        
        // First preferred item is default did of default queue
        if (!foundNumber) {
          if (did.id === defaultQueue.default_did_id) {
            console.log('Did is default did of default queue', did.number);
            foundNumber = true;
          }
        }

        // Preferred item is default did of current agent
        if (!foundNumber) {
          // Next preferred item is default did of user
          const defaultDidOfUser: DidEntity = currentUser && currentUser.getDefaultCallingNumber();

          if (defaultDidOfUser && defaultDidOfUser.id === did.id) {
            console.log('Did is default did of current agent', c.number);
            foundNumber = true;
          }
        }

        // Preferred item is secretary did of current agent
        if (!foundNumber) {
          // Next preferred item is secretary did of user
          const secretaryDidOfUser: DidEntity = currentUser && currentUser.secretary_did;

          if (secretaryDidOfUser && secretaryDidOfUser.id === did.id) {
            console.log('Did is secretary did of current agent', c.number);
            foundNumber = true;
          }
        }

        // Preferred item is routing to default queue
        const routedQueue: CallQueueEntity = did.routed_queue;
        if (routedQueue && routedQueue.id === this.defaultQueueId) {
          console.log('Did is is routing to default queue', c.number);
          foundNumber = true;
        }

        // Preferred item is routing to default queue out of office hours
        if (!foundNumber) {
          const routedQueueOOH: CallQueueEntity = did.out_office_hours_routed_queue;
          if (routedQueueOOH && routedQueueOOH.id === this.defaultQueueId) {
            console.log('Did is is routing to default queue out of office hours', c.number);
            foundNumber = true;
          }
        }

        // Preferred item is routing to default queue of master did
        const masterDid: DidEntity = did.master_did;
        if (!foundNumber) {
          const routedQueueMaster: CallQueueEntity = masterDid && masterDid.routed_queue;
          if (routedQueueMaster && routedQueueMaster.id === this.defaultQueueId) {
            console.log('Did is is routing to default queue of master did', c.number);
            foundNumber = true;
          }
        }

        // Preferred item is routing to default queue for out or office hours of master did
        if (!foundNumber) {
          const routedQueueOOHMaster: CallQueueEntity = masterDid && masterDid.out_office_hours_routed_queue;
          if (routedQueueOOHMaster && routedQueueOOHMaster.id === this.defaultQueueId) {
            console.log('Did is is routing to default for out or office hoursqueue of master did', c.number);
            foundNumber = true;
          }
        }
        
        // Preferred item is routing to ivr-menu //related to default queue
        if (!foundNumber) {
          let didRoutedToIvrMenuRelatedToQueue = false;
          if (did.isDestType(RoutingAppName.ivr_custom_menu)) {
            didRoutedToIvrMenuRelatedToQueue = true;

            // const routedIvrMenu: IvrCustomMenuEntity = did.routed_menu;
            // const routedIvrMenuOOH: IvrCustomMenuEntity = did.out_office_hours_routed_menu;
  
            // const routedIds = [];
            // for (const r of [routedIvrMenu, routedIvrMenuOOH]) {
            //   if (!r || _.includes(routedIds, r.id)) {
            //     continue;
            //   }
            //   routedIds.push(r.id);
  
            //   if (r.routed_queues && r.routed_queues.find(x => x.id === this.defaultQueueId)) {
            //     didRoutedToIvrMenuRelatedToQueue = true;
            //     break;
            //   }
            // }
          }

          if (didRoutedToIvrMenuRelatedToQueue) {
            console.log('Did is is routing to ivr-menu related to default queue', c.number);
            foundNumber = true;
          }
        }
        
        // Preferred item is routing to remote application //related to default queue
        if (!foundNumber) {
          let didRoutedToRemoteAppRelatedToQueue = false;
          if (did.isDestType(RoutingAppName.remote_application)) {
            didRoutedToRemoteAppRelatedToQueue = true;

            // const routedRemoteApp: IvrRemoteAppEntity = did.routed_remote_application;
            // const routedRemoteAppOOH: IvrRemoteAppEntity = did.out_office_hours_routed_remote_application;
  
            // const routedIds = [];
            // for (const r of [routedRemoteApp, routedRemoteAppOOH]) {
            //   if (!r || _.includes(routedIds, r.id)) {
            //     continue;
            //   }
            //   routedIds.push(r.id);
  
            //   const crmRoutingRules = r.routedCrmRoutings;
            //   for (const crmRoutingRule of crmRoutingRules) {
            //     const crmRoutedQueue = crmRoutingRule.routed_queue;
            //     const crmRoutedQueueConditional = crmRoutingRule.routed_queues_conditional;
  
            //     if (crmRoutedQueue && crmRoutedQueue.id === this.defaultQueueId ||
            //       crmRoutedQueueConditional && crmRoutedQueueConditional.find(x => x.id === this.defaultQueueId)) {
            //       didRoutedToRemoteAppRelatedToQueue = true;
            //       break;
            //     }
            //   }
  
            //   if (didRoutedToRemoteAppRelatedToQueue) {
            //     break;
            //   }
            // }
          }

          if (didRoutedToRemoteAppRelatedToQueue) {
            console.log('Did is routing to remote application related to default queue', c.number);
            foundNumber = true;
          }
        }

        if (foundNumber) {
          filteredList.push(c);
        }
      }
    } else {
      filteredList = numberList;
    }

    if (this.configManager.getSystemParam(SystemParam.telecom_has_anonymous)) {
      filteredList.push(this.anonymousCallingNumber);
    }

    this._callingNumberList.next(filteredList);

    return filteredList;
  }

  getMyDefaultCallingDID(): CallingNumber {
    return this.defaultCallingNumber;
  }

  async getMyCallingNumberByNumber(phone_number: string): Promise<CallingNumber> {
    const callingNumbers = await this.getCallingNumbers();
    const phoneNumberNormalize = this.phoneNumberService.normalizeNumber(phone_number);
    return callingNumbers.find(callingNumber => callingNumber.number === phoneNumberNormalize);
  }

  async getDefaultCallQueue(): Promise<CallQueueEntity> {
    let callQueue: CallQueueEntity;
    if (!this.defaultQueueId) {
      const defaultQueueId = await this.getPhoneCallingInfoCallingQueue();

      callQueue = this.queueRepo.getObjectById(defaultQueueId);
      await this.setDefaultQueue(callQueue && callQueue.id || null, false);
    } else {
      callQueue = this.queueRepo.getObjectById(this.defaultQueueId);
    }

    return callQueue || this.queueRepo.getEmptyCallQueue();
  }

  async getDefaultCallingNumber(): Promise<CallingNumber> {
    if (!this.defaultCallingNumber) {
      const filteredList = await this.reloadCallingNumbers();

      // Get from local storage
      const defaultCallingNumber: string = await this.getPhoneCallingInfoCallingNumber();
      let callingNumber: CallingNumber;
      if (defaultCallingNumber) {
        callingNumber = filteredList.find(x => x.number === defaultCallingNumber);
      }

      if (!callingNumber) {
        callingNumber = await this.getDefaultCallingNumberByCallQueue(this.defaultQueueId);
      }

      await this.setDefaultCallingNumber(callingNumber);
    }

    return this.getMyDefaultCallingDID();
  }

  getDefaultQueueId(): number {
    return this.defaultQueueId;
  }

  getRingtones(): Promise<FileEntryEntity[]> {
    return HttpEngine.getInstance().apiGetV2(this.RINGTONES_PATH);
  }

  deleteRingtones(public_ids: string[]): Promise<any> {
    return HttpEngine.getInstance().apiPostV2(this.DELETE_RINGTONES_PATH, { public_ids });
  }

  private setPhoneCallingInfoCallingNumber(callingNumber: string): Promise<void> {
    return this.setPhoneCallingInfo({ calling_number: callingNumber });
  }

  private setPhoneCallingInfoCallingQueue(queueId: number): Promise<void> {
    return this.setPhoneCallingInfo({ calling_queue_id: queueId });
  }

  private async getPhoneCallingInfoCallingNumber(): Promise<string> {
    const callingInfo = await this.getPhoneCallingInfo();
    return callingInfo && callingInfo.calling_number || null;
  }

  private async getPhoneCallingInfoCallingQueue(): Promise<number> {
    const callingInfo = await this.getPhoneCallingInfo();
    return callingInfo && callingInfo.calling_queue_id || null;
  }

  private getPhoneCallingInfoKey(): string {
    const currentUserId: string = this.authService.getUserId() ? this.authService.getUserId().toString() : 'guest';
    return `phone_calling_info_id_${currentUserId}`;
  }

  async getPhoneCallingInfo(): Promise<IStoragePhoneCallingInfo> {
    const data = await this.storage.getDomainConfig(this.getPhoneCallingInfoKey()) || {};
    console.log('getPhoneCallingInfo', data);
    return data || {};
  }

  private async setPhoneCallingInfo(appendData: any): Promise<void> {
    const data = await this.getPhoneCallingInfo();

    const phoneCallingInfo: IStoragePhoneCallingInfo = _.assign(_.cloneDeep(data) || {}, appendData);
    console.log('setPhoneCallingInfo', phoneCallingInfo);
    return this.storage.setDomainConfig(this.getPhoneCallingInfoKey(), phoneCallingInfo);
  }

}
