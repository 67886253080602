import * as _ from 'lodash';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  Renderer2,
  ViewChild
} from '@angular/core';
import { OutcallCampaignEntity } from '@wephone-core/model/entity/outcallcampaign';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { OutcallCampaignRepository } from '@wephone-core/model/repository/outcallcampaign';
import { OutcallcampaignBaseComponent } from '@wephone/components/outcallcampaign/outcallcampaign-base/outcallcampaign-base.component';
import { TranslateService } from '@ngx-translate/core';
import {
  DialogService,
  ToastService,
  IChangeAwareComponent,
  toTimeHour,
  parseDateTime,
  NoWhitespaceValidator
} from '@wephone-utils';
import { UserService } from '@wephone/services/user.service';
import { Router } from '@angular/router';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { BoType, CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { _ti } from '@wephone-translation';
import { DateAdapter } from '@angular/material/core';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { UrlValidator } from '@wephone-common/form/inputs/url-input/url-validate';
import { NumberOpeningCalendar } from '@wephone/components/number-opening-calendar/number-opening-calendar.component';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { ArrayHelper } from '@wephone-core/helpers/array.helper';
import { OutcallCampaignaAMAction } from '@wephone-core/model/entity/outcallcampaign.i';

@Component({
  selector: 'app-outcallcampaign-general',
  templateUrl: './outcallcampaign-general.component.html',
  styleUrls: ['./outcallcampaign-general.component.scss']
})
export class OutcallcampaignGeneralComponent extends OutcallcampaignBaseComponent
  implements OnInit, OnChanges, IChangeAwareComponent {
  static ValidatorsMaxAMDuration = [Validators.min(1), Validators.max(600), Validators.required];

  @Input() campaign: OutcallCampaignEntity;
  @Input() queue: CallQueueEntity;
  @Input() multipleEdit: boolean;
  @Output() readonly formValueChanges: EventEmitter<boolean>;
  @ViewChild('numberOpeningCalendar') numberOpeningCalendar: NumberOpeningCalendar;

  readonly backOfficeType = BoType;

  form: FormGroup;

  campaign_list: OutcallCampaignEntity[];
  after_call_time_object = {};

  amActionList = ArrayHelper.getEnumValues(OutcallCampaignaAMAction).map((value: number) => ({
    name: `campaign.am_action.am_action_${value}`,
    value
  }));

  campaign_types = [
    { name: 'campaign.call_agent_first', value: 0 },
    { name: 'campaign.call_client_first', value: 1 }
  ];

  priority_list = [
    { value: 0, label: 'call_queue.content.priority_level.very_high' },
    { value: 1, label: 'call_queue.content.priority_level.high' },
    { value: 2, label: 'call_queue.content.priority_level.normal' },
    { value: 3, label: 'call_queue.content.priority_level.low' },
    { value: 4, label: 'call_queue.content.priority_level.very_low' }
  ];

  ivr_list: IvrCustomMenuEntity[];

  hasInitialized: boolean;
  hasQualifications: boolean;
  listenFunc: Function;

  isAdmin: boolean;

  constructor(
    translate: TranslateService,
    protected dialogService: DialogService,
    protected userService: UserService,
    protected toast: ToastService,
    protected router: Router,
    private readonly fb: FormBuilder,
    private readonly configManager: ConfigManager,
    dateAdapter: DateAdapter<Date>,
    private readonly em: EntityManager,
    private readonly authService: AuthenticationService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {
    super(translate, dialogService, userService, toast, router);

    this.listenFunc = this.renderer.listen(this.elementRef.nativeElement, 'click', (event: any) => {
      if (
        event &&
        event.srcElement.classList.length > 0 &&
        event.srcElement.classList.contains('link_create_qualification')
      ) {
        this.router.navigate(['callcenter-params/qualification']);
      }
    });

    this.dateAdapter = dateAdapter;
    this.formValueChanges = new EventEmitter<boolean>();

    this.campaign_list = OutcallCampaignRepository.getInstance().getObjectList();
  }

  get show_timepicker(): boolean {
    return this.form && !this.form.get('opening_hour_calendar_id').value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      return;
    }
    this.initFormGroup();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.isAdmin = this.authService.isAdmin();

    if (!this.campaign) {
      return;
    }

    await this.em.getRepository('QualificationRepository').findAll();

    this.initFormGroup();
    this.hasInitialized = true;
    const qualifications = this.em
      .getRepository<QualificationRepository>('QualificationRepository')
      .getValidRootObjectList();
    this.hasQualifications = !_.isEmpty(qualifications);
  }

  onChangeAfterCallPauseMode($event: MatSelectChange): void {
    this.form.get('after_call_time').markAsDirty();
  }

  numberOpeningCalendarChange(): any {
    const value = this.numberOpeningCalendar.getChangeSet();
    const openingCalendar: OpeningHourCalendarEntity = value.opening_calendar;
    this.form.get('opening_hour_calendar_id').setValue(openingCalendar);
    this.form.get('opening_hour_calendar_id').markAsDirty();

    this.onFormValueChange();
  }

  async updateCampaignAttrs(campaign: OutcallCampaignEntity, fieldList: string[], extra_data: any = {}): Promise<any> {
    if (!this.isValidFormDate(fieldList)) {
      console.error('Schedule date is incorrect');
      return undefined;
    }

    return OutcallCampaignRepository.getInstance().saveAttrs(campaign, fieldList, extra_data);
  }

  markAllAsDirty(): void {
    this.form.markAllAsTouched();
    this.formService.markGroupDirty(this.form);
  }

  getChangeSet(): any {
    const ret = super.getChangeSet();
    if ('name' in ret) {
      ret.name = _.trim(ret.name);
    }
    if (ret['out_qualification_id'] && !ret['has_qualification']) {
      ret['has_qualification'] = this.form.get('has_qualification').value ? 1 : 0;
    }

    if (_.hasIn(ret, 'bo_type') && ret.bo_type !== this.backOfficeType.URL && _.hasIn(ret, 'bo_url')) {
      delete ret.bo_url;
    }

    const boType = ret.bo_type || this.form.get('bo_type').value;

    if (boType === this.backOfficeType.URL && (_.hasIn(ret, 'bo_url') || _.hasIn(ret, 'bo_method'))) {
      const boMethod = ret.bo_method || this.form.get('bo_method').value;
      const boUrl = ret.bo_url || this.form.get('bo_url').value;
      ret.bo_url = boMethod === 'POST' ? `${boMethod}|${boUrl}` : boUrl;
    }

    if (_.hasIn(ret, 'bo_method')) {
      delete ret.bo_method;
    }

    return ret;
  }

  markAsDateChanged(): void {
    this.form.get('schedule_end_date').markAsTouched();
    this.form.get('schedule_end_date').markAsDirty();

    this.form.get('schedule_start_date').markAsTouched();
    this.form.get('schedule_start_date').markAsDirty();
  }

  markAsHourChanged(hourControl: string = null): void {
    const hourControls = hourControl ? [hourControl] : ['hour_start', 'hour_end'];
    for (const h of hourControls) {
      this.form.get(h).markAsTouched();
      this.form.get(h).markAsDirty();
    }
  }

  private isValidFormAM(fieldList: string[]): boolean {
    if (!_.intersection(['am_action', 'am_check_enabled', 'am_check_max_duration'], fieldList).length) {
      return true;
    }

    this.resetErrorForms(['am_action', 'am_check_max_duration']);
    let valid = true;
    if (this.form.get('am_action').invalid) {
      this.form.get('am_action').markAsTouched();
      valid = false;
    }

    if (this.form.get('am_check_max_duration').invalid) {
      this.form.get('am_check_max_duration').markAsTouched();
      valid = false;
    }

    return valid;
  }

  private isValidFormName(fieldList: string[]): boolean {
    let isValid = true;
    this.resetErrorForm('name');

    if (this.multipleEdit) {
      return true;
    }

    if (this.isSubmitField('name', fieldList)) {
      if (!this.form.get('name').value) {
        this.form.get('name').setErrors({ required: true });
        this.form.get('name').markAsTouched();
        isValid = false;
      }

      if (this._isExistCampaignName(this.form.get('name').value.trim())) {
        this.form.get('name').setErrors({
          exists: true
        });
        this.form.get('name').markAsTouched();
        isValid = false;
      }
    }
    if (!isValid) {
      console.error('Invalid form name');
    }
    return isValid;
  }

  private isValidFormDate(fieldList: string[]): boolean {
    let isValid = true;
    const forms = ['schedule_start_date', 'schedule_end_date', 'hour_start', 'hour_end'];

    if (_.intersection(forms, fieldList).length === 0) {
      return true;
    }

    this.resetErrorForms(forms);

    const scheduleStartDate: Date = this.form.get('schedule_start_date').value;
    const scheduleEndDate: Date = this.form.get('schedule_end_date').value;

    const scheduleStartNumber = scheduleStartDate && +parseDateTime(scheduleStartDate).toFormat('yyyyMMdd');
    const scheduleEndNumber = scheduleEndDate && +parseDateTime(scheduleEndDate).toFormat('yyyyMMdd');

    if (scheduleStartNumber && scheduleEndNumber && scheduleStartNumber > scheduleEndNumber) {
      this.form.get('schedule_end_date').setErrors({
        small: true
      });
      this.form.get('schedule_end_date').markAsTouched();
      isValid = false;
    }

    if (this.show_timepicker) {
      if (!this.multipleEdit && !this.form.get('hour_start').value && this.form.get('hour_end').value) {
        this.form.get('hour_start').setErrors({
          required: true
        });
        this.form.get('hour_start').markAsTouched();
        isValid = false;
      }

      if (!this.multipleEdit && !this.form.get('hour_end').value && this.form.get('hour_start').value) {
        this.form.get('hour_end').setErrors({
          required: true
        });
        this.form.get('hour_end').markAsTouched();
        isValid = false;
      }

      if (scheduleStartNumber && 
        scheduleStartNumber === scheduleEndNumber &&
        this.form.get('hour_start').value &&
        this.form.get('hour_end').value
      ) {
        const hourStart: number = +this.form.get('hour_start').value.replace(/\D/g, '');
        const hourEnd: number = +this.form.get('hour_end').value.replace(/\D/g, '');

        if (hourStart > hourEnd) {
          this.form.get('hour_end').setErrors({
            small: true
          });
          this.form.get('hour_end').markAsTouched();
          isValid = false;
        }
      }
    }

    if (!isValid) {
      console.error('Invalid form date');
      for (const f of forms) {
        this.form.get(f).markAsTouched();
      }
    }

    return isValid;
  }

  private isValidFormBoType(fieldList: string[]): boolean {
    if (!_.intersection(fieldList, ['bo_type', 'bo_url']).length) {
      return true;
    }
    let isValid = true;

    const boTypeControl = this.form.get('bo_type');
    const boUrlControl = this.form.get('bo_url');

    const boType = boTypeControl.value;
    const boUrl = _.trim(boUrlControl.value || '');

    this.resetErrorForms(['bo_type']);

    if (!_.includes([0, 1, 2], boType)) {
      this.form.get('bo_type').setErrors({ required: true });
      this.form.get('bo_type').markAsTouched();
      console.error('Invalid form bo-type');
    }

    if (boType === 2) {
      if (!boUrl && !this.multipleEdit) {
        this.form.get('bo_url').setErrors({ required: true });
        this.form.get('bo_url').markAsTouched();
      }
    } else {
      this.resetErrorForms(['bo_url']);
    }

    if (!_.isEmpty(boTypeControl.errors) || !_.isEmpty(boUrlControl.errors)) {
      isValid = false;
    }

    return isValid;
  }

  private resetErrorForms(names: string[]): void {
    for (const name of names) {
      this.resetErrorForm(name);
    }
  }

  private resetErrorForm(name: string): void {
    this.form.get(name).setErrors(null);
    this.form.get(name).updateValueAndValidity();
  }

  private isValidFormQualification(fieldList: string[]): boolean {
    if (!_.intersection(['has_qualification', 'out_qualification_id'], fieldList).length) {
      return true;
    }

    this.resetErrorForm('out_qualification_id');
    if (
      !this.multipleEdit &&
      this.form.get('has_qualification').value &&
      (!this.form.get('out_qualification_id').value || !this.form.get('out_qualification_id').value.id)
    ) {
      this.form.get('out_qualification_id').setErrors({
        required: true
      });
      this.form.get('out_qualification_id').markAsTouched();
      console.error('Invalid form qualification');
      return false;
    }

    return true;
  }

  isValidForm(fieldList: string[]): boolean {
    if (!this.multipleEdit && !this.campaign.id) {
      throw new Error('Outcall Campaign not found');
    }

    if (!this.multipleEdit && !this.queue.id) {
      throw new Error('Outcall Campaign Queue not found');
    }

    // this.form.clearValidators();
    // this.form.updateValueAndValidity();

    const isValid: boolean =
      this.isValidFormName(fieldList) &&
      this.isValidFormDate(fieldList) &&
      this.isValidFormBoType(fieldList) &&
      this.isValidFormAM(fieldList) &&
      this.isValidFormQualification(fieldList);

    if (!this.form.valid && !this.multipleEdit) {
      this.form.markAllAsTouched(); // Mark all touched to show error message for all fields in case of single campaign
    }

    return isValid && this.form.valid;
  }

  private _isExistCampaignName(campaign_name: string): boolean {
    const is_exist = this.campaign_list.filter((item: OutcallCampaignEntity) => {
      return item.name === campaign_name && item.id !== this.campaign.id;
    });

    return is_exist.length > 0;
  }

  isShowGroup(): boolean {
    return (UserGroupRepository.getInstance().getObjectList() || []).length > 1 ? true : false;
  }

  initFormGroup(): void {
    const scheduleStartDate = this.campaign.schedule_start_date && this.campaign.schedule_start_date.toJSDate();
    const scheduleEndDate = this.campaign.schedule_end_date && this.campaign.schedule_end_date.toJSDate();

    const validateName: any[] = !this.multipleEdit
      ? [Validators.required, Validators.maxLength(255), NoWhitespaceValidator]
      : undefined;
    const maxTryCount: number = !_.isUndefined(this.campaign.max_try_count) ? this.campaign.max_try_count : 3;

    let boUrl: string = this.queue.bo_url;
    const boMethod: 'POST' | 'GET' = boUrl && boUrl.startsWith('POST|') ? 'POST' : 'GET';
    boUrl = (boUrl || '').replace('POST|', '');

    this.form = this.fb.group({
      name: [this.campaign.name, validateName],
      schedule_start_date: [scheduleStartDate],
      schedule_end_date: [scheduleEndDate],
      hour_start: [toTimeHour(this.campaign.hour_start)],
      hour_end: [toTimeHour(this.campaign.hour_end)],
      max_try_count: [maxTryCount, [Validators.required, Validators.min(1), Validators.max(100)]], // default is 3
      group_id: [this.queue.group],
      calling_number_id: [this.campaign.callingNumber],
      ivr_id: [this.campaign.ivr],
      recording_mode: [this.queue.recording_mode],
      has_qualification: [this.queue.has_qualification],
      out_qualification_id: [this.queue.out_qualification],
      queue_priority: [this.queue.queue_priority],
      campaign_type: [this.campaign.campaign_type],
      am_action: [this.campaign.am_action],
      am_check_enabled: [!!this.campaign.am_check_enabled],
      am_check_max_duration: [this.campaign.am_check_max_duration],
      aftercall_pause_mode: [!!this.queue.aftercall_pause_mode],
      after_call_time: [this.queue.after_call_time, [Validators.min(0), Validators.max(3600)]],
      bo_type: [this.queue.bo_type],
      bo_method: [boMethod],
      bo_url: [boUrl, [UrlValidator.isValidUrl, Validators.maxLength(512)]],
      opening_hour_calendar_id: [this.queue.opening_calendar]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );

    if (!this.multipleEdit) {
      this.addSubscription(
        this.form.get('am_check_enabled').valueChanges.subscribe((value: boolean) => {
          this.setValidatorsMaxAMDuration();
  
          if (value) {
            this.form.get('am_action').markAsDirty();
            this.form.get('am_check_max_duration').markAsDirty();
          }
        })
      );

      this.setValidatorsMaxAMDuration();
    }
  }

  // Form actions
  removeControlValue(controlName: string): void {
    this.form.controls[controlName].setValue(undefined);
    this.form.controls[controlName].markAsTouched();
  }

  async submitForm(): Promise<any> {}

  formHasChanged(): boolean {
    return this.form && this.form.dirty;
  }

  resetForm(): void {
    if (!this.form) {
      return;
    }
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  private setValidatorsMaxAMDuration(): void {
    this.form.get('am_action').clearValidators();
    this.form.get('am_check_max_duration').clearValidators();

    if (this.form.get('am_check_enabled').value) {
      this.form.get('am_action').setValidators(Validators.required);
      this.form.get('am_check_max_duration').setValidators(OutcallcampaignGeneralComponent.ValidatorsMaxAMDuration);
    }

    this.form.get('am_action').updateValueAndValidity();
    this.form.get('am_check_max_duration').updateValueAndValidity();
  }

  private getFormResetData(): any {
    if (!this.queue.after_call_time) {
      this.queue.after_call_time = 0;
    }

    let boUrl: string = this.queue.bo_url;
    const boMethod: 'POST' | 'GET' = boUrl && boUrl.startsWith('POST|') ? 'POST' : 'GET';
    boUrl = (boUrl || '').replace('POST|', '');

    return {
      name: this.campaign.name,
      schedule_start_date: this.campaign.schedule_start_date && this.campaign.schedule_start_date.toJSDate(),
      schedule_end_date: this.campaign.schedule_end_date && this.campaign.schedule_end_date.toJSDate(),
      hour_start: toTimeHour(this.campaign.hour_start),
      hour_end: toTimeHour(this.campaign.hour_end),
      group_id: this.queue.group,
      calling_number_id: this.campaign.callingNumber,
      ivr_id: this.campaign.ivr,
      recording_mode: this.queue.recording_mode,
      has_qualification: this.queue.has_qualification,
      out_qualification_id: this.queue.out_qualification,
      queue_priority: this.queue.queue_priority,
      aftercall_pause_mode: !!this.queue.aftercall_pause_mode,
      after_call_time: this.queue.after_call_time,
      campaign_type: this.campaign.campaign_type,
      am_action: this.campaign.am_action,
      am_check_enabled: !!this.campaign.am_check_enabled,
      am_check_max_duration: this.campaign.am_check_max_duration,
      bo_type: this.queue.bo_type,
      bo_method: boMethod,
      bo_url: boUrl,
      opening_hour_calendar_id: this.queue.opening_calendar
    };
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }
}
