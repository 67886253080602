import { IEntity } from '@wephone-core/model/model.interface';
import { IUserEntity } from './user.i';
import { IDidEntity } from './did.i';
import { IHotelRoomEntity } from './hotelroom.i';

export enum SipphoneKeyConfigType {
  Supervision = 'supervision',
  Phoneline = 'phoneline',
  Speeddial = 'speeddial',
  Undefined = 'undefined'
}

export interface ISipPhoneKeyConfig {
  type: SipphoneKeyConfigType;
  data: any;
  label: string;
}


export interface ISipPhoneCustomConfig {
  wifi_enable: 0 | 1;
  wifi_ssid?: string;
  wifi_password?: string;
  wifi_secure_mode?: string;
  wifi_encryption_type?: string;

  dhcp: 0 | 1;
  auto_dns?: 0 | 1;
  ip?: string;
  subnet_mask?: string;
  gateway?: string;
  primary_dns?: string;
  secondary_dns?: string;

  vlan_pc_enabled: 0 | 1;
  vlan_pc_id?: number;

  vlan_phone_enabled: 0 | 1;
  vlan_phone_id?: number;

  codec_list: SipphoneCodec[];
  background_image: string;
}

export enum SipphoneCodec {
  PCMA = 'PCMA',
  PCMU = 'PCMU',
  GSM = 'GSM',
  G729 = 'G729',
  OPUS = 'OPUS',
}


export enum SipphoneSecureMode {
  WPA = 'WPA/WPA2-PSK',
  EAP = '802.1x EAP'
}

export enum SipphoneEncryptionType {
  TKIP = 'TKIP',
  AES = 'AES(CMPP)',
  TKIP_AES = 'TKIP+AES(CMPP)'
}

export interface ISipphoneEntity extends IEntity {
  id: number;
  extension: string;
  extension_with_owner: string;
  password: string;
  user_id: number;
  is_online: number;
  last_user_agent: string;
  enabled: number;
  phone_mode: number;
  calling_profile_id: number;
  calling_number_id: number;
  description: string;
  last_online_dt: any;
  hotel_room_id: number;
  did_calling_number: IDidEntity;
  user: IUserEntity;
  hotelroom: IHotelRoomEntity;
  key_config: ISipPhoneKeyConfig[];
}

export enum SipphonePhoneModeType {
  Default = 0,
  Office = 1,
  CallCenter = 2
}

export enum PhoneModelType {
  grandstream_wp820 = 'grandstream_wp820',
  grandstream_gxp17xx = 'grandstream_gxp17xx',
  grandstream_grp26xx = 'grandstream_grp26xx',
  yealink_t33g = 'yealink_t33g',
  yealink_t53w = 'yealink_t53w',
  yealink_t54w = 'yealink_t54w',
  yealink_t42x = 'yealink_t42x',
  xenios_icd012 = 'xenios_icd012',
  xenios_itl010 = 'xenios_itl010',
  fanvil_h2u = 'fanvil_h2u',
  fanvil_h5w = 'fanvil_h5w',
  fanvil_h3w = 'fanvil_h3w'
}

export interface ISipPhoneCustomSettings {
  phone_model: PhoneModelType;
  screen_key_count: number;
  available_codecs: SipphoneCodec[];
  default_config: ISipPhoneCustomConfig;
}
