import { Injectable } from '@angular/core';
// import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { ToastService } from '@wephone-utils';
import * as _ from 'lodash';
import { EnterpriseEvolutionPeriod, EnterpriseOAuthSettings, EnterpriseOAuthSettingsReq } from './enterprise.service.i';
import { IEnterpriseConsumptionData } from '@wephone-core/model/entity/enterprise_consumption.i';
interface ConnectAsResp {
  loginUrl: string;
  domain: string;
  token: string;
}
@Injectable()
export class EnterpriseService {
  // private static URL_ENTERPRISE_UPDATE_WEBCALLBACK = '/api/enterprise/update_web_callback';
  // private static URL_UPDATE_CONTACT_FORMAT = '/api/contact_format/update_contact_format';
  // private static URL_ENTERPRISE_UPDATE_APIKEY = '/api/enterprise/update_apikey';
  private static readonly URL_ENTERPRISE_OAUTH_SETTINGS = '/api/enterprise/oauth_settings';
  private static readonly URL_ENTERPRISE_CONNECT_AS = '/api/enterprise/connect_as';
  private static readonly URL_ENTERPRISE_SUBSCRIBE_PACK = '/api/enterprise/subscribe_pack';
  private static readonly URL_ENTERPRISE_VALIDATE_REGISTRATION = '/api/enterprise/validate_registration';
  private static readonly URL_ENTERPRISE_EVOLUTION_STATS_DATA = 'enterprise/evolution_stats_data/__ENTERPRISE_ID__';
  private static readonly UPDATE_ENTERPRISE_PARAMETERS_URL = '/api/enterprise_parameters';
  private static readonly START_BILLING_PATH = '/api/enterprise/start-billing';
  private static readonly RESELLER_ENTERPRISE_LIST_PATH = 'reseller/enterprise/list';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly toast: ToastService,
    private readonly settings: FlexIvrSettings
  ) { }

  async connectAsEnterprise(enterpriseId: number, userId: number = null, version: string = 'v3', returnUrl: string = null): Promise<any> {
    try {
      const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.URL_ENTERPRISE_CONNECT_AS);

      const params = {
        enterprise_id: enterpriseId,
        user_id: userId,
        version
      };

      if (returnUrl) {
        _.extend(params, {
          return_url: returnUrl
        });
      }

      const result: ConnectAsResp = await this.httpEngine.post(url, params);

      window.open(result.loginUrl, '_blank');
    } catch (error) {
      console.error('connectAs error: ', error);
      this.toast.showError(error.message || 'Connect failure');
    }
  }

  getEnterpriseOAuthSettings(): Promise<EnterpriseOAuthSettings[]> {
    try {
      const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.URL_ENTERPRISE_OAUTH_SETTINGS);
      return this.httpEngine.get(url);
    } catch (error) {
      console.error('connectAs error: ', error);
      this.toast.showError('Cannot get OAuth settings');
    }
  }

  updateEnterpriseOAuthSettings(data: EnterpriseOAuthSettingsReq[]): Promise<any> {
    try {
      const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.URL_ENTERPRISE_OAUTH_SETTINGS);
      return this.httpEngine.post(url, { oauths: data });
    } catch (error) {
      console.error('connectAs error: ', error);
      this.toast.showError('Cannot update OAuth settings');
    }
  }

  subscribePack(
    enterprise_id: number,
    subscription_pack: any,
    subscription_expire_date: Date,
    has_expired_date: number,
    subscription_start_date: Date
  ): Promise<any> {
    const params: any = {
      id: enterprise_id,
      subscription_pack,
      subscription_expire_date,
      has_expired_date,
      subscription_start_date
    };

    const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.URL_ENTERPRISE_SUBSCRIBE_PACK);
    return this.httpEngine.post(url, params);
  }

  validateRegistration(enterprise_id: number): Promise<any> {
    const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.URL_ENTERPRISE_VALIDATE_REGISTRATION);
    return this.httpEngine.post(url, { enterprise_id });
  }

  // updateWebCallback(event: string, url: string): Promise<any> {
  //   return this.httpEngine.post(EnterpriseService.URL_ENTERPRISE_UPDATE_WEBCALLBACK, { event, url });
  // }

  // getSystemParams(): Observable<any> {
  //   const url: string = this.config_manager.getURL('enterprise/get_system_params');
  //   return this.http.get(url, null);
  // }

  // getEnterpriseLogoUrl(enterprise: EnterpriseEntity): string {
  //   if (!enterprise || !enterprise.logo_id) {
  //     return FlexIvrSettings.getInstance().getAbsoluteUrl('/bundles/flexivrangularui/assets/img/logo-company.png');
  //   }
  //   return this.settings.getEnterpriseUrl(
  //     '/resource/enterprise/logo/' + enterprise.getId() + '?logo_id=' + enterprise.logo_id
  //   );
  // }

  // updateSystemParams(params): Observable<any> {
  //   const url: string = this.config_manager.getURL('enterprise/vt_update_system_params');
  //   return this.http.post(url, params);
  // }

  // updateContactFormat(contact_format): Observable<any> {
  //   console.log('contact_format: ', contact_format);
  //   return this.http.post(EnterpriseService.URL_UPDATE_CONTACT_FORMAT, { contact_format: [contact_format] });
  // }

  generateApiKey(length: number): string {
    const mask = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) {
      result += mask[Math.round(Math.random() * (mask.length - 1))];
    }
    return result;
  }

  updateEnterpriseParammeters(data: {
    id?: number,
    enterprise_id?: number,
    cc_allow_call_direction_selection?: number,
    disable_phone_in_iframe?: number,
    dialer_queue_selector?: number,
    agent_auto_disconnect?: number,
    my_own_recorded_calls?: number,
    text2speech_service?: number,
    eleven_labs_key?: string,
    group_management?: number,
    sms_enable?: number,
    sipphone_provisioning?: number,
    call_quality_enable?: number,
    call_analysis_enable?: number,
    buy_number_from_provider?: number,
    speech2text?: number,
    anti_spam?: number,
    sip_trunk_enable?: number,
    enterprise_consumption?: IEnterpriseConsumptionData
  }): Promise<any> {
    const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.UPDATE_ENTERPRISE_PARAMETERS_URL);
    return this.httpEngine.post(url, data, {}, false);
  }

  startBilling(data: any): Promise<any> {
    const url = this.settings.getAbsoluteWSv2URL(EnterpriseService.START_BILLING_PATH);
    return this.httpEngine.post(url, data, {}, false);
  }

  resellerGetEnterprises(): Promise<any> {
    return this.httpEngine.apiGetV2(EnterpriseService.RESELLER_ENTERPRISE_LIST_PATH);
  }

  getEnterpriseEvolutionStatsData(enterpriseId: number, data?: {
    period?: EnterpriseEvolutionPeriod;
    start_date?: Date;
    end_date?: Date;
  }): Promise<any> {
    const url = EnterpriseService.URL_ENTERPRISE_EVOLUTION_STATS_DATA.replace('__ENTERPRISE_ID__', `${enterpriseId}`);
    return this.httpEngine.apiGetV2(url, data);
  }
}
