import { ICallLog } from "@wephone-app-phone/pages/history/calllog.i";

export enum TranscriptionStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED'
}

export enum RCTranscriptionState {
    NoTranscription = 0,
    TranscriptionInProgress = 1,
    TranscriptionDone = 2,
    TranscriptionFailed = 3,
}

export enum RCAnalysisState {
    NoAnalysis = 0,
    AnalysisInProgress = 1,
    AnalysisDone = 2,
    AnalysisFailed = 3,
}

export interface TranscriptAnalysisData {
    analysis_date: string; // "Tue, 26 Sep 2023 08:38:52 GMT"
    json_result: {
        credit_card_info: string; // "----------------",
        emotion: string; // "Neutral",
        list_offend_customer: string; // "RAS",
        list_offend_operator: string; // "RAS",
        repetition: string; // "PAS DE REPETITIONS",
        requested_psychic: string; // "aucun",
        resume: string; // "The caller, Can from Vietnam, greeted the operator.",
        technical_problems: string; // "pas de problème"
    }; // "Tue, 26 Sep 2023 08:38:52 GMT"
    transcript_analysis_uuid: string; // "72ee2aa8-24d2-4562-9941-c94d5f8552cb"
}

export interface IRecordingCall {
    recorded_dt: string;
    queue_name: string;
    local_number: string;
    duration: number;
    remote_number: string;
    client_name: string;
    direction: number;
    analysis_enabled: number;
    user_ids: number[];
    id: number;
    file_public_id: string;
    file_entry_ready: number;
    audio_state: number;
    cdr_id: number;
    cdr_public_id: string;
    has_qualification: number;
    in_qualification_id: number;
    out_qualification_id: number;
    qualification: string;
    qualification_ids: number[];
    qualification_comment: string;
    queue_ids: number[];
    handled_user_ids: number[];
    call_log?: ICallLog;
    transcription_state: RCTranscriptionState;
    transcription_start_dt: string;
    transcription: string;
    analysis_state: RCAnalysisState;
    analysis_start_dt: string;
    analysis_result: TranscriptAnalysisData;
}
