<div fxLayout="column">

  <div fxLayout="row" fxFlexAlign="start start">
    <!--Avatar-->
    <div fxFlex.xs="50%" fxFlex.gt-xs="160px">
      <div fxLayoutAlign="center">
        <user-avatar [uploadAvatar]="true" [hidden]="!loggedUser" [user]="loggedUser" width="80px" height="80px">
        </user-avatar>
      </div>

      <div fxLayoutAlign="center" class="mt-1">
        <a class="link" (click)="openChangeUserPasswordDialog($event)">{{ 'resetpass.btmchange' | translate }}</a>
      </div>
    </div>

    <div fxFlex.xs="0px" fxFlex.gt-xs="30px"></div>
    <div fxFlex.xs="50%" fxFlex.gt-xs="calc(100%-190px)" fxLayout="column" fxLayoutAlign="start start">
      <h2>
        {{ loggedUser.name }}
      </h2>
      {{ ('user_type_names.'+ loggedUser.user_type) | translate }}
    </div>
  </div>
  
  <div fxLayout="column" fxFlexAlign="start start" class="mt-2">
    <span>{{ 'login.mfa_label'|translate }}
      <button mat-icon-button type="button" [flexDialogInfo]="{title: 'dialogs.information'|translate, message: 'login.mfa_hint'|translate}">
        <mat-icon>info</mat-icon>
      </button>
    </span>
    
    <mat-slide-toggle color="primary" [(ngModel)]="mfaEnabled" (click)="onChangeMfaEnabled($event)">
      {{'user.content.mfa_enabled'|translate}}:
      <span [ngClass]="mfaEnabled ? 'mat-text-primary' : 'mat-text-muted'">{{
        (mfaEnabled ? 'client.content.enable' : 'sipphone.content.disabled')
        | translate }}</span>
    </mat-slide-toggle>
    
  </div>
</div>
