import { Directive, HostListener, Input } from '@angular/core';
import { DialogService } from '@wephone-utils/services';

@Directive({
  selector: '[flexDialogInfo]'
})
export class FlexDialogInfoComponent {
  @Input() flexDialogInfo: {
    title: string;
    message: string;
  };

  @HostListener('click') onClick(): void {
    this.showDialog();
  }

  constructor(
    private dialogService: DialogService
  ) { }

  showDialog(): void {
    console.log(this.flexDialogInfo);

    this.dialogService.showAlert(this.flexDialogInfo.title, this.flexDialogInfo.message);
  }

}
