import { Injectable } from '@angular/core';
// import { parseDateTime } from '@wephone-utils';

@Injectable()
export class ValidatorService {
  static VALID_PHONE_NUMBER_PATTERN = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.0-9]*$/; // /^(?:(?:(?:00|\+)([1-4]\d\d|[1-9]\d?))?(\d+))?$/i;
  static VALID_EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  static VALID_IP_PATTERN = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  static VALID_DOMAIN_PATTERN = /^[a-zA-Z0-9]{3,30}$/;
  static VALID_URL_PATTERN = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  static VALID_MAC_ADDRESS_PATTERN = /^[0-9A-Fa-f]{1,}([\.:])[0-9A-Fa-f]{1,}(?:\1[0-9A-Fa-f]{1,})*$/;
  static VALID_SMS_SENDER_NAME_PATTERN = /^[a-zA-Z0-9 ]+$/;

  isPhoneNumber(phone_number: string): boolean {
    return ValidatorService.VALID_PHONE_NUMBER_PATTERN.test(phone_number);
  }

  /**
   * 
   * @param email: validated email, multiple emails are separated by given character
   * @param multipleSeparated: input if validate multiple email separated by given value
   */
  isValidEmail(email: string, multipleSeparated: string = null): boolean {
    if (!email) {
      return false;
    }
    let emails: string[] = [];
    if (multipleSeparated) {
      emails = email.split(multipleSeparated).map(x => x.trim());
    }

    for (const e of emails) {
      if (!ValidatorService.VALID_EMAIL_PATTERN.test(e)) {
        return false;
      }
    }
    return true;
  }

  isUrl(url: string): boolean {
    return ValidatorService.VALID_URL_PATTERN.test(url);
  }
}
