<form [formGroup]="formGenerateVoice" fxLayout="column" *ngIf="is_ready && formGenerateVoice">
  <div fxLayout="row">
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ 'sound_manager.content.lang' | translate }}</mat-label>
        <mat-select
          data-ci="lang_search"
          formControlName="lang"
          (selectionChange)="changeText2speechLang()"
          [placeholder]="'sound_manager.content.lang' | translate"
        >
          <ngx-mat-select-search
            [(ngModel)]="langSearch"
            [ngModelOptions]="{ standalone: true }"
            [placeholderLabel]="'public.search' | translate"
            [noEntriesFoundLabel]="'public.message.no_results' | translate"
          >
          </ngx-mat-select-search>
          <mat-option *ngFor="let text_lang of langList" [value]="text_lang.value">
            {{ text_lang.label }}
          </mat-option>
        </mat-select>

        <mat-error [flexFormControlError]="formGenerateVoice.get('lang')"></mat-error>
      </mat-form-field>
    </div>
    <div class="pl-1"></div>
    <div fxFlex>
      <mat-form-field class="full-width">
        <mat-label>{{ 'public.voice' | translate }}</mat-label>
        <mat-select
          data-ci="voice_lang"
          formControlName="voice"
          (selectionChange)="clearTTSSound()"
          [placeholder]="'public.voice' | translate"
        >
          <ng-container *ngIf="formGenerateVoice.get('lang').value">
            <mat-option
              *ngFor="let voice_lang of text2speech_lang_voices[formGenerateVoice.get('lang').value]"
              [value]="voice_lang.value"
              >{{ voice_lang.label }}
            </mat-option>
          </ng-container>
        </mat-select>

        <mat-error [flexFormControlError]="formGenerateVoice.get('voice')"></mat-error>
      </mat-form-field>
    </div>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>{{ 'sound_manager.content.text' | translate }}</mat-label>
      <textarea
        data-ci="tts-text"
        matInput
        rows="5"
        formControlName="text"
        [placeholder]="'sound_manager.content.text' | translate"
      ></textarea>

      <mat-error [flexFormControlError]="formGenerateVoice.get('text')"></mat-error>
    </mat-form-field>
  </div>

  <div class="text-right mt-1">
    <button
      data-ci="generate"
      type="button"
      mat-stroked-button
      color="accent"
      (click)="recordByText2speech()"
      [disabled]="!formGenerateVoice.get('text').value || !formGenerateVoice.get('text').value.trim()"
    >
      {{ 'fileentry.action.generate' | translate }}
    </button>
  </div>
</form>

<!--text-to-speech-row-->

<mat-card *ngIf="newFileEntry" class="card-widge sound-file mt-1">
  <div mat-card-widget>
    <span fxFlex>{{ 'sound_manager.content.generated_sound'|translate }}</span>
    <div fxFlex="50" class="pl-1 pr-1">
      <p>
        <fileentry-player-button [filePublicId]="newFileEntry?.public_id" playerId="main"> </fileentry-player-button>
      </p>
    </div>
    <button fxFlex="40px" mat-icon-button class="mat-text-muted" (click)="clearTTSSound()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-card>
<!--text-to-speech-sound-->
