import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DialogService,
  DynamicFilterSource,
  IFlexTableConfig,
  IFlexTableSidePannelOptions,
  FlexCRUDPageComponent,
  regexSearch,
} from '@wephone-utils';
import { EntityManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChangeSoundModal } from '@wephone-audio';
import { FlexBasePage } from '@wephone-core-ui';
import { ReusableSoundEditComponent } from '@wephone/components/reusable-sound/reusable-sound-edit/reusable-sound-edit.component';
import { _tk, _ti } from '@wephone-translation';
import * as _ from 'lodash';
import { ReusableSoundService } from '@wephone/services/reusable-sound.service';

@Component({
  selector: 'reusable-sound',
  templateUrl: './reusable-sound.component.html',
  styleUrls: ['./reusable-sound.component.scss']
})
export class ReusableSoundComponent extends FlexBasePage implements OnInit {
  @ViewChild('flexCrud') flexCrud: FlexCRUDPageComponent;

  dataSource: DynamicFilterSource;
  tableConfig: IFlexTableConfig;
  sidePannelOptions: IFlexTableSidePannelOptions = {
    singleItemEditor: ReusableSoundEditComponent
  };

  filterString: string;
  form: FormGroup;

  private readonly _fileEntryRepo: FileEntryRepository;

  constructor(
    private readonly dialogService: DialogService,
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly reusableSoundService: ReusableSoundService,
  ) {
    super();
    this._fileEntryRepo = this.em.getRepository<FileEntryRepository>('FileEntryRepository');
    const fileEntrySource = this._fileEntryRepo.dataSource<FileEntryEntity>();
    this.dataSource = new DynamicFilterSource(fileEntrySource, this.filterFunc);

    this.tableConfig = {
      columns: [
        {
          name: 'name',
          label: _tk('fileentry.label.title'),
          enableTooltip: true,
          lineClamp: 2,
          sortable: true,
          sort: 'asc',
          customSortFn: (sound: FileEntryEntity) => {
            return sound.name.toLowerCase();
          }
        }
      ],
      listActions: {
        defaultActions: [
          {
            id: 'add',
            icon: 'add',
            callback: (): any => {
              this.createFileEntry();
            }
          }
        ],
        selectionActions: {
          primary: [
            {
              id: 'delete',
              icon: 'delete',
              callback: (data: any): any => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      }
    };

    this.form = this.fb.group({
      filterString: [this.filterString]
    });
    this.form.valueChanges.subscribe(this.onFormValueChage);
  }

  protected async resolveData(): Promise<any> {
    await EntityManager.getRepository('CrmRoutingRuleRepository').findAll();
    await EntityManager.getRepository('IvrEvaluationRepository').findAll();
  }

  private readonly filterFunc = (fileEntry: FileEntryEntity) => {
    return !!fileEntry.is_shared && fileEntry.is_temp === 0 && this.filterPredicate(fileEntry, this.filterString);
  }

  private filterPredicate(fileEntry: FileEntryEntity, filterString: string): boolean {
    return !filterString ? true : regexSearch(fileEntry.name, filterString);
  }

  private readonly onFormValueChage = (formValues: any) => {
    this.filterString = (formValues.filterString || '').trim();

    this.dataSource.filter = this.filterString;
    this.dataSource.onFilterChange();
  }

  bulkDelete(fileEntryList: FileEntryEntity[]): Promise<any> {
    return this.reusableSoundService.bulkDelete(fileEntryList);
  }

  createFileEntry(): void {
    this.openDialogChangeSound();
  }

  async openDialogChangeSound(fileEntry?: FileEntryEntity): Promise<void> {
    const data = {
      fileEntry,
      selectExisting: false,
      showInputName: true,
      markFileAsTemp: false
    };

    const dialogRef = this.dialogService.openDialog2(ChangeSoundModal, { data, size: 's' });

    const data_response = await dialogRef.afterClosed().toPromise();
    if (!data_response) {
      return;
    }
    const newFileEntry: any = data_response.file_entry;
    console.log('newFileEntry', newFileEntry);

    if (newFileEntry && newFileEntry.id && (!fileEntry || !fileEntry.id)) {
      this.successToast(_ti('public.message.create_success'));
      return;
    }

    if (!newFileEntry || !fileEntry ||
      !newFileEntry.id || !fileEntry.id ||
      newFileEntry.id === fileEntry.id
    ) {
      console.log('No entry was replaced');
      return;
    }
  }

  get uiEnterprise(): boolean {
    return FlexIvrSettings.getInstance().uiEnterprise();
  }

  // async deleteAll(fileEntryList: FileEntryEntity[]): Promise<void> {
  //   try {
  //     const public_ids: string[] = fileEntryList.map(x => x.public_id);
  //     const res = await this.fileEntryService.removeFileEntries(public_ids);
  //     if (res.data && res.data.error) {
  //       this.toastService.showError(res.data.error.message);
  //       return;
  //     }
  //     this.toastService.showSuccess(_ti('public.message.delete_success'));
  //   } catch (e) {
  //     this.toastService.showError(`fileentry.error.error_${e.code}`);
  //   }
  // }
}
