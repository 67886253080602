import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Optional,
  ElementRef,
  TemplateRef,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FlexMatInputWrapper } from '../base/flex-mat-input-wrapper';
import { _ti } from '@wephone-translation';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';

export interface IFlexSelectOptions {
  compareWith?: (a, b) => boolean;
  filterFunc: (item, filterString) => boolean;
  disabledOptionFunc?: (item) => boolean;
}

@Component({
  selector: 'flex-select',
  templateUrl: './flex-select.component.html',
  styleUrls: ['./flex-select.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FlexSelect }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexSelect extends FlexMatInputWrapper
  implements OnInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {
  static nextId = 0;

  @ViewChild('mySelect', { static: true }) mySelect;

  @Input()
  set itemList(itemList: any[]) {
    this._itemList = itemList;
    if (itemList) {
      // load the initial item list
      this.cdr.markForCheck();
      this.filteredItems.next(this._itemList.slice());
    }
  }

  get itemList(): any[] {
    return this._itemList;
  }

  @Input() multiple = false;
  @Input() options: IFlexSelectOptions;
  @Input() itemTemplate: TemplateRef<any>;
  @Input() selectTriggerTemplate: TemplateRef<any>;
  @Input() showCheckAll = true;

  @Output() readonly selectionChange = new EventEmitter<MatSelectChange>();

  matSelectControl: FormControl = new FormControl();
  filterFormControl: FormControl = new FormControl();
  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private _itemList: any[];

  defaultCompareFn = (a, b): boolean => a === b;
  compareFn = (a, b): boolean => {
    return this.options.compareWith ? this.options.compareWith(a, b) : this.defaultCompareFn(a, b);
  }
  disabledOptionFn = (item): boolean => {
    return this.options.disabledOptionFunc && this.options.disabledOptionFunc(item) || false;
  }

  constructor(
    @Optional() ngControl: NgControl,
    elRef: ElementRef,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, elRef, cdr);
  }

  get wrappedControl(): FormControl {
    return this.matSelectControl;
  }

  ngOnInit(): void {
    super.ngOnInit();

    // listen for search field value change
    this.filterFormControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.updateFilteredList();
    });

    if (!this.placeholder) {
      this.placeholder = _ti('placeholder.default.select');
    }
  }

  get controlType(): string {
    return 'flex-select-form-input';
  }

  removeItem(item: any): void {
    const value = this.matSelectControl.value.slice();
    value.splice(value.indexOf(item), 1);
    this.matSelectControl.setValue(value);
    this.cdr.detectChanges();
    this.updateFilteredList();
  }

  updateFilteredList(): void {
    if (!this.itemList) {
      return;
    }
    this.cdr.markForCheck();

    const search = this.filterFormControl.value;
    if (!search) {
      this.filteredItems.next(this.itemList.slice());
      return;
    }

    this.filteredItems.next(this.itemList.filter(item => this.options.filterFunc(item, search)));
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'mat-select') {
      this.mySelect.open();
    }
  }

  getFilteredValues(): any[] {
    let values = this.itemList;
    const search = this.filterFormControl.value;

    if (search) {
      values = this.itemList.filter(item => this.options.filterFunc(item, search));
    }

    return values || [];
  }

  get selectAllChecked(): boolean {
    return this.multiple && (this.matSelectControl.value || []).length === this.getFilteredValues().length;
  }

  get selectAllIndeterminate(): boolean {
    return this.multiple && !this.selectAllChecked && (this.matSelectControl.value || []).length ? true : false;
  }

  selectAll(e: MatCheckboxChange): void {
    if (this.multiple) {
      const values = e.checked === true ? this.getFilteredValues() : [];
      this.matSelectControl.setValue(values);
      this.cdr.markForCheck();
    }
  }

  onSelectionChange($event: MatSelectChange): void {
    this.selectionChange.emit($event);
  }
}
