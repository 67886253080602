import { Injectable } from '@angular/core';
import { SingletonBase } from '../utils/singleton';

@Injectable()
export class PhoneNumberService extends SingletonBase {
  // private static _instance: PhoneNumberService;
  private telecom_country_prefix: string;

  setTeleComCountryPrefix(telecom_country_prefix: string): void {
    this.telecom_country_prefix = telecom_country_prefix;
  }

  getDisplayNumber(phone_number: string): string {
    if (!phone_number) {
      return '';
    }

    const telecomCountryPrefix = this.telecom_country_prefix;
    let newPhoneNumber = phone_number;

    if (!telecomCountryPrefix) {
      return phone_number;
    }

    if (phone_number.charAt(0) === '+') {
      newPhoneNumber = phone_number.substr(1);
    }

    if (newPhoneNumber.substring(0, telecomCountryPrefix.length) === telecomCountryPrefix) {
      return `0${newPhoneNumber.substring(telecomCountryPrefix.length)}`;
    }

    return newPhoneNumber;
  }

  cleanupNumber(phone_number: string): string {
    let ret = phone_number.replace('%20', ''); // Remove url encoded space
    ret = ret.replace(/[^\*#+_0-9a-zA-Z]/g, '');
    return ret;

  }

  normalizeNumber(phone_number: string): string {
    if (!phone_number) {
      return '';
    }
    const ret = this.cleanupNumber(phone_number);
    if (ret.indexOf('+') === 0) {
      return ret.substr(1);
    }

    if (ret.indexOf('00') === 0) {
      return ret.substr(2);
    }

    if (ret.indexOf('0') === 0) {
      return this.telecom_country_prefix + ret.substr(1);
    }
    return ret;
  }
}
