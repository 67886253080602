<div fxLayout="column" fxLayoutAlign="start center">
  <div *ngIf="mfaQrCodeUrl" fxLayout="column">
    <span>{{ 'login.mfa_qr_code_hint'|translate }}</span>
    <div class="text-center full-width">
      <img class="img-qrcode" [src]="mfaQrCodeUrl" />
    </div>
  </div>

  <form [formGroup]="form" *ngIf="form" class="full-width">
    <mat-form-field class="full-width">
      <input
        id="mfa_code"
        matInput
        [placeholder]="'login.mfa_code' | translate"
        [formControl]="form.controls['mfa_code']"
        tabindex="1"
        focus="true"
      />

      <mat-hint>{{ 'login.mfa_code_hint'|translate }}</mat-hint>
      
      <mat-error
        *ngIf="form.controls['mfa_code'].invalid && form.controls['mfa_code'].touched"
        class="mat-text-warn ml-10"
        >{{ 'public.message.data_invalid' | translate }}</mat-error
      >
    </mat-form-field>
  </form>
</div>
