import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';

export interface GetContactOpts {
  crmId: number;
  contactId: string;
  contactType: string;
}

@Injectable()
export class RoutingServiceService {
  private readonly VERIFY_URL_URL = 'routing_service/verify-url';

  constructor(
    private readonly httpEngine: HttpEngine,
  ) {
  }

  verify(data: any): Promise<any> {
    return this.httpEngine.apiPostV2(this.VERIFY_URL_URL, data);
  }
}
