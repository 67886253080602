<div fxLayout="column" class="pa-1">
    <div *ngIf="item.analysis_state === 2;else DisplayAnalysisState">
        <strong>{{ 'call_queue.content.analysis.title'|translate }}</strong>
        <table class="flex-table-info full-width" *ngIf="analysisResult">
            <tr>
                <th>{{ 'public.key_label'|translate }}</th>
                <th>{{ 'public.value'|translate }}</th>
            </tr>

            <tr *ngFor="let key of getKeyAnalysis()">
                <td>{{ ('audioinsight.' + key)|translate }}</td>
                <td>{{ analysisResult.json_result[key] }}</td>
            </tr>

            <tr *ngFor="let key of getKeyAnalysisUuid()">
                <td>{{ ('audioinsight.' + key)|translate }}</td>
                <td>{{ analysisResult[key] }}</td>
            </tr>
        </table>
    </div>
    <ng-template #DisplayAnalysisState>
        <strong>{{ 'recording_call.content.analysis_state'|translate }}</strong>
        <p class="mt-0">{{ ('recording_call.transcription_analysis_state.state_' + item.analysis_state)|translate }}</p>
    </ng-template>

    <div class="mt-1" *ngIf="item.transcription_state === 2;else DisplayTranscriptionState">
        <strong>{{ 'call_queue.content.transcription'|translate }}</strong>
        <p class="mt-0 transcription-text" [innerHTML]="transcriptionText"></p>
    </div>
    <ng-template #DisplayTranscriptionState>
        <strong>{{ 'recording_call.content.transcription_state'|translate }}</strong>
        <p class="mt-0">{{ ('recording_call.transcription_analysis_state.state_' + item.transcription_state)|translate }}</p>
    </ng-template>
</div>
