import { Component, isDevMode, ChangeDetectorRef, ViewChild, OnInit } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { UserGroupEntity } from '@wephone-core/model/entity/usergroup';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DidRepository } from '@wephone-core/model/repository/did';
import { UserGroupRepository } from '@wephone-core/model/repository/usergroup';
import { takeUntil } from 'rxjs/operators';
import { QualificationRepository } from '@wephone-core/model/repository/qualification';
import { QualificationEntity } from '@wephone-core/model/entity/qualification';
import * as _ from 'lodash';
import {
  MessageService,
  DialogService,
  ToastService,
  IFlexTableConfig,
  localNow,
  parseDateTime,
  PhoneNumberService,
  IFlexSelectOptions,
  regexSearch,
  IDataSourceFilter,
  FlexTableComponent,
} from '@wephone-utils';
import { GridOptions } from 'ag-grid-community';
// import { TranslateService } from '@ngx-translate/core';
import { FlexDataSource } from 'src/app/wephone-utils/datasource/datasource';
import { DataSourceService } from '@wephone-core/service/datasource.service';
import { FileEntryService } from '@wephone-core/service/file_entry_service';
import { RecordingService } from '@wephone-core/service/recording_service';
import { EntityManager, ConfigManager, FlexIvrSettings } from '@wephone-core/wephone-core.module';
import { FlexBasePage } from '@wephone-core-ui';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { _tk, _ti } from '@wephone-translation';
import { DateAdapter } from '@angular/material/core';
import { FlexDatepickerMin } from '@wephone/services/form-validator';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { SystemParam, UserRole } from '@wephone-core/system';
import { MyUserProfile } from '@wephone-core/service/config_manager.i';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';
import { ICallQualify, IQualifyResult, QualificationService } from '@wephone-common';
import { ICallInfoDataChanged, ICallLog } from '@wephone-app-phone/pages/history/calllog.i';
import { EnterpriseCrmEntity } from '@wephone-core/model/entity/enterprise_crm';
import { CrmContactService } from '@wephone-core/service/crm-contact.service';
import { CallInfoModalComponent } from '@wephone-common/modals/call-info-modal/call-info-modal.component';
import { IRecordingCall, RCAnalysisState, RCTranscriptionState, TranscriptionStatus } from './recording-call.i';
import { RecordingCallAnalysisResultComponent } from '@wephone/modals/recording-call-analysis-result/recording-call-analysis-result.component';

@Component({
  selector: 'app-recording-call',
  templateUrl: './recording-call.component.html',
  styleUrls: ['./recording-call.component.scss']
})
export class RecordingCallComponent extends FlexBasePage implements OnInit {
  @ViewChild('flexTable', { static: false }) flexTable: FlexTableComponent;

  private readonly didRepo = DidRepository.getInstance<DidRepository>();
  private readonly groupRepo = UserGroupRepository.getInstance<UserGroupRepository>();
  private readonly quanlifiRepo = QualificationRepository.getInstance<QualificationRepository>();
  private readonly _onDestroy = new Subject<void>();

  gridOptions: GridOptions;
  didList = [];
  groups = [];
  quanlifi = [];
  isInDevMode: boolean;
  filteredGroupsMulti: ReplaySubject<UserGroupEntity[]> = new ReplaySubject<UserGroupEntity[]>(1);
  filteredDidsMulti: ReplaySubject<DidEntity[]> = new ReplaySubject<DidEntity[]>(1);
  filteredQuanlifisMulti: ReplaySubject<QualificationEntity[]> = new ReplaySubject<QualificationEntity[]>(1);
  groupMultiFilterCtrl: FormControl = new FormControl();
  didMultiFilterCtrl: FormControl = new FormControl();
  qualifiMultiFilterCtrl: FormControl = new FormControl();

  filterDidsSelectOptions: IFlexSelectOptions;

  tableConfig: IFlexTableConfig;

  dataSource: FlexDataSource;
  filterForm: FormGroup;
  filtersReadonly: boolean;
  hasTranscription = false;
  callAnalysisEnabled = false;
  hasAnonymous: boolean;
  didConfiguredList: DidEntity[];

  filteredUserIds: number[];
  filteredQueueIds: number[];
  filteredGroupIds: number[];
  filteredPublicFileId: string;

  userRole: UserRole;
  activeCRM: EnterpriseCrmEntity;
  crmConfig: any;

  private readonly _crmContactUrls: any = {};

  constructor(
    cdr: ChangeDetectorRef,
    dateAdapter: DateAdapter<Date>,
    public messageService: MessageService,
    public dialogService: DialogService,
    public toast: ToastService,
    // private readonly translate: TranslateService,
    private readonly datasourceService: DataSourceService,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly fileEntryService: FileEntryService,
    private readonly recordingService: RecordingService,
    private readonly authService: AuthenticationService,
    private readonly fb: FormBuilder,
    private readonly configManager: ConfigManager,
    private readonly clipboard: Clipboard,
    private readonly activeRoute: ActivatedRoute,
    private readonly qualService: QualificationService,
    private readonly crmContactService: CrmContactService,
    // private readonly settings: FlexIvrSettings,
  ) {
    super(cdr);
    this.dateAdapter = dateAdapter;
    this.userRole = authService.getUserRole();

    this.filterForm = this.fb.group({
      start_dt: [localNow().toJSDate(), Validators.required],
      end_dt: [localNow().toJSDate(), Validators.required],
      start_time: '00:00',
      end_time: '00:00',
      start_min: 0,
      end_min: 60,
      agents: [[]],
      queues: [[]],
      campaigns: [[]],
      qualifications: '',
      filter_duration: false,
      numbers: [[]], // local_numbers
      remote_number: ['', Validators.pattern(/^([0-9+]\d*)?$/)],
      audio_state: '',
      groups: [[]],
    }, { validator: FlexDatepickerMin('end_dt', 'start_dt') });

    this.dataSource = this.datasourceService.createDataSource('pbx_recording_queue');
    this.dataSource.setOrder('recorded_dt', 'desc');
    this.dataSource.onLoadItem(
      (item: IRecordingCall) => {
        const callLog: ICallLog = this.getCallLog(item);
        item.call_log = callLog;
      }
    );

    this.isInDevMode = isDevMode();
    this.didList = this.didRepo.getObjectList();

    this.filterDidsSelectOptions = {
      compareWith: (a: DidEntity, b: DidEntity) => (a && b ? a.id === b.id : a === b),
      filterFunc: (item: DidEntity, filterString): boolean => {
        return regexSearch(item.number_with_name, filterString);
      }
    };

    this.groups = this.groupRepo.getObjectList();
    this.quanlifi = this.quanlifiRepo.getObjectList();

    const canTakeAction: boolean = this.authService.isAdmin() || this.authService.isSupervisor();
    this.tableConfig = {
      multiSelect: canTakeAction,
      enableExportCsv: canTakeAction,
      columns: [
        { name: 'recorded_dt', label: _tk('recording_call.recorded_date'), width: '90px' },
        { name: 'queue_name', label: _tk('call_queue.content.name'), enableTooltip: true, lineClamp: 2 },
        { name: 'local_number', label: _tk('call_queue.content.didroute.did_number') },
        { name: 'duration', label: _tk('recording_call.length'), width: '70px' },
        { name: 'remote_number', label: _tk('recording_call.remote_number'), renderCell: this.renderPhoneNumber, enableTooltip: true, lineClamp: 2 },
        { name: 'direction', label: _tk('recording_call.direction'), width: '25px' },
        { name: 'qualification', label: _tk('recording_call.table_filter.qualification'), enableTooltip: true, lineClamp: 2 },
        { name: 'user_ids', label: _tk('voicemail.label_operator') },
        { name: 'action', label: _tk('public.action'), width: '350px' }
      ]
    };

    if (canTakeAction) {
      this.tableConfig.listActions = {
        selectionActions: {
          primary: [
            // Visible action buttons when some item is selected
            {
              id: 'delete',
              icon: 'delete',
              callback: data => {
                return this.bulkDelete(data.selectedItems);
              }
            }
          ]
        }
      };
    }

    const enterpriseParams = this.configManager.getEnterpriseParams();
    const myEnterprise = EnterpriseRepository.getInstance<EnterpriseRepository>().getMyEnterprise();
    this.hasTranscription = myEnterprise && enterpriseParams.speech2text && ['FR', 'US', 'GB'].includes(myEnterprise.country) || false;
    this.callAnalysisEnabled = !!enterpriseParams.call_analysis_enable || false;
    this.hasAnonymous = !!ConfigManager.getInstance().getSystemParam(SystemParam.telecom_has_anonymous);
  }

  ngOnInit(): void {
    this.addSubscription(
      this.activeRoute.params.subscribe(
        params => {
          this.filteredPublicFileId = params['file_public_id'];
        }
      )
    );

    super.ngOnInit();

  }

  get startDateControl(): FormControl {
    return this.filterForm && this.filterForm.get('start_dt') as FormControl;
  }

  get endDateControl(): FormControl {
    return this.filterForm && this.filterForm.get('end_dt') as FormControl;
  }

  get groupsControl(): FormControl {
    return this.filterForm && this.filterForm.get('groups') as FormControl;
  }

  /******************
  * Cannot bind input [min] because there's a bug with Mat-Datepicker,
  * the Form error won't fire if the binding object's value was changed.
  ******************/
  minEndDateFilter = (d: Date): boolean => {
    const startDate = this.startDateControl.value;
    if (!d || !startDate) {
      return true;
    }
    const minDate = parseDateTime(startDate).startOf('day');
    const dt = parseDateTime(d).startOf('day');
    return minDate.diff(dt).as('days') > 0 ? false : true;
  }

  async resolveData(): Promise<void> {
    this.didConfiguredList = this.didRepo.getObjectList().filter((d: DidEntity) => d.hasRoutingData());
    this.filteredGroupsMulti.next(this.groups);
    this.filteredDidsMulti.next(this.didList);
    this.filteredQuanlifisMulti.next(this.quanlifi);
    this.groupMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterGroupsMulti();
    });

    this.didMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterDidMulti();
    });

    this.qualifiMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterQuanlifiMulti();
    });

    if (!this.authService.isAdmin()) {
      const myProfile: MyUserProfile = await this.configManager.getMyprofile();
      this.filteredUserIds = myProfile.managed_user_ids;
      this.filteredQueueIds = myProfile.managed_queue_ids;
      this.filteredGroupIds = myProfile.managed_group_ids;
    }

    if (!this.activeCRM) {
      this.activeCRM = await this.crmContactService.getActiveCRM(false);
      this.crmConfig = this.crmContactService.getConfigCRM(this.activeCRM);
      if (this.activeCRM) {
        this._crmContactUrls[this.activeCRM.type] = {};
      }
    }

    this.reloadClick();
  }

  detailHandledCallInfo(call: ICallLog): void {
    this.dialogService.openDialog2(
      CallInfoModalComponent,
      {
        data: {
          cdr_id: call.id
        },
        padding: false
      },
      (resp?: ICallInfoDataChanged) => {
        if (!resp) {
          return;
        }

        if (resp.hasOwnProperty('qualification')) {
          call.qualification_ids = resp.qualification;
        }

        if (resp.hasOwnProperty('qualification_comment')) {
          call.qualification_comment = resp.qualification_comment;
        }

        if (resp.hasOwnProperty('qualification_displayed')) {
          call.qualification = resp.qualification_displayed;
        }

        if (resp.hasOwnProperty('vm_message_is_read')) {
          call.is_read = 1;
        }

        if (resp.hasOwnProperty('vm_message_readers')) {
          call.readers = resp.vm_message_readers;
        }

        this.flexTable.detectChanges();
      }
    );
  }

  async openContactProfile(callLog: ICallLog): Promise<void> {
    const callNumber = callLog.is_outgoing ? callLog.called_number : callLog.calling_number;
    const url = await this.getCrmContactUrl(callNumber);
    if (url) {
      window.open(url, '_blank');
    }
  }

  async getCrmContactUrl(phoneNumber: string): Promise<string> {
    if (!this.activeCRM) {
      console.error('No enterprise crm active');
      return undefined;
    }
    let crmContactUrl = this._crmContactUrls[this.activeCRM.type][phoneNumber];
    if (!crmContactUrl) {
      try {
        const contactData = await this.crmContactService.getCrmContactByNumber(this.activeCRM.id, phoneNumber);
        crmContactUrl = contactData.profileUrl;
        this._crmContactUrls[this.activeCRM.type][phoneNumber] = crmContactUrl;
      } catch (e) {
        this.showErrorMessage(e, _ti('crm_contact.message.not_found'));
      }
    }
    return crmContactUrl;
  }

  async reloadClick(): Promise<void> {
    if (!this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }

    const newFilters: IDataSourceFilter = this.getFilters();

    try {
      if (this.flexTable) {
        // this.dataSource.setFilter(newFilters);
        this.flexTable.reloadDataSource(newFilters);
      } else {
        await this.dataSource.load(newFilters);
      }

      this.filtersReadonly = true;
    } catch (e) {
      this.showError(_ti('public.message.error_occurred'));
    }
  }

  private filterGroupsMulti(): void {
    if (!this.groups) {
      return;
    }

    let search = this.groupMultiFilterCtrl.value;
    if (!search) {
      this.filteredGroupsMulti.next(this.groups);

      return;
    }

    search = search.toLowerCase();

    this.filteredGroupsMulti.next(this.groups.filter(group => group.name.toLowerCase().indexOf(search) > -1));
  }

  private filterDidMulti(): void {
    if (!this.didList) {
      return;
    }

    let search = this.didMultiFilterCtrl.value;
    if (!search) {
      this.filteredDidsMulti.next(this.didList);

      return;
    }

    search = search.toLowerCase();

    this.filteredDidsMulti.next(this.didList.filter(did => did.display_number.toLowerCase().indexOf(search) > -1));
  }

  private filterQuanlifiMulti(): void {
    if (!this.quanlifi) {
      return;
    }

    let search = this.qualifiMultiFilterCtrl.value;
    if (!search) {
      this.filteredQuanlifisMulti.next(this.quanlifi);

      return;
    }

    search = search.toLowerCase();

    this.filteredQuanlifisMulti.next(this.quanlifi.filter(quan => quan.value.toLowerCase().indexOf(search) > -1));
  }

  private getFilters(): IDataSourceFilter {
    // const newFilters = _.cloneDeep(this.filterForm.value);
    let newFilters: any = {};
    newFilters.file_entry_public_id = this.filteredPublicFileId;

    if (!this.filteredPublicFileId) {
      newFilters = _.cloneDeep(this.filterForm.value);
      newFilters.start_dt = parseDateTime(newFilters.start_dt).toISODate();
      newFilters.end_dt = parseDateTime(newFilters.end_dt).toISODate();

      // format local_numbers before apply filters
      newFilters.local_numbers = this.didRepo.getObjectListByIds(newFilters.numbers).map(d => d.display_number);

      if (_.includes(newFilters.numbers, 0)) {
        const anonymousDid = this.didRepo.getAnonymousDid();
        newFilters.local_numbers.unshift(anonymousDid.display_number.toLocaleLowerCase());
      }

      delete newFilters.numbers;

      // if (this.dataSource.sort) {
      //   newFilters.order = [{
      //     column: this.dataSource.sort.active,
      //     dir: this.dataSource.sort.direction
      //   }];
      // }
    }

    return newFilters;
  }

  canQualify = (item: ICallLog): boolean => {
    if (this.userRole === UserRole.WATCHER) {
      return false;
    }

    if (!item.has_qualification) {
      return false;
    }

    if (this.userRole !== UserRole.ADMIN &&
      (
        this.filteredUserIds &&
        !_.intersection(this.filteredUserIds, item.handled_user_ids).length
      )
    ) {
      return false;
    }

    return item.is_outgoing && !!item.out_qualification_id || !item.is_outgoing && !!item.in_qualification_id;
  }

  private getCallLog(item: IRecordingCall): ICallLog {
    const outgoing: boolean = item.direction == 0;
    const call: ICallLog = {
      duration: item.duration,
      id: item.cdr_id,
      called_number: outgoing ? item.remote_number : item.local_number,
      calling_number: !outgoing ? item.remote_number : item.local_number,
      user_direction: item.direction,
      is_outgoing: outgoing ? 1 : 0,
      has_qualification: item.has_qualification,
      handled_user_ids: item.handled_user_ids,
      out_qualification_id: item.out_qualification_id,
      in_qualification_id: item.in_qualification_id,
      start_time: new Date(item.recorded_dt),
      qualification_ids: item.qualification_ids,
      qualification: item.qualification,
      qualification_comment: item.qualification_comment,
      queue_ids: item.queue_ids,
    };

    return call;
  }

  async reQualify(item: IRecordingCall): Promise<void> {
    const call: ICallLog = item.call_log;
    const callQualify: ICallQualify = {
      id: call.id,
      qualification_comment: call.qualification_comment,
      out_qualification_id: call.out_qualification_id,
      in_qualification_id: call.in_qualification_id,
      queue_ids: call.queue_ids,
      is_outgoing: call.is_outgoing,
      qualification_ids: call.qualification_ids,
      // qualification: call.qualification,
    };

    const result: IQualifyResult = await this.qualService.reQualify(callQualify);
    if (result) {
      _.extend(call, {
        qualification_ids: result.qualification_ids,
        qualification_comment: result.qualification_comment,
        qualification: result.qualification,
      });

      _.extend(item, {
        qualification: result.qualification,
        qualification_ids: result.qualification_ids,
        qualification_comment: result.qualification_comment
      });
    }

    this.flexTable.detectChanges();
  }

  displayPhoneNumber(phoneNumber: string): string {
    return this.phoneNumberService.getDisplayNumber(phoneNumber);
  }

  downloadRecoredCall(item: IRecordingCall): void {
    const recordedDateTime = parseDateTime(item.recorded_dt).toFormat('yyyyMMdd-HHmm');
    const fileName = `recording_call-${recordedDateTime}-${item.file_public_id}.mp3`;
    const download_url = this.fileEntryService.getFileUrl(item.file_public_id, true);
    this.fileEntryService.download(download_url, fileName);
  }

  copyRecoredCallUrl(item: IRecordingCall): void {
    const urlPattern: string = this.configManager.getSystemParam(SystemParam.recorded_call_url);
    // https://admin.graam.com/v3/juritravail/vocal_messages/recorded-call/{{public_id}}
    const url: string = urlPattern.replace('{{public_id}}', item.file_public_id);
    this.clipboard.copy(url);
    this.toast.showSuccess(_ti('call.message.share_url_success'));
  }

  async downloadTranscription(item: IRecordingCall): Promise<void> {
    try {
      const res = await this.recordingService.getTranscription(item.id);
      if (res.status === TranscriptionStatus.IN_PROGRESS) {
        item.transcription_state = 1;
        this.showInfo(_ti('recording_call.transcription.in_progress'));
        return;
      }

      if (res.status === TranscriptionStatus.FAILED) {
        this.showError(_ti('recording_call.transcription.failed'));
        return;
      }

      if (res.status === TranscriptionStatus.COMPLETED) {
        if (item.transcription_state !== 2) {
          item.transcription_state = 2;
        }

        const blob = new Blob([res.transcription], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = `${item.id}.txt`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        return;
      }
    } catch (error) {
      console.error('Download Transcription Error: ', error);
      this.showErrorMessage(error, _ti('public.message.download_failure'));
    }
  }

  async setEnableTranscriptionAnalysis(item: IRecordingCall): Promise<void> {
    try {
      await this.recordingService.setEnableAnalysis(item.id);
      item.analysis_enabled = 1;
      this.showInfo(_ti('recording_call.message.enable_analysis_success'));
    } catch (error) {
      console.error('Set Enable Transcription Analysis Error: ', error);
      this.showErrorMessage(error, _ti('recording_call.message.enable_analysis_failure'));
    }
  }

  transcriptionDone(item: IRecordingCall): boolean {
    return item.transcription_state === 2;
  }

  analysisDone(item: IRecordingCall): boolean {
    return item.analysis_state === 2;
  }

  transcriptionInProgress(item: IRecordingCall): boolean {
    return item.transcription_state === 1;
  }

  analysisInProgress(item: IRecordingCall): boolean {
    return item.analysis_state === 1;
  }

  transcriptionFailed(item: IRecordingCall): boolean {
    return item.transcription_state === 3;
  }

  analysisFailed(item: IRecordingCall): boolean {
    return item.analysis_state === 3;
  }

  doNothing(item: IRecordingCall): void {
    console.log('Do Nothing: ', item);
  }

  showTranscriptionResult(item: IRecordingCall): void {
    // if (item.transcription_state !== RCTranscriptionState.TranscriptionDone &&
    //   item.analysis_state !== RCAnalysisState.AnalysisDone) {
    //   console.error('No transcription result', item);
    //   return;
    // }

    this.dialogService.openDialog2(
      RecordingCallAnalysisResultComponent,
      {
        data: {
          item,
        },
        padding: false
      },
      (data: IRecordingCall) => {
        if (data) {
          _.extend(item, data);
        }
      }
    );
  }

  deleteRecoredCall(item: IRecordingCall): void {
    this.bulkDelete([item]);
    // this.dialogService.confirmDialog(
    //   _ti('dialogs.confirmation'),
    //   _ti('user.title.delete'),
    //   () => {
    //     this.bulkDelete([item]);
    //   }
    // );
  }

  bulkDelete(items: IRecordingCall[]): Promise<any> {
    return this.dialogService.confirmDialog(
      _ti('dialogs.confirmation'),
      _ti('user.title.delete'),
      async () => {
        const itemIds: number[] = items.map(i => i.id);

        try {
          await this.recordingService.deleteRecordCalls(itemIds);

          for (const item of items) {
            this.dataSource.deleteRow(item);
          }
          this.toast.showSuccess(_ti('public.message.delete_success'));
        } catch (errData) {
          console.error('Delete Voicemail Error: ', errData);
          this.toast.showErrorMessage(errData, _ti('public.message.delete_failure'));
        }
      }
    );
  }

  // resetLocalNumberFilters(): void {
  //   this.filterForm.value.local_numbers = [];
  // }

  // resetGroupsFilters(): void {
  //   this.filterForm.value.groups = [];
  // }

  renderPhoneNumber = (row: IRecordingCall, colName?: string): string => {
    let value: string = this.displayPhoneNumber(row[colName]);
    if (row.client_name) {
      value = `${value} (${row.client_name})`;
    }
    return value;
  }

  rendererQualifi(qualifiIds: number[]): string[] {
    const qualifiList = EntityManager.getRepository('QualificationRepository').getObjectList();
    return qualifiList.filter(i => qualifiIds.includes(i.id)).map(q => q.value);
  }

  changeFilter(): Boolean {
    return this.filtersReadonly = false;
  }

  rendererGroup(groupIds: number[]): any[] {
    const groupName = [];
    const groupList = EntityManager.getRepository('UserGroupRepository').getObjectList();
    for (let i = 0; i < groupList.length; i++) {
      for (let q = 0; q < groupIds.length; q++) {
        if (groupList[i].id === groupIds[q]) {
          groupName.push(groupList[i].name);
        }
      }
    }

    return groupName;
  }

  rendererUser(userIds: number[]): any[] {
    const userName = [];
    const userList = EntityManager.getRepository('UserRepository').getObjectList();
    for (let i = 0; i < userList.length; i++) {
      for (let q = 0; q < userIds.length; q++) {
        if (userList[i].id === userIds[q]) {
          userName.push(userList[i].name);
        }
      }
    }

    return userName;
  }

  rendererNumber(ids: number[]): string[] {
    const displayNumbers: string[] = [];
    for (const id of ids) {
      let d: DidEntity = this.didRepo.getAnonymousDid();

      if (id) {
        d = this.didRepo.getObjectById(id);
      }

      displayNumbers.push(d.display_number);
    }
    return displayNumbers;
    // return this.didRepo.getObjectListByIds(ids).map(d => d.display_number);
  }

  rendererQueue(queueIds: number[]): any[] {
    const queueName = [];
    const queueList = EntityManager.getRepository('CallQueueRepository').getObjectList();
    for (let i = 0; i < queueList.length; i++) {
      for (let q = 0; q < queueIds.length; q++) {
        if (queueList[i].id === queueIds[q]) {
          queueName.push(queueList[i].queue_name);
        }
      }
    }

    return queueName;
  }

  rendererCampaign(campaignIds: number[]): any[] {
    const campaignName = [];
    const campaignList = EntityManager.getRepository('OutcallCampaignRepository').getObjectList();
    for (const campaign of campaignList) {
      for (const id of campaignIds) {
        if (campaign.id === id) {
          campaignName.push(campaign.campaign_name);
        }
      }
    }

    return campaignName;
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  changePeriod(periodSelectData: { period: string, start_dt: Date, end_dt: Date }): void {
    if (periodSelectData.start_dt) {
      this.filterForm.get('start_dt').setValue(periodSelectData.start_dt);
    }
    if (periodSelectData.end_dt) {
      this.filterForm.get('end_dt').setValue(periodSelectData.end_dt);
    }

    this.reloadClick();
  }

  clearValue(event: MouseEvent, controlName: string, multiple: boolean = false): void {
    event.stopPropagation();
    this.filterForm.get(controlName).setValue(multiple ? [] : null);
  }
}
