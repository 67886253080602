import * as _ from 'lodash';
import { _tk, _ti } from '@wephone-translation';

import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef, ViewChild, OnChanges } from '@angular/core';
import { DidEntity } from '@wephone-core/model/entity/did';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { EditingComponent, DialogService, ToastService } from '@wephone-utils';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { EntityManager } from '@wephone-core/wephone-core.module';
import { IRoutingAppDestinationData, RoutingAppName } from '@wephone-core/routing-app/routing-app.interface';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { CalendarSelectListDialogComponent } from '../calendar';
import { OpeningHourCalendarEntity } from '@wephone-core/model/entity/openinghour_calendar';
import { VoicemailOptionModal, VoicemailOptionInterface } from '@wephone/modals/call-queue/voicemail-option-modal/voicemail-option-modal.component';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { CallDestinationComponent } from '../call-destination/call-destination.component';

@Component({
  selector: 'number-opening-calendar',
  templateUrl: './number-opening-calendar.component.html',
  styleUrls: ['./number-opening-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NumberOpeningCalendar extends EditingComponent implements OnInit, OnChanges {

  @Input() did?: DidEntity;
  @Input() queue?: CallQueueEntity;
  @Input() hideClosedConfig?: boolean;
  @ViewChild('callDestination') callDestination: CallDestinationComponent;
  @ViewChild('agendaClosedConfigDestination') agendaClosedConfigDestination: CallDestinationComponent;
  actionNotOfficeHour: IRoutingAppDestinationData;
  queueRepo: CallQueueRepository;
  hasInitialized: boolean;

  destinationList: RoutingAppName[] = [
    RoutingAppName.ivr_custom_menu,
    RoutingAppName.callqueue,
    RoutingAppName.to_did,
    RoutingAppName.call_phone,
    RoutingAppName.call_phone_number,
    RoutingAppName.play_then_hangup,
    RoutingAppName.send_sms
  ];
  agendaClosedConfigDestinationList: RoutingAppName[] = [
    RoutingAppName.callqueue,
    RoutingAppName.call_phone,
    RoutingAppName.call_phone_number,
    RoutingAppName.ivr_custom_menu,
    RoutingAppName.play_then_hangup,
    RoutingAppName.send_sms
  ];

  constructor(
    private readonly em: EntityManager,
    private readonly fb: FormBuilder,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.queueRepo = this.em.getRepository<CallQueueRepository>('CallQueueRepository');
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.hasInitialized = true;
    this.initForm();
  }

  ngOnChanges(): void {
    if (!this.hasInitialized) {
      return;
    }
    this.initForm();
  }

  get serviceCloseSound(): string {
    // return this.vmEnabledCtrl.value ? 'service_closed_voicemail' : 'service_closed';
    return 'service_closed';
  }

  get opening_calendar(): OpeningHourCalendarEntity {
    return this.did ? this.did.opening_calendar : this.queue.opening_calendar;
  }

  get vm_enabled(): number {
    if (this.did) {
      const actionNotOfficeHour = this.getActionNotOfficeHour();
      return !_.isEmpty(actionNotOfficeHour) && actionNotOfficeHour.application === RoutingAppName.voicemail && 1 || 0;
    }

    const agendaClosedConfig = this.getAgendaClosedConfig();
    return !_.isEmpty(agendaClosedConfig) && agendaClosedConfig.application === RoutingAppName.voicemail && 1 || 0;
  }

  get opening_hour_file(): FileEntryEntity {
    return this.did ? this.did.opening_hour_file : this.queue.opening_hour_file;
  }

  get voicemail_by_mail_enabled(): number {
    return this.did ? this.did.voicemail_by_mail_enabled : this.queue.voicemail_by_mail_enabled;
  }

  get voicemail_in_attachement(): number {
    return this.did ? this.did.voicemail_in_attachement : this.queue.voicemail_in_attachement;
  }

  get voicemail_by_mail_addrs(): string[] {
    return this.did ? this.did.voicemail_by_mail_addrs : this.queue.voicemail_by_mail_addrs;
  }

  initForm(): void {
    if (!this.queue && !this.did) {
      throw new Error('At least one did or queue given');
    }

    this.actionNotOfficeHour = this.getActionNotOfficeHour();

    const formGroup: any = {
      opening_calendar: [this.opening_calendar],
      vm_enabled: [!!this.vm_enabled],
      opening_hour_file: [this.opening_hour_file],
      // calendar_reroute: [!_.isEmpty(this.actionNotOfficeHour)],
      action_not_office_hour: [this.actionNotOfficeHour],
      voicemail_by_mail_enabled: [this.voicemail_by_mail_enabled],
      voicemail_in_attachement: [this.voicemail_in_attachement],
      voicemail_by_mail_addrs: [this.voicemail_by_mail_addrs],
    };

    if (!this.hideClosedConfig) {
      const agendaClosedConfig = this.getAgendaClosedConfig();
      formGroup.agenda_closed_config = [agendaClosedConfig];
    }

    this.form = this.fb.group(formGroup);

    this.addSubscription(
      this.form.valueChanges.subscribe(val => {
        this.onFormValueChange();
      })
    );
  }

  private getAgendaClosedConfig(): IRoutingAppDestinationData {
    return this.queue && !_.isEmpty(this.queue.agenda_closed_config)
      ? _.cloneDeep(this.queue.agenda_closed_config) : new IRoutingAppDestinationData();
  }

  private getActionNotOfficeHour(): IRoutingAppDestinationData {
    return this.did && !_.isEmpty(this.did.action_not_office_hour)
      ? _.cloneDeep(this.did.action_not_office_hour) : new IRoutingAppDestinationData();
  }

  validateForm(): boolean {
    if (!this.openingCalendarControl.value) {
      return true;
    }

    this.form.get('action_not_office_hour').setErrors(null);
    if (this.callDestination) {
      this.callDestination.clearValidations();

      if (!this.callDestination.validParam()) {
        this.form.get('action_not_office_hour').setErrors({ invalid: true });
        this.form.get('action_not_office_hour').markAsTouched();

        return false;
      }
    }

    if (!this.hideClosedConfig) {
      this.form.get('agenda_closed_config').setErrors(null);
      if (this.agendaClosedConfigDestination &&
        (
          // !this.getFormControlValue('agenda_closed_config') ||
          // !this.getFormControlValue('agenda_closed_config').application ||
          !this.agendaClosedConfigDestination.validParam()
        )
      ) {
        this.form.get('agenda_closed_config').setErrors({ callDestination: true });
        this.form.get('agenda_closed_config').markAsTouched();
        return false;
      }
    }

    return true;
  }

  private resetAgendaClosedConfigDestination(): void {
    if (this.agendaClosedConfigDestination) {
      this.agendaClosedConfigDestination.value = this.getAgendaClosedConfig();
      this.agendaClosedConfigDestination.resetForm();
    }
  }

  resetForm(options: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    if (!this.hideClosedConfig) {
      this.resetAgendaClosedConfigDestination();
    }

    this.actionNotOfficeHour = this.getActionNotOfficeHour();
    const value: any = {
      opening_calendar: this.opening_calendar,
      vm_enabled: !!this.vm_enabled,
      opening_hour_file: this.opening_hour_file,
      // calendar_reroute: !_.isEmpty(this.actionNotOfficeHour),
      action_not_office_hour: this.actionNotOfficeHour,
      voicemail_by_mail_enabled: this.voicemail_by_mail_enabled,
      voicemail_in_attachement: this.voicemail_in_attachement,
      voicemail_by_mail_addrs: this.voicemail_by_mail_addrs,
    };

    if (!this.hideClosedConfig) {
      value.agenda_closed_config = this.getAgendaClosedConfig();
    }

    this.form.reset(value, options);
    this.form.markAsPristine();
    this.cdr.detectChanges();
  }

  pristineForm(): void {
    this.form.markAsPristine();
  }

  getDestinationQueue(did: DidEntity): CallQueueEntity {
    // if (did.hasLinkedUser()) {
    //   return this.getUserDedicatedQueue(did);
    // }

    return did.getDestinationObject();
  }

  // private getUserDedicatedQueue(did: DidEntity): CallQueueEntity {
  //   if (!did.hasLinkedUser()) {
  //     return;
  //   }

  //   const queueDedicatedList: CallQueueEntity[] = this.em.getRepository('CallQueueRepository').getObjectList() || [];

  //   return queueDedicatedList.find((q: CallQueueEntity) => q.dedicated_user_id === did.linked_object_id);
  // }

  get openingCalendarControl(): AbstractControl {
    return this.form && this.form.get('opening_calendar');
  }

  get openingHourFileCtrl(): AbstractControl {
    return this.form && this.form.get('opening_hour_file');
  }

  get vmEnabledCtrl(): AbstractControl {
    return this.form && this.form.get('vm_enabled');
  }

  private setFormControlValue(formControlName: string, value: any): void {
    this.form.get(formControlName).markAsDirty();
    this.form.get(formControlName).setValue(value);
  }

  private getFormControlValue(formControlName: string): any {
    return this.form.get(formControlName).value;
  }

  removeCalendar(): void {
    this.setFormControlValue('opening_calendar', undefined);
    if (this.did) {
      this.actionNotOfficeHourOnChange(null);
    } else if (this.queue && !this.hideClosedConfig) {
      this.updateAgendaClosedConfig(null);
    }
  }

  actionNotOfficeHourOnChange(actionNotOfficeHour: IRoutingAppDestinationData): void {
    this.setFormControlValue('action_not_office_hour', actionNotOfficeHour);
  }

  openVoicemailByEmailSettings(): void {
    const voicemailOption: VoicemailOptionInterface = {
      voicemail_by_mail_enabled: this.form.get('voicemail_by_mail_enabled').value,
      voicemail_in_attachement: this.form.get('voicemail_in_attachement').value,
      voicemail_by_mail_addrs: this.form.get('voicemail_by_mail_addrs').value
    };

    const dialogRef = this.dialogService.openSideDialog(VoicemailOptionModal, { data: { voicemailOption }, size: 's' });

    dialogRef.afterClosed().subscribe(voicemailSetting => {
      if (!voicemailSetting) {
        return;
      }

      this.setFormControlValue('voicemail_by_mail_enabled', voicemailSetting.voicemail_by_mail_enabled);
      this.setFormControlValue('voicemail_in_attachement', voicemailSetting.voicemail_in_attachement);
      this.setFormControlValue('voicemail_by_mail_addrs', voicemailSetting.voicemail_by_mail_addrs);
    });
  }

  getDefaultCalendar(): OpeningHourCalendarEntity {
    const myEnterprise: EnterpriseEntity = this.em
      .getRepository<EnterpriseRepository>('EnterpriseRepository')
      .getMyEnterprise();

    return myEnterprise.default_opening_calendar;
  }

  openDialogSelectCalendar(): void {
    let seletedCalendar = this.opening_calendar;
    if (!seletedCalendar) {
      const defaultCalendar = this.getDefaultCalendar();
      if (defaultCalendar && defaultCalendar.id) {
        seletedCalendar = defaultCalendar;
      }
    }

    const dialogRef = this.dialogService.openSideDialog(CalendarSelectListDialogComponent, {
      data: {
        calendar: seletedCalendar,
        hasEmpty: false
      },
      size: 's'
    });

    dialogRef.afterClosed().subscribe(newCalendar => {
      if (newCalendar) {
        this.setFormControlValue('opening_calendar', newCalendar);
      }
    });
  }

  updateOpeningHourFile(uploadResult: { new_file_entry: FileEntryEntity; remove?: boolean }): void {
    const fileEntry: FileEntryEntity = uploadResult.new_file_entry;
    if (!uploadResult.remove && !fileEntry.is_default && (!fileEntry || !fileEntry.getId())) {
      this.toastService.showError(_ti('No file entry created/updated'));

      return;
    }

    this.setFormControlValue('opening_hour_file', fileEntry);
  }

  calendarRerouteChange(change: MatButtonToggleChange): void {
    this.actionNotOfficeHour = this.getActionNotOfficeHour();
  }

  updateAgendaClosedConfig(data: IRoutingAppDestinationData): void {
    this.setFormControlValue('agenda_closed_config', data);
    // let agendaClosedConfig: any;
    // if (data && data.application) {
    //   agendaClosedConfig = data;
    // }

    // this.form.get('agenda_closed_config').markAsDirty();
    // this.form.get('agenda_closed_config').setValue(agendaClosedConfig || null);
  }

  isVoicemailEnabled(): boolean {
    const data = this.did ? this.getFormControlValue('action_not_office_hour') : this.getFormControlValue('agenda_closed_config');
    return data && data.application === RoutingAppName.voicemail || false;
  }

  updateLayout(): void {
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }
}
