<div fxFlex="grow">
  <form [formGroup]="form" class="pa-1" *ngIf="form">
    <div fxLayout="column">
      <h4 class="fontfamily-bold">{{'call_queue.content.tab_general'|translate}}</h4>

      <div *ngIf="!multipleEdit">
        <mat-form-field class="full-width" [attr.data-ci]="'campaign_name'">
          <mat-label>{{ 'campaign.name'|translate }}</mat-label>
          <input matInput [placeholder]="'campaign.name'|translate" formControlName="name">
          
          <mat-error [flexFormControlError]="form.get('name')"
            *ngIf="form.get('name').invalid && form.get('name').touched"
            [customMessages]="{
              'exists': 'campaign.validator.campaign_name_already_exists'|translate
            }"></mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <div fxFlex>
          <mat-form-field class="full-width" [attr.data-ci]="'start_dt'">
            <mat-label>{{ 'campaign.schedule_start_dt'|translate }}</mat-label>
            <input matInput (change)="markAsDateChanged()" [matDatepicker]="scheduleStartDtPicker"
              [formControl]="form.controls['schedule_start_date']"
              [placeholder]="'campaign.schedule_start_dt' | translate">
            <mat-datepicker-toggle matSuffix [for]="scheduleStartDtPicker"></mat-datepicker-toggle>
            <mat-datepicker #scheduleStartDtPicker></mat-datepicker>

          </mat-form-field>
        </div>
        <div fxFlex>
          <mat-form-field class="full-width" [attr.data-ci]="'end_dt'">
            <mat-label>{{ 'campaign.schedule_end_dt'|translate }}</mat-label>
            <input matInput (change)="markAsDateChanged()" [matDatepicker]="scheduleEndDtPicker"
              [formControl]="form.controls['schedule_end_date']" [placeholder]="'campaign.schedule_end_dt' | translate">
            <mat-datepicker-toggle matSuffix [for]="scheduleEndDtPicker"></mat-datepicker-toggle>
            <mat-datepicker #scheduleEndDtPicker></mat-datepicker>

            <mat-error [flexFormControlError]="form.get('schedule_end_date')"
              *ngIf="form.get('schedule_end_date').invalid && form.get('schedule_end_date').touched"
              [customMessages]="{
                'small': 'campaign.message.schedule_end_date_must_greater_than_schedule_start_date'|translate
              }"></mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>
        <number-opening-calendar #numberOpeningCalendar 
          [queue]="queue"
          [hideClosedConfig]="true"
          (formValueChanges)="numberOpeningCalendarChange()">
        </number-opening-calendar>
      </div>

      <!-- <div>
        <mat-slide-toggle [attr.data-ci]="'show_time'" (change)="changeShowTime($event)" [(ngModel)]="show_timepicker"
          [ngModelOptions]="{standalone: true}">
          <span class="fontfamily-bold">{{'campaign.set_schedule_time'|translate}}</span>
        </mat-slide-toggle>
      </div> -->

      <!--Time-->
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" [hidden]="!show_timepicker">
        <div fxLayout="column" fxFlex>
          <flex-time-picker [attr.data-ci]="'hour_start'" [formControl]="form.controls['hour_start']"
            [nullable]="true"
            placeholder="{{ 'campaign.hour_start'|translate }}" (timeSet)="markAsHourChanged('hour_start')">
          </flex-time-picker>
          
          <mat-error [flexFormControlError]="form.get('hour_start')"
            *ngIf="form.get('hour_start').invalid && form.get('hour_start').touched"></mat-error>
        </div>

        <div fxLayout="column" fxFlex>
          <flex-time-picker [attr.data-ci]="'hour_end'" [formControl]="form.controls['hour_end']"
            [nullable]="true"
            placeholder="{{ 'campaign.hour_end'|translate }}" (timeSet)="markAsHourChanged('hour_end')">
          </flex-time-picker>

          <mat-error [flexFormControlError]="form.get('hour_end')"
            *ngIf="form.get('hour_end').invalid && form.get('hour_end').touched"
            [customMessages]="{
              'small': 'campaign.message.hour_end_must_greater_than_hour_start'|translate
            }"></mat-error>
        </div>
      </div>


      <div *ngIf="hasFeature('SERVICE_GROUP')">
        <mat-form-field class="full-width">
          <mat-label>{{ 'call_queue.content.group'|translate }}</mat-label>
          <usergroup-select-form-input [formControl]="form.controls['group_id']" [attr.data-ci]="'group'"
            [enabled]="isAdmin"
            [placeholder]="'call_queue.content.group'|translate">
          </usergroup-select-form-input>
          <button mat-icon-button matSuffix type="button" (click)="removeControlValue('group_id')"
            [disabled]="!form.controls['group_id'].value || !isAdmin">
            <mat-icon>close</mat-icon>
          </button>
          
          <mat-error [flexFormControlError]="form.get('group_id')"
            *ngIf="form.get('group_id').invalid && form.get('group_id').touched"></mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-label>{{'campaign.calling_number'|translate}}</mat-label>
          <did-select-form-input [formControl]="form.controls['calling_number_id']" [attr.data-ci]="'calling_number'"
            [placeholder]="'campaign.calling_number'|translate">
          </did-select-form-input>
          <button mat-icon-button matSuffix type="button" (click)="removeControlValue('calling_number_id')"
            [disabled]="!form.controls['calling_number_id'].value">
            <mat-icon>close</mat-icon>
          </button>
            
          <mat-error [flexFormControlError]="form.get('calling_number_id')"
            *ngIf="form.get('calling_number_id').invalid && form.get('calling_number_id').touched"></mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-slide-toggle [formControl]="form.controls['recording_mode']" [attr.data-ci]="'recording_mode'"
          [checked]="form.controls['recording_mode'].value == 1">
          <span class="fontfamily-bold">{{'campaign.record_all_call'|translate}}</span>
        </mat-slide-toggle>
            
        <mat-error [flexFormControlError]="form.get('recording_mode')"
          *ngIf="form.get('recording_mode').invalid && form.get('recording_mode').touched"></mat-error>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'call_queue.content.priority'|translate }}</mat-label>
          <mat-select class="full-width" [formControl]="form.controls['queue_priority']"
            [attr.data-ci]="'queue_priority'" [placeholder]="'call_queue.content.priority'|translate">
            <mat-option *ngFor="let item of priority_list" [value]="item.value">
              {{item.label|translate}}
            </mat-option>
          </mat-select>

          <mat-error [flexFormControlError]="form.get('queue_priority')"
            *ngIf="form.get('queue_priority').invalid && form.get('queue_priority').touched"></mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.campaign.max_try_count' | translate }}</mat-label>
          <input type="number" matInput formControlName="max_try_count" data-ci="max_try_count"
            [placeholder]="'campaign.campaign.max_try_count' | translate">
            
          <mat-error [flexFormControlError]="form.get('max_try_count')"
            *ngIf="form.get('max_try_count').invalid && form.get('max_try_count').touched"
            [customMessages]="{
              'min': 'campaign.validator.max_try_count_minmax'| translate: {min: 1, max: 100},
              'max': 'campaign.validator.max_try_count_minmax'| translate: {min: 1, max: 100}
            }"></mat-error>
          
        </mat-form-field>
      </div>

      <mat-divider [inset]="true" class="mt-2"></mat-divider>
      <h4 class="fontfamily-bold mt-2">{{'campaign.campaign_type'|translate}}</h4>

      <div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.campaign_type'|translate }}</mat-label>
          <mat-select class="full-width" [formControl]="form.get('campaign_type')" [attr.data-ci]="'campaign_type'"
            [placeholder]="'campaign.campaign_type'|translate">
            <mat-option *ngFor="let item of campaign_types" [value]="item.value">
              {{item.name|translate}}
            </mat-option>
          </mat-select>

          <mat-error [flexFormControlError]="form.get('campaign_type')"
            *ngIf="form.get('campaign_type').invalid && form.get('campaign_type').touched"></mat-error>

        </mat-form-field>
      </div>

      <div [hidden]="!(form.get('campaign_type').value==1)">
        <mat-form-field class="full-width">
          <mat-label>{{ 'campaign.select_ivrmenu'|translate }}</mat-label>
          <ivr-menu-select-form-input [menuType]="0" [isConfigured]="true" [formControl]="form.get('ivr_id')"
            [attr.data-ci]="'ivr_menu'" [placeholder]="'campaign.select_ivrmenu'|translate">
          </ivr-menu-select-form-input>
          <button mat-icon-button matSuffix type="button" (click)="removeControlValue('ivr_id')"
            [disabled]="!form.get('ivr_id').value">
            <mat-icon>close</mat-icon>
          </button>

          <mat-error [flexFormControlError]="form.get('ivr_id')"
            *ngIf="form.get('ivr_id').invalid && form.get('ivr_id').touched"></mat-error>
          
        </mat-form-field>
      </div>

      <hr class="dashed-border mt-2" />
      <h4 class="fontfamily-bold mt-2">{{'call_queue.content.call_tagging'|translate}}</h4>

      <p *ngIf="!hasQualifications;  else hasQualificationsBlock" class="create_qualification"
        [innerHTML]="'call_queue.content.hint_create_qualification'|translate">
      </p>

      <ng-template #hasQualificationsBlock>
        <div>
          <mat-slide-toggle [formControl]="form.controls['has_qualification']" [attr.data-ci]="'has_qualification'"
            [checked]="form.controls['has_qualification'].value == 1">
            <span class="fontfamily-bold">{{'call_queue.content.call_tagging'|translate}}</span>
          </mat-slide-toggle>
          <mat-error [flexFormControlError]="form.get('has_qualification')"
            *ngIf="form.get('has_qualification').invalid && form.get('has_qualification').touched"></mat-error>
        </div>

        <div *ngIf="form.controls['has_qualification'].value == 1">
          <mat-form-field class="full-width">
            <mat-label>{{ 'call_queue.content.out_qualification'|translate }}</mat-label>
            <qualification-select-form-input formControlName="out_qualification_id" [attr.data-ci]="'out_qualification'"
              [allowEmpty]="false" [placeholder]="'call_queue.content.out_qualification'|translate">
            </qualification-select-form-input>
            <mat-error [flexFormControlError]="form.get('out_qualification_id')"
              *ngIf="form.get('out_qualification_id').invalid && form.get('out_qualification_id').touched"></mat-error>
          </mat-form-field>
        </div>
      </ng-template>

      <div fxLayout="column" class="pt-2 block-wrapper">
        <mat-label>{{ 'campaign.when_new_call_start'|translate }}</mat-label>
        <mat-card class="mat-card-flat ma-0 pa-0">
          <mat-card-content class="padded-content">
            <mat-button-toggle-group fxLayout="row" formControlName="bo_type" class="button-multiple-choice">
              <mat-button-toggle fxFlex="50" [value]="backOfficeType.NULL" [attr.data-ci]="'bo_type_null'"
                class="button-multiple-choice">
                {{'call_queue.content.open_none'|translate}}
              </mat-button-toggle>
              <mat-button-toggle fxFlex="50" [value]="backOfficeType.URL" [attr.data-ci]="'bo_type_url'"
                class="button-multiple-choice">
                {{'call_queue.content.open_url'|translate}}
              </mat-button-toggle>
            </mat-button-toggle-group>
            
            <mat-error [flexFormControlError]="form.get('bo_type')"
              *ngIf="form.get('bo_type').invalid && form.get('bo_type').touched"></mat-error>

            <div fxLayout="row" fxLayoutGap="20px" class="mt-1"
              *ngIf="form.get('bo_type').value == backOfficeType.URL">
              <div fxFlex="100px">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'call_queue.content.bo_method'|translate }}</mat-label>
                  <mat-select formControlName="bo_method">
                    <mat-option value="GET">GET</mat-option>
                    <mat-option value="POST">POST</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="auto">
                <mat-form-field class="full-width">
                  <mat-label>{{ 'call_queue.content.bo_url'|translate }}</mat-label>
                  <input matInput formControlName="bo_url" placeholder="{{ 'call_queue.content.bo_url'|translate }}">
                  <mat-error [flexFormControlError]="form.get('bo_url')"
                    *ngIf="form.get('bo_url').invalid && form.get('bo_url').touched"></mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <mat-divider [inset]="true" class="mt-2"></mat-divider>
      <h4 class="fontfamily-bold mt-2">{{'campaign.when_a_call_end'|translate}}</h4>

      <div [ngClass]="multipleEdit ? 'hidden-toggle' : ''">
        <div>
          <mat-slide-toggle [formControl]="form.controls['aftercall_pause_mode']" color="primary"
            [attr.data-ci]="'aftercall_pause_mode'" 
            (selectionChange)="onChangeAfterCallPauseMode($event)">
            {{'call_queue.content.aftercall_pause_mode'|translate}}
          </mat-slide-toggle>
        </div>
  
        <div [hidden]="!form.controls['aftercall_pause_mode'].value">
          <mat-form-field class="full-width">
            <mat-label>{{ 'campaign.limit_pause_duration'|translate }}</mat-label>
            <flex-duration-input [attr.data-ci]="'after_call_time'" 
              [formControl]="form.controls['after_call_time']" 
              [predefined_values]="{
                '0': 'call_queue.content.max_inqueue.never'|translate
              }"
              [enabled]="form.controls['aftercall_pause_mode'].value == true">
            </flex-duration-input>
            <mat-error [flexFormControlError]="form.controls['after_call_time']"
              *ngIf="form.controls['after_call_time'].invalid && form.controls['after_call_time'].touched"></mat-error>
          </mat-form-field>
        </div>
  
        <div>
            <mat-slide-toggle [formControl]="form.controls['am_check_enabled']" color="primary">
              {{'campaign.content.am_check_enabled'|translate}}
            </mat-slide-toggle>
        </div>
        
        <div [hidden]="!form.controls['am_check_enabled'].value">
          <div fxLayout="row">
            <div fxFlex>
              <mat-form-field class="full-width">
                <mat-label>{{ 'campaign.content.am_check_max_duration'|translate }}</mat-label>
      
                <flex-duration-input [formControl]="form.controls['am_check_max_duration']"></flex-duration-input>
      
                <mat-error [flexFormControlError]="form.controls['am_check_max_duration']"
                  *ngIf="form.controls['am_check_max_duration'].invalid && form.controls['am_check_max_duration'].touched"></mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="10px"></div>
            <div fxFlex>
              <mat-form-field class="full-width">
                <mat-label>{{ 'campaign.content.am_action'|translate }}</mat-label>
      
                <mat-select class="full-width" [formControl]="form.controls['am_action']"
                  [placeholder]="'campaign.content.am_action'|translate">
                  <mat-option *ngFor="let item of amActionList" [value]="item.value">
                    {{item.name|translate}}
                  </mat-option>
                </mat-select>
                
                <mat-error [flexFormControlError]="form.controls['am_action']"
                *ngIf="form.controls['am_action'].invalid && form.controls['am_action'].touched"></mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  </form>
</div>
