import { Component, ViewChild, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LiveQueueData, LiveStatsService, IChartFilter, ILiveQueueFilter } from '@wephone-core/service/live-stats.service';
import { AuthenticationService } from '@wephone-core/service/authentication';
import { Chart } from 'angular-highcharts';

import * as _ from 'lodash';
import { ChartStatsDataInterface, HighChartsHelperService } from '@wephone-core/service/high_charts_helper.service';
import { QueuesFilterPage } from '@wephone/modals/queues-filter/queues-filter';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { DialogService, IFlexDialogConfig, parseDateTime, parseStringWithFormat, localNow } from '@wephone-utils';
import { ConfigManager, EntityManager } from '@wephone-core/wephone-core.module';
import { GroupRepository } from '@wephone-core/model/repository/group';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { GroupTreeModal } from '@wephone/modals/group-tree-modal/group-tree-modal';
import { StorageService } from '@wephone-core/service/storage.service';
import { FlexBasePage } from '@wephone-core-ui';
import { Router, ActivatedRoute } from '@angular/router';
import { ImportUsersFromCrmModal } from '@wephone/modals/import-users-from-crm-modal/import-users-from-crm-modal.component';
import { ChartPeriod } from '../graph-chart/graph-chart-type';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { TranslateService } from '@ngx-translate/core';

export class DasboardFilterKey {
  static STORAGE_FILTER_GROUP_IDS = 'dashboard-filtered_group_ids';
  static STORAGE_FILTER_GROUP_CHECKALL = 'dashboard-filtered_checkall_groups';
  static STORAGE_CHART_CALL_FILTERS = 'dashboard-chart_call_filters';
  static STORAGE_GRAPH_CALL_FILTERS = 'graph-chart_call_filters';
}

@Component({
  selector: 'page-dashboard',
  templateUrl: 'dashboard.html',
  styleUrls: ['dashboard.scss']
})
export class DashboardPage extends FlexBasePage implements OnInit, OnDestroy {
  realtime_pushapi = true;
  @ViewChild('pieCanvas') pieCanvas;
  @ViewChild('lineCanvas') lineCanvas;

  private isReloadingData = false;

  queues_info: LiveQueueData[] = [];
  filtered_group_ids: number[] = [];
  filtered_checkall_groups = true;
  call_filters: IChartFilter = {
    day: new Date()
  };
  filteredStats: ILiveQueueFilter = {
    period: ChartPeriod.day,
    include_outbound: 1
  };

  interval: any;

  lineChart: Chart;
  showDashboardStats: boolean;
  filteredQueues: CallQueueEntity[] = [];

  constructor(
    private dialogService: DialogService,
    private storage: StorageService,
    private liveStatsService: LiveStatsService,
    private authService: AuthenticationService,
    private highChartsHelperService: HighChartsHelperService,
    private configManager: ConfigManager,
    private em: EntityManager,
    private router: Router,
    cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private readonly translate: TranslateService,
  ) {
    super(cdr);

  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.interval) {
      this.interval = setInterval(() => this.reloadData(), 30000); // Auto refresh after 30s
    }

    this.showDashboardStats = !(this.authService.isWatcher() || this.authService.isAccountant());

    this.addSubscription(
      this.translate.onLangChange.subscribe(() => {
        this.buildCallStatsHistory();
      })
    );

    if (this.route.snapshot.queryParamMap.has('import_users_from_crm')) {
      const crmId = this.route.snapshot.queryParamMap.get('import_users_from_crm');

      if (_.isNumber(+crmId)) {
        const modalConfig: IFlexDialogConfig = {
          size: 'fs',
          data: { crmId, showIgnoreStep: true },
          padding: false
        };

        this.dialogService.openDialog2(ImportUsersFromCrmModal, modalConfig);
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  async resolveData(): Promise<any> {
    const filtered_group_ids = await this.storage.getDomainConfig(DasboardFilterKey.STORAGE_FILTER_GROUP_IDS);
    const filtered_checkall_groups = await this.storage.getDomainConfig(DasboardFilterKey.STORAGE_FILTER_GROUP_CHECKALL);
    this.filtered_group_ids = filtered_group_ids || [];
    this.filtered_checkall_groups = !_.isUndefined(filtered_checkall_groups) && filtered_checkall_groups || true;

    await this.initCallFilters();
  }

  private isCallCenterQueue(callQueueId: number): boolean {
    const queue = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(callQueueId);
    if (!queue) {
      return false;
    }

    return !queue.is_outbound_campaign || queue.is_outbound_campaign !== 1;
  }

  private async initCallFilters(): Promise<any> {
    let chartFilterObjects = await this.storage.getDomainConfig(DasboardFilterKey.STORAGE_CHART_CALL_FILTERS);
    if (!chartFilterObjects) {
      const hour_limit: { start: string; end: string } = this._getCharLimitHours();
      chartFilterObjects = {
        time_start: hour_limit.start + ':00',
        time_end: hour_limit.end + ':00',
        queues: []
      };
    }
    this.call_filters = {
      day: localNow().toJSDate(),
      queues: chartFilterObjects.queues || [],
      time_start: chartFilterObjects.time_start || '00:00',
      time_end: chartFilterObjects.time_end || '00:00'
    };

    this.setFilteredQueues();
    this.callFiltersByDay();
  }

  private _getCharLimitHours(): { start: string; end: string } {
    const ret: { start: string; end: string } = {
      start: '00',
      end: '00'
    };

    const my_enterprise: EnterpriseEntity = this.em
      .getRepository<EnterpriseRepository>('EnterpriseRepository')
      .getMyEnterprise();
    const calendar = my_enterprise.default_opening_calendar;

    if (!calendar || (calendar.openinghours || []).length === 0) {
      return ret;
    }

    let start_hour: number;
    let end_hour: number;

    for (const item of calendar.openinghours) {
      const start_time_parse: string[] = item.start_time.split(':');
      const start_item_hour: number = +start_time_parse[0];
      start_hour = !start_hour || start_hour > start_item_hour ? start_item_hour : start_hour;

      const end_time_parse = item.end_time.split(':');
      const end_item_hour = +end_time_parse[0];
      end_hour = !end_hour || end_hour < end_item_hour ? end_item_hour : end_hour;
    }

    ret.start = start_hour < 10 ? '0' + start_hour : start_hour.toString();
    ret.end = end_hour < 10 ? '0' + end_hour : end_hour.toString();

    return ret;
  }

  private buildCallStatsHistory(): any {
    if (!this.call_filters.time_start || !this.call_filters.time_end) {
      console.warn('Invalid time range');
      return;
    }

    const hour_start: number = +this.call_filters.time_start.substr(0, 2);
    let hour_end: number = +this.call_filters.time_end.substr(0, 2);
    if (this.call_filters.time_end === '00:00') {
      hour_end = 23;
    }
    const historyData: ChartStatsDataInterface = {};

    for (const q of this.queues_info) {
      if (
        !this.isCallCenterQueue(q.queue_id) ||
        ((this.call_filters.queues || []).length && this.call_filters.queues.indexOf(q.queue_id) === -1) ||
        !q.hasOwnProperty('call_stats') ||
        !q.call_stats.hasOwnProperty('history') ||
        (!this.filtered_checkall_groups && (!q.group_id || this.filtered_group_ids.indexOf(q.group_id) === -1))
      ) {
        continue;
      }

      for (const idx of Object.keys(q['call_stats']['history'])) {
        const history = q['call_stats']['history'][idx];
        if (+idx < hour_start || +idx > hour_end) {
          continue;
        }

        if (!historyData[idx]) {
          historyData[idx] = _.cloneDeep(history);
          continue;
        }
        for (const i of Object.keys(history)) {
          if (!historyData[idx][i]) {
            historyData[idx][i] = 0;
          }
          historyData[idx][i] += +history[i];
        }
      }
    }

    this.drawLintChart(historyData);
  }

  private drawLintChart(statsData: ChartStatsDataInterface): void {
    if (this.lineChart) {
      this.lineChart.destroy();
    }

    const _op: any = this.highChartsHelperService.get_charts_line_data(ChartPeriod.today, statsData, this.call_filters);
    this.lineChart = new Chart({
      chart: {
        height: 300,
        type: 'column'
      },
      credits: _op.credits,
      legend: _op.options.legend,
      plotOptions: _op.options.plotOptions,
      title: _op.options.title,
      tooltip: _op.options.tooltip,
      xAxis: _op.xAxis,
      yAxis: _op.options.yAxis,
      series: _op.series
    });
  }

  reloadData(): void {
    if (this.isReloadingData) {
      console.warn('Data is reloading');
      return;
    }

    this.isReloadingData = true;

    this.liveStatsService.getLiveQueueInfo(this.filteredStats, this.call_filters).then(data => {
      this.queues_info = data || [];
      this.isReloadingData = false;
      this.buildCallStatsHistory();
      return data;
    }, response => {
      console.error('Load chart data error', response);
      this.isReloadingData = false;
      return;
    });
  }

  setFilteredQueues(): void {
    this.filteredQueues = [];
    if (this.call_filters && this.call_filters.queues) {
      this.filteredQueues = this.em.getRepository('CallQueueRepository').getObjectListByIds(this.call_filters.queues);
    }
  }

  callFiltersByDay(): void {
    this.storage.setDomainConfig(DasboardFilterKey.STORAGE_CHART_CALL_FILTERS, {
      time_start: this.call_filters.time_start,
      time_end: this.call_filters.time_end,
      queues: this.call_filters.queues
    });

    const dayString: string = parseDateTime(this.call_filters.day).toFormat('yyyy-MM-dd');

    this.filteredStats.period_start_date = dayString;
    this.filteredStats.period_end_date = dayString;
    this.reloadData();
  }

  private isAvailableFilterQueue(q: any): boolean {
    if (!q.queue_id) {
      return false;
    }

    const queueEntity = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectById(q.queue_id);
    if (_.isEmpty(queueEntity)) {
      return false;
    }
    console.log('isAvailableFilterQueue filtered_checkall_groups', this.filtered_checkall_groups);
    if (this.filtered_checkall_groups === true) {
      return true;
    }

    return (q.group_id && this.filtered_group_ids.indexOf(q.group_id) > -1) ? true : false;
  }

  filterChartData(): void {
    const cc_queues: LiveQueueData[] = this.queues_info.filter(q => {
      if (!this.isAvailableFilterQueue(q)) {
        return false;
      }
      return this.isCallCenterQueue(q.queue_id);
    });

    this.dialogService.openDialog2(
      QueuesFilterPage,
      {
        data: {
          show_date: true,
          show_queue: true,
          queues: cc_queues || [],
          day: this.call_filters.day,
          time_start: this.call_filters.time_start,
          time_end: this.call_filters.time_end,
          filter_queue_ids: this.call_filters.queues
        },
      },
      ret => {
        if (!ret) {
          return;
        }

        this.call_filters.day = ret.day;
        this.call_filters.queues = ret.filter_queue_ids;
        this.call_filters.time_start = ret.time_start;
        this.call_filters.time_end = ret.time_end;
        this.setFilteredQueues();
        this.callFiltersByDay();
      }
    );
  }

  showGroupFilterDialog(): void {
    const group_list = this.em.getRepository<GroupRepository>('GroupRepository').getServiceGroupList();
    const allgroup_id_list = group_list.map(g => {
      return g.id;
    });

    const group_ids =
      allgroup_id_list.filter(gid => {
        return this.filtered_group_ids.indexOf(gid) !== -1;
      }) || [];

    this.dialogService.openDialog2(
      GroupTreeModal,
      {
        data: {
          group_ids,
          checked_all: this.filtered_checkall_groups
        },
        size: 's'
      }, async ret => {
        if (!ret) {
          return;
        }

        const selected_group_ids = ret.selected_group_ids;
        this.filtered_checkall_groups = ret.checked_all_group;
        this.filtered_group_ids =
          allgroup_id_list.filter(gid => {
            return selected_group_ids.indexOf(gid) !== -1;
          }) || [];

        await this.storage.setDomainConfig(DasboardFilterKey.STORAGE_FILTER_GROUP_IDS, this.filtered_group_ids);
        await this.storage.setDomainConfig(DasboardFilterKey.STORAGE_FILTER_GROUP_CHECKALL, this.filtered_checkall_groups);

        this.buildCallStatsHistory();
      }
    );
  }

  hasFeature(feature: string): boolean {
    return this.configManager.hasFeature(feature);
  }

  gotoTab(view: string): void {
    this.router.navigate([], { queryParams: { view } });
  }

  gotoPage(pageAlias: string): void {
    this.router.navigateByUrl(pageAlias);
  }
}
