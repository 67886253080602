import { Injectable } from '@angular/core';
import { HttpEngine } from '@wephone-core/service/http_engine';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
// import { ResponseContentType } from '@angular/http';
// import { CommonAPI } from '@wephone-core/service/common_api';
// import { StorageService } from '@wephone-core/service/storage.service';
import { FlexIvrSettings } from '@wephone-core/service/flexivr_settings';
import { FileEntryRepository } from '@wephone-core/model/repository/fileentry';
import { ElectronService } from 'ngx-electron';
import { SingletonBase } from '@wephone-utils';
import { TTSFileOption, GoogleCloudVoiceParam } from '@wephone-audio/service/text2speech-data.service';
import { CallQueueRepository } from '@wephone-core/model/repository/callqueue';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { DidEntity } from '@wephone-core/model/entity/did';
import { VoiceMailBoxRepository } from '@wephone-core/model/repository/voicemail_box';
import { VoiceMailBoxEntity } from '@wephone-core/model/entity/voicemail_box';
import { IvrCustomMenuRepository } from '@wephone-core/model/repository/ivr_custom_menu';
import { EntityManager } from './entity_manager';
import { UserRepository } from '@wephone-core/model/repository/user';
import { FileEntryCategory, FileEntryEntity } from '@wephone-core/model/entity/fileentry';
import { DidRepository } from '@wephone-core/model/repository/did';
import { CrmRoutingRuleEntity } from '@wephone-core/model/entity/crm_routing_rule';
import { CrmRoutingRuleRepository } from '@wephone-core/model/repository/crm_routing_rule';
import { UserEntity } from '@wephone-core/model/entity/user';
import { IvrCustomMenuEntity } from '@wephone-core/model/entity/ivr_custom_menu';
import { IvrEvaluationRepository } from '@wephone-core/model/repository/ivr_evaluation';
import { IvrEvaluationEntity } from '@wephone-core/model/entity/ivr_evaluation';
import { OpeningHourCalendarRepository } from '@wephone-core/model/repository/openinghour_calendar';
import { EnterpriseTelecomParamsEntity } from '@wephone-core/model/entity/enterprise_telecom_params';

export interface ObjectUsingFileEntry {
  [key: string]: DidEntity[] | UserEntity[] | IvrCustomMenuEntity[] | CrmRoutingRuleEntity[] | IvrEvaluationEntity[] | EnterpriseTelecomParamsEntity[];
};
@Injectable()
export class FileEntryService extends SingletonBase {
  static URL_FILE_ENTRY_GET = 'fileentry/{public_id}/{user_id}/{format}?modified_dt={modified_dt}';
  static URL_DEFAULT_FILE_ENTRY_GET = 'fileentry/system-default/{lang}/{public_id}';

  private URL_FILE_ENTRY_PUT = 'fileentry/put';
  private URL_FILE_ENTRY_DEL = 'fileentry/delete';
  private URL_FILE_ENTRY_UPDATE = 'fileentry/update';
  // private URL_FILE_ENTRY_TEXT2SPEECH = 'fileentry/record_text2speech'; // Deprecated
  // private URL_FILE_ENTRY_GGCLOUD_RECORD = 'fileentry/record_google_cloud';

  // private URL_FILE_ENTRY_REPLACE = 'sound/replace';
  // private URL_RECORD_VM_WELCOME = 'sound/record_by_phone';

  constructor(
    private readonly httpEngine: HttpEngine,
    private readonly http: HttpClient,
    private readonly settings: FlexIvrSettings,
    private readonly em: EntityManager,
    private readonly electron: ElectronService) {
    super();
  }

  /**
   * Use in get voicemail + recording call + reusable sound
   */
  getFileUrl(file_public_id: string, download: boolean = false, lang: string = 'en'): string {
    const fileEntry: FileEntryEntity = (this.em.getRepository('FileEntryRepository') as FileEntryRepository).getObjectByPublicId(file_public_id);
    let url: string;
    const isReusableSound = fileEntry && fileEntry.category === FileEntryCategory.USER_CONTENT;

    if (fileEntry && fileEntry.is_default) {
      url = FileEntryService.URL_DEFAULT_FILE_ENTRY_GET
        .replace('{public_id}', file_public_id)
        .replace('{lang}', lang)
        ;
    } else {
      url = FileEntryService.URL_FILE_ENTRY_GET
        .replace('{public_id}', file_public_id)
        .replace('{user_id}', '0')
        .replace('{format}', 'mp3')
        .replace('{modified_dt}', isReusableSound ? Date.now().toString() : ''); // Force to no using cache for reusable sound

      if (download) {
        url += '&download=1';
      }
    }

    return this.settings.getEnterpriseUrlV2(url);
  }

  // Not use
  // /**
  //  * Replace dest_id by src_id, so keep the file entry id dest_id, only content was replaced
  //  */
  // replaceFileEntry(src_id: number, dest_id: number): Promise<any> {
  //   const fileentry_repo: FileEntryRepository = FileEntryRepository.getInstance();
  //   const dest_file_entry = fileentry_repo.getObjectById(dest_id);
  //   if (!dest_file_entry) {
  //     console.error('No dest file entry found');

  //     return;
  //   }

  //   return this.httpEngine
  //     .apiGet(this.URL_FILE_ENTRY_REPLACE, { src_id, dest_id })
  //     .then((response: any) => {
  //       if (!response.item) {
  //         console.error('No response item');

  //         return;
  //       }

  //       const modified_dt = response.item.modified_dt;
  //       dest_file_entry.setModifiedDate(modified_dt);

  //       // manually remove from list
  //       const src_file_entry = fileentry_repo.getObjectById(src_id);
  //       if (src_file_entry) {
  //         // remove from list
  //         fileentry_repo.removeObjectFromList(src_file_entry);
  //       }
  //     });
  // }

  updateFileEntry(
    public_id: string,
    parameters: {
      replaced_file_entry?: {
        public_id: string,
        lang?: string
      },
      name?: string,
      alias?: string,
      is_temp?: number
    }
  ): Promise<any> {
    const fileentry_repo: FileEntryRepository = FileEntryRepository.getInstance();
    const file_entry = fileentry_repo.getObjectByPublicId(public_id);
    if (!file_entry) {
      console.error('No file entry found');
      return Promise.reject();
    }

    let params: any = { public_id };
    params = _.extend(params, parameters);
    return this.httpEngine.apiPostV2(this.URL_FILE_ENTRY_UPDATE, params);
  }

  removeFileEntries(public_ids: string[]): Promise<any> {
    return this.httpEngine.apiPostV2(this.URL_FILE_ENTRY_DEL, { public_ids });
  }

  upload(soundFile: any, soundOptions: any = null): Promise<any> {
    const params = soundOptions;

    // for (const opt of ['lang', 'name', 'creation_method', 'is_temp']) { //, 'is_shared'
    //   if (typeof soundOptions[opt] !== 'undefined') {
    //     params[opt] = soundOptions[opt];
    //   }
    // }

    const options: any = {
      data: params,
      method: 'POST',
      headers: { 'Content-Type': soundFile.type }
    };
    const body = new FormData();
    body.append('file', soundFile);
    for (const key of Object.keys(params)) {
      body.append(key, params[key]);
    }

    return this.httpEngine.apiPostV2(this.URL_FILE_ENTRY_PUT, body, options, true);
  }

  async download(fileurl: string, filename: string): Promise<any> {
    let url = fileurl;
    // Not download using blob because of CORS error from AWS
    if (this.electron.isElectronApp) {
      const blob = await this.http.get<Blob>(fileurl, { responseType: 'blob' as 'json' }).toPromise();
      url = window.URL.createObjectURL(blob);
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  // Not in-used
  // recordWelcome(phoneNumber: string, lang: string): Promise<any> {
  //   return this.httpEngine.apiPost(this.URL_RECORD_VM_WELCOME, {
  //     phone_number: phoneNumber,
  //     lang
  //   });
  // }

  // Deprecated
  // recordText2speech(text: string, soundOptions: any, voice: string): Promise<any> {
  //   const lang: string = soundOptions.lang;
  //   const name: string = soundOptions.name;
  //   // const is_shared = 0;

  //   return this.httpEngine.apiPostV2(this.URL_FILE_ENTRY_TEXT2SPEECH, {
  //     text,
  //     lang,
  //     voice,
  //     // is_shared,
  //     name
  //   });
  // }


  getEnterpriseFileEntryUrl(enterpriseFileId: number): string {
    const url = `/resource/enterprise_file_entry/get?id=${enterpriseFileId}`;

    return this.settings.getEnterpriseUrl(url);
  }

  getDidListConfigToFile(fileEntries: FileEntryEntity[]): DidEntity[] {
    const didList: DidEntity[] = [];
    for (const fileEntry of fileEntries) {
      for (const did of fileEntry.routedDids) {
        if (!_.includes(didList, did)) {
          didList.push(did);
        }
      }
    }
    return didList;
  }

  // Already defined from NestJs
  // getQueueListConfigToFile(fileEntries: FileEntryEntity[]): CallQueueEntity[] {
  //   const list: CallQueueEntity[] = [];
  //   for (const fileEntry of fileEntries) {
  //     for (const q of fileEntry.routedQueues) {
  //       if (!_.includes(list, q)) {
  //         list.push(q);
  //       }
  //     }
  //   }
  //   return list;
  // }

  getCrmRoutingListConfigToFile(fileEntries: FileEntryEntity[]): CrmRoutingRuleEntity[] {
    const crmRoutingList: CrmRoutingRuleEntity[] = [];
    for (const fileEntry of fileEntries) {
      for (const item of fileEntry.routedCrmRoutings) {
        if (!_.includes(crmRoutingList, item)) {
          crmRoutingList.push(item);
        }
      }
    }
    return crmRoutingList;
  }

  /**
   * Get routing object list using file-entry
   * @param ids: array number
   */
  async getObjectsUsedFileEntryByIds(ids: number[]): Promise<any> {
    const URL_GET_OBJECTS_USED_FILE_ENTRIES = 'fileentry/objects-used';
    const data = await this.httpEngine.apiGetV2(URL_GET_OBJECTS_USED_FILE_ENTRIES, { 'ids[]': ids });

    const ivrMenuRefs: object = {};
    const userWtcRefs: object = {};
    const voicemailUserRefs: object = {};
    const crmRoutingRuleRefs: object = {};
    const ivrEvaluationRefs: object = {};
    // const voicemailQueueRefs: object = {};
    const queueIdRefs: object = {};
    const calendarRefs: object = {};

    const users: UserEntity[] = this.em.getRepository<UserRepository>('UserRepository').getObjectList();
    for (const user of users) {
      if (!_.isEmpty(user.wait_timeout_config)) {
        userWtcRefs[user.id] = user;
      }

      if (user.mailbox_id) {
        voicemailUserRefs[user.mailbox_id] = user;
      }
    }

    const ivrMenus = this.em.getRepository<IvrCustomMenuRepository>('IvrCustomMenuRepository').getObjectList();
    for (const ivrMenu of ivrMenus) {
      ivrMenuRefs[ivrMenu.id] = ivrMenu;
    }

    const crmRoutingRules = this.em.getRepository<CrmRoutingRuleRepository>('CrmRoutingRuleRepository').getObjectList();
    for (const item of crmRoutingRules) {
      crmRoutingRuleRefs[item.id] = item;
    }

    const ivrEvaluations = this.em.getRepository<IvrEvaluationRepository>('IvrEvaluationRepository').getObjectList();
    for (const item of ivrEvaluations) {
      ivrEvaluationRefs[item.id] = item;
    }

    const queues = this.em.getRepository<CallQueueRepository>('CallQueueRepository').getObjectList();
    for (const queue of queues) {
      queueIdRefs[queue.id] = queue;

      // if (queue.voicemail_id) {
      //   voicemailQueueRefs[queue.voicemail_id] = queue;
      // }
    }

    const calendars = this.em.getRepository<OpeningHourCalendarRepository>('OpeningHourCalendarRepository').getObjectList();
    for (const item of calendars) {
      calendarRefs[item.id] = item;
    }

    const usedDidList = [];
    const queueIdList = [];
    const ivrMenuIdList = [];
    const userWtcList = [];
    const userWtcIds = [];
    const userVoicemailIds = [];
    const queueVoicemailIds = [];
    const crmRoutingRuleIds = [];
    const ivrEvaluationIds = [];
    const calendarIds = [];
    const enterpriseTelecomParamIds = [];

    for (const item of data) {
      const entityName: string = item[0];
      const entityId: number = +item[1];

      const repo = this.em.getRepositoryById(entityName);
      if (!repo) {
        console.error('No repo with name' + entityName);
        continue;
      }

      if (entityName === EnterpriseTelecomParamsEntity.object_type_id) {
        enterpriseTelecomParamIds.push(entityId);
      }

      if (repo instanceof DidRepository) {
        const usedDid: DidEntity = repo.getObjectById(entityId);
        if (usedDid && !_.includes(usedDidList, usedDid)) {
          usedDidList.push(usedDid);
        }
      }

      if (repo instanceof CallQueueRepository) {
        const usedQueue: CallQueueEntity = repo.getObjectById(entityId);
        if (usedQueue) {
          const didList: DidEntity[] = usedQueue.get_did_list();
          for (const d of didList) {
            if (!usedDidList.find(x => x.id === d.id)) {
              usedDidList.push(d);
            }
          }
          queueIdList.push(entityId);
        }
      }

      if (repo instanceof UserRepository) {
        userWtcIds.push(entityId);
      }

      if (repo instanceof VoiceMailBoxRepository) {
        const entity: VoiceMailBoxEntity = repo.getObjectById(entityId);
        if (entity.isMailboxTypeQueue()) {
          queueVoicemailIds.push(entityId);
        }
        if (entity.isMailboxTypeUser()) {
          userVoicemailIds.push(entityId);
        }
      }

      if (repo instanceof IvrCustomMenuRepository) {
        ivrMenuIdList.push(entityId);

        const ivrMenu: IvrCustomMenuEntity = repo.getObjectById(entityId);
        if (ivrMenu) {
          const didList: DidEntity[] = ivrMenu.routedDids;
          for (const d of didList) {
            if (!usedDidList.find(x => x.id === d.id)) {
              usedDidList.push(d);
            }
          }
        }
      }

      if (repo instanceof CrmRoutingRuleRepository) {
        crmRoutingRuleIds.push(entityId);
      }

      if (repo instanceof IvrEvaluationRepository) {
        ivrEvaluationIds.push(entityId);
      }

      if (repo instanceof OpeningHourCalendarRepository) {
        calendarIds.push(entityId);
      }
    }

    const ret: ObjectUsingFileEntry = {};
    if (usedDidList.length > 0) {
      ret['did'] = usedDidList;
    }

    if (queueIdList.length > 0) {
      ret['call_queue'] = queueIdList.filter(x => !!queueIdRefs[x] && queueIdRefs[x].id).map(x => queueIdRefs[x]);
    }

    if (ivrMenuIdList.length > 0) {
      ret['ivr_menu'] = ivrMenuIdList.filter(x => !!ivrMenuRefs[x] && ivrMenuRefs[x].id).map(x => ivrMenuRefs[x]);
    }

    if (userVoicemailIds.length > 0) {
      ret['user'] = userVoicemailIds.filter(x => !!voicemailUserRefs[x] && voicemailUserRefs[x].id).map(x => voicemailUserRefs[x]);
    }

    if (userWtcIds.length > 0) {
      ret['user_wtc'] = userWtcIds.filter(x => !!userWtcRefs[x] && userWtcRefs[x].id).map(x => userWtcRefs[x]);
    }

    if (crmRoutingRuleIds.length > 0) {
      ret['crm_routing_rule'] = crmRoutingRuleIds.filter(x => !!crmRoutingRuleRefs[x] && crmRoutingRuleRefs[x].id).map(x => crmRoutingRuleRefs[x]);
    }

    if (ivrEvaluationIds.length > 0) {
      ret['ivr_evaluation'] = ivrEvaluationIds.filter(x => !!ivrEvaluationRefs[x] && ivrEvaluationRefs[x].id).map(x => ivrEvaluationRefs[x]);
    }

    if (calendarIds.length > 0) {
      ret['work_calendar'] = calendarIds.filter(x => !!calendarRefs[x] && calendarRefs[x].id).map(x => calendarRefs[x]);
    }

    if (enterpriseTelecomParamIds.length > 0) {
      const enterpriseTelecomParamId = enterpriseTelecomParamIds[0];
      const enterpriseTelecomParam: EnterpriseTelecomParamsEntity = await this.em.getRepository('EnterpriseTelecomParamsRepository')
        .findById(enterpriseTelecomParamId);
      ret['enterprise_telecom_params'] = [enterpriseTelecomParam];
    }

    return ret;
  }

  async getInUsedObjects(fileEntryList: FileEntryEntity[], limit: number = 5): Promise<any> {
    const ret = {};

    const fileEntryIds: number[] = fileEntryList.map(f => f.id);
    const inUsedObjects = await this.getObjectsUsedFileEntryByIds(fileEntryIds);

    // for (const fileEntry of fileEntryList) {
    //   // get in-used objects
    //   const inUsedObjects = await this.getObjectsUsedFileEntryById(fileEntry.id);
    for (const obj of Object.keys(inUsedObjects)) {
      if (!ret[obj]) {
        ret[obj] = [];
      }

      for (const item of inUsedObjects[obj]) {
        if (ret[obj].indexOf(item) === -1 && ret[obj].length < limit) {
          ret[obj].push(item);
        }
      }
    }
    // }

    return ret;
  }
}
