<form [formGroup]="form" class="pa-1" *ngIf="form">
  <h2>{{ 'public.multipleedit'|translate:{number:editingList.length} }}</h2>
  <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
    <mat-form-field fxFlex="calc(50-10px)" fxFlex.xs="100">
      <mat-label>{{ 'user.content.password'|translate }}</mat-label>
      <input matInput placeholder="{{ 'user.content.password'|translate }}" [type]="hidePassword ? 'password' : 'text'"
        formControlName="password" [errorStateMatcher]="matcher">
      <mat-icon matSuffix (click)="hidePassword = !hidePassword"
        title="{{ (hidePassword ? 'public.show_pw' : 'public.hide_pw')|translate }}">{{
        hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      <mat-error [flexFormControlError]="form.get('password')"></mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="calc(50-10px)" fxFlex.xs="100">
      <mat-label>{{ 'user.content.calling_profile'|translate }}</mat-label>
      <calling-profile-select-form-input formControlName="calling_profile"
        placeholder="{{ 'user.content.calling_profile' | translate }}">
      </calling-profile-select-form-input>
    </mat-form-field>

    <mat-form-field fxFlex="calc(50-10px)" fxFlex.xs="100">
      <mat-label>{{ 'user.content.calling_number'|translate }}</mat-label>
      <did-select-form-input formControlName="calling_number"
        placeholder="{{ 'user.content.calling_number' | translate }}"></did-select-form-input>
      <button mat-icon-button matSuffix type="button" (click)="removeControlValue('calling_number')"
        [disabled]="!hasControlValue('calling_number')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- <mat-form-field fxFlex="100">
      <mat-label>{{ 'user_type_names.user'|translate }}</mat-label>
      <user-select-form-input [hideDisableUsers]='true' formControlName="user"
        placeholder="{{ 'user_type_names.user' | translate }}">
      </user-select-form-input>
      <button mat-icon-button matSuffix type="button" (click)="removeControlValue('user')"
        [disabled]="!hasControlValue('user')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field> -->

    <mat-form-field fxFlex="100">
      <mat-label>{{ 'sipphone.content.description'|translate }}</mat-label>
      <textarea matInput formControlName="description"
        placeholder="{{ 'sipphone.content.description' | translate }}"></textarea>
    </mat-form-field>
  </div>
</form>
