<h2 mat-dialog-title class="ma-0">
    {{ data.title || ('dialogs.confirmation'|translate) }}
</h2>
<mat-dialog-content>
    <span style="white-space:pre-wrap;">{{ data.message }}</span>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center" *ngIf="showButtons">
  <button *ngIf="showButtons.indexOf('no') > -1"
    data-ci="button-confirm-no" mat-button mat-dialog-close class="font-weight-bold">{{ 'dialogs.no'|translate }}</button>

  <button *ngIf="showButtons.indexOf('yes') > -1"
    data-ci="button-confirm-yes" mat-button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="ok()" class="font-weight-bold">{{ 'dialogs.yes'|translate }}</button>

  <button *ngIf="showButtons.indexOf('ok') > -1"
    data-ci="button-confirm-yes" mat-button mat-raised-button color="primary" [mat-dialog-close]="true" class="font-weight-bold">{{ 'dialogs.ok'|translate }}</button>

</mat-dialog-actions>
