import { Injectable } from '@angular/core';
import { EnterpriseEntity } from '@wephone-core/model/entity/enterprise';
import { EnterpriseRepository } from '@wephone-core/model/repository/enterprise';
import { SingletonBase } from '@wephone-utils';
import * as _ from 'lodash';
import * as ct from 'countries-and-timezones';
import * as clm from 'country-locale-map';
import { EntityManager } from './entity_manager';

interface TimezoneCt {
  name: string; // 'Mexico/BajaSur',
  country: string; // 'MX',
  utcOffset: number; // -420,
  utcOffsetStr: string; // '-07:00',
  dstOffset: number; // -360,
  dstOffsetStr: string; // '-06:00',
  aliasOf: string; // 'America/Mazatlan'
}

export interface CountryGlobal {
  code2: string;
  name: string;
  timezones: string[];
}


export interface Timezone {
  label: string;
  value: string;
}
@Injectable()
export class LocalManager extends SingletonBase {
  static DefaultLocaleCode = 'en_GB'; // According to country-locale-map standard format
  static UiLocales = [
    'en_GB',
    'pt_BR',
    'fr_FR',
    'vi_VN'
  ];

  static getInstance(): LocalManager {
    return super.getInstance();
  }

  // Get language code from locale: 'en_GB' => 'en'
  private getLangCodeFromLocale(locale: string): string {
    return locale.split('_')[0].toLowerCase();
  }

  /**
   * 
   * @returns Default locale code for file-entry: 'en'|'fr'|'vi'
   */
  getLangForDefaultFileEntry(): string {
    let locale = this.getMyEnterpriseDefaultLocale();
    if (!_.includes(LocalManager.UiLocales, locale)) {
      locale = LocalManager.DefaultLocaleCode;
    }

    return this.getLangCodeFromLocale(locale);
  }

  getDefaultLangForSite(countryCode2: string): string {
    const locale = this.getLocaleFromCountryCode2(countryCode2);
    let langCode = this.getLangCodeFromLocale(locale);
    if (langCode === 'pt') {
      langCode = 'pt_BR';
    }

    return langCode;
  }

  /**
   * Get default locale of my enterprise: 'en_GB', 'fr_FR', 'vi_VN'...
   */
  getMyEnterpriseDefaultLocale(): string {
    const myEnterprise: EnterpriseEntity = (EntityManager.getRepository('EnterpriseRepository') as EnterpriseRepository).getMyEnterprise();
    let ret: string = LocalManager.DefaultLocaleCode;
    if (myEnterprise && myEnterprise.country) {
      ret = this.getLocaleFromCountryCode2(myEnterprise.country.toUpperCase());
    }

    return ret;
  }

  private getLocaleFromCountryCode2(countryCode2: string): string {
    return clm.getLocaleByAlpha2(countryCode2.toUpperCase()); // en_GB
  }

  /**
   * @param countryCode : country-code-2-letters
   */
  getTimezonesByCountry(countryCode: string): Timezone[] {
    const timezones: TimezoneCt[] = ct.getTimezonesForCountry(countryCode.toUpperCase());

    if (!timezones || !timezones.length) {
      return;
    }
    // const timezone = timezones[0];
    const ret: Timezone[] = [];
    for (const tz of timezones) {
      ret.push({
        value: tz.name,
        label: tz.name
      });
    }

    return ret;
  }

  // [br: [
  //   {
  //       'AMERICA/ARAGUAINA': 'America > Araguaina'
  //   }, ]
  // ]
  getAllTimezones(): Timezone[] {
    let ret = [];
    const list: string[] = this.getCountryCodeList();
    for (const countryCode of list) {
      ret = ret.concat(this.getTimezonesByCountry(countryCode));
    }
    return ret;
  }

  getCountryByCode2(code2: string): CountryGlobal {
    const countries: CountryGlobal[] = this.getAllCountries();
    return countries.find(x => x.code2 === code2);
  }

  getAllCountries(): CountryGlobal[] {
    const countryList: {[key: string]: {
      id: string;
      name: string;
      timezones: string[];
    }} = ct.getAllCountries();

    const ret: CountryGlobal[] = [];
    for (const key of Object.keys(countryList)) {
      const item = countryList[key];
      ret.push({
        code2: item.id,
        name: item.name,
        timezones: item.timezones
      });
    }

    return ret;
  }

  private standardizeTz(tz: string): string {
    let timezone: string = tz.toLowerCase();
    timezone = timezone.charAt(0).toUpperCase() + timezone.slice(1);
    const capSplitChars = ['/', '_'];
    for (const c of capSplitChars) {
      const pos = timezone.indexOf(c);
      if (pos !== -1 && pos !== timezone.length) {
        timezone = timezone.substring(0, pos + 1) + timezone.charAt(pos + 1).toUpperCase() + timezone.slice(pos + 2);
      }
    }

    return timezone;
  }

  /**
   *
   * @param value : timezone, e.g: AMERICA/ARAGUAINA
   */
  getTimezoneByValue(value: string): Timezone {
    if (!value) {
      return;
    }

    let timezone: TimezoneCt = ct.getTimezone(value);
    if (!timezone) {
      // Should standardize timezone value because in some cases timezone stored in database was uppercase
      const tzStd: string = this.standardizeTz(value);
      timezone = ct.getTimezone(tzStd);
    }

    return timezone ? {
      label: timezone.name,
      value: timezone.name
    } : undefined;
  }

  /**
   *
   * @returns Array of country-code (uppercase)
   */
  private getCountryCodeList(): string[] {
    const allCountries: {[key: string]: {
      id: string;
      name: string;
      timezones: string[];
    }} = ct.getAllCountries();

    return Object.keys(allCountries);
  }
}
