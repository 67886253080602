<mat-select #mySelect
  [disabled]="!enabled"
  [formControl]="matSelectControl"
  [multiple]="multiple"
  [compareWith]="compareFn"
  [placeholder]="placeholder"
  (selectionChange)="onSelectionChange($event)">
  <mat-select-trigger>
    <ng-container *ngIf="!selectTriggerTemplate">
      <mat-chip-list #chipList *ngIf="multiple && matSelectControl.value?.length > 0">
        <mat-chip class="square" *ngFor="let selectedItem of matSelectControl.value" [selectable]="false" [removable]="true"
          [disabled]="disabled"
          (removed)="removeItem(selectedItem)">
          <mat-icon matChipRemove>clear</mat-icon>
          <span *ngIf="!itemTemplate">{{ selectedItem | truncate }}</span>
          <ng-container *ngTemplateOutlet="itemTemplate;context:{item:selectedItem,truncate:true}"></ng-container>
        </mat-chip>
      </mat-chip-list>
      <ng-container *ngIf="!multiple && matSelectControl.value">
        <span *ngIf="!itemTemplate">{{ matSelectControl.value | truncate }}</span>
        <ng-container *ngTemplateOutlet="itemTemplate;context:{item:matSelectControl.value,truncate:false}"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="selectTriggerTemplate;context:{selectedValue:matSelectControl.value}"></ng-container>
  </mat-select-trigger>
  <ngx-mat-select-search data-ci="select-search" [formControl]="filterFormControl" placeholderLabel="{{ 'public.search' | translate }}"
    noEntriesFoundLabel="{{ 'public.message.no_results' | translate }}">
  </ngx-mat-select-search>

  <div *ngIf="multiple && showCheckAll" class="select-all">
    <mat-checkbox [indeterminate]="selectAllIndeterminate" [checked]="selectAllChecked" (change)="selectAll($event)" >
      Select all
    </mat-checkbox>
  </div>

  <mat-option *ngFor="let item of filteredItems | async" [value]="item" [disabled]="disabledOptionFn(item)">
    <span *ngIf="!itemTemplate">{{ item }}</span>
    <ng-container *ngTemplateOutlet="itemTemplate;context:{item:item,truncate:false}"></ng-container>
  </mat-option>
</mat-select>
