import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IChangeAwareComponent, EditingComponent, FormService, NoWhitespaceValidator } from '@wephone-utils';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CallQueueEntity } from '@wephone-core/model/entity/callqueue';
import { TranslateService } from '@ngx-translate/core';
import { _tk, _ti } from '@wephone-translation';

@Component({
  selector: 'queue-edit-advance',
  templateUrl: './queue-edit-advance.component.html',
  styleUrls: ['./queue-edit-advance.component.scss']
})
export class QueueEditAdvanceComponent extends EditingComponent implements OnInit, IChangeAwareComponent {
  @Input() queue: CallQueueEntity;
  @Input() multipleEdit?: boolean;
  @Output() readonly formValueChanges: EventEmitter<boolean>;

  readonly agentDisplayNumberDidNumber: number;
  agentDisplayNumberTypes: {
    value: number;
    label: string;
  }[];

  constructor(public formService: FormService, protected fb: FormBuilder, transService: TranslateService) {
    super();
    this.formValueChanges = new EventEmitter<boolean>();

    this.agentDisplayNumberDidNumber = CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER;
    this.agentDisplayNumberTypes = [
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_CUSTOMER_PHONE,
        label: _ti('call_queue.content.display_number.agent_display.client_phone')
      },
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_CALLER_NUMBER,
        label: _ti('call_queue.content.display_number.agent_display.caller_number')
      },
      {
        value: CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER,
        label: _ti('call_queue.content.display_number.agent_display.did_number')
      }
    ];
  }

  get aliasControl(): FormControl {
    return this.form.get('alias') as FormControl;
  }

  get xapiAgentSearchControl(): FormControl {
    return this.form.get('xapi_agent_search') as FormControl;
  }

  get agentDisplayNumberTypeControl(): FormControl {
    return this.form.get('agent_display_number_type') as FormControl;
  }

  get agentDisplayNumberControl(): FormControl {
    return this.form.get('agent_display_number') as FormControl;
  }

  get isExclusiveControl(): FormControl {
    return this.form.get('is_exclusive') as FormControl;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormGroup();
  }

  async submitForm(): Promise<any> { }

  resetForm(): void {
    if (!this.form) {
      return;
    }
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  private getFormResetData(): object {
    return {
      alias: this.queue.alias,
      xapi_agent_search: this.queue.xapi_agent_search,
      agent_display_number_type: this.queue.agent_display_number_type,
      agent_display_number: this.queue.agent_display_number,
      is_exclusive: !!this.queue.is_exclusive,
      analysis_enabled: !!this.queue.analysis_enabled,
      analysis_start_dt: this.queue.analysis_start_dt && this.queue.analysis_start_dt.toJSDate() || null,
    };
  }

  getChangeSet(): object {
    const data = {};
    const formData = super.getChangeSet();
    for (const f of Object.keys(formData)) {
      switch (f) {
        case 'is_exclusive':
        case 'analysis_enabled':
          data[f] = formData[f] ? 1 : 0;
          break;
        case 'analysis_start_dt':
          // skip update analysis_start_dt if analysis_enabled is disabled
          const analysisEnabled: boolean = formData.hasOwnProperty('analysis_enabled') && formData.analysis_enabled || !!this.queue.analysis_enabled;
          if (analysisEnabled) {
            data[f] = formData[f];
          }
          break;
        default:
          data[f] = formData[f];
      }
    }

    return data;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      alias: [this.queue.alias, [NoWhitespaceValidator, Validators.maxLength(256)]],
      xapi_agent_search: [this.queue.xapi_agent_search, [NoWhitespaceValidator, Validators.maxLength(256)]],
      agent_display_number_type: [this.queue.agent_display_number_type],
      agent_display_number: [this.queue.agent_display_number],
      is_exclusive: [!!this.queue.is_exclusive],
      analysis_enabled: [!!this.queue.analysis_enabled],
      analysis_start_dt: [this.queue.analysis_start_dt ? this.queue.analysis_start_dt.toJSDate() : null],
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  private validateFormControls(fieldList: string[]): void {
    if (!this.multipleEdit && _.intersection(fieldList, ['analysis_enabled', 'analysis_start_dt']).length) {
      // Validate analysis_start_dt
      const analysisEnabledControl = this.form.get('analysis_enabled');
      const analysisStartDtControl = this.form.get('analysis_start_dt');
  
      analysisStartDtControl.setErrors(null);
      if (analysisEnabledControl.value && !analysisStartDtControl.value) {
        analysisStartDtControl.setErrors({ required: true });
        analysisStartDtControl.markAsTouched();
      }
    }

    if (!this.multipleEdit && _.intersection(fieldList, ['agent_display_number']).length) {
      // Validate agent_display_number
      this.agentDisplayNumberControl.setErrors(null);
      const agentDisplayNumber = this.agentDisplayNumberControl.value;
      if (this.agentDisplayNumberTypeControl.value === CallQueueEntity.AGENT_DISPLAY_NUM_DID_NUMBER && !agentDisplayNumber) {
        this.agentDisplayNumberControl.setErrors({ required: true });
        this.agentDisplayNumberControl.markAsTouched();
      }
    }
  }

  isValid(fieldList: string[] = []): boolean {
    this.validateFormControls(fieldList);

    if (!this.formIsValid()) {
      this.form.markAllAsTouched();
      return false;
    }

    return true;
  }
}
