import { NgModule, ModuleWithProviders } from '@angular/core';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateService } from '@ngx-translate/core';

import { WephoneTranslateLoader } from './wephone-translation-loader';
import { WephoneMissingTranslationHandler } from './wephone-missing-translation-handler';
import { TranslationService } from './translation.service';
import { CookieService } from '@wephone-utils/services/cookie.service';
// import { CookieService } from 'ngx-cookie-service';

export function createTranslateLoader(http: HttpClient) {
  return new WephoneTranslateLoader(http);
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: WephoneMissingTranslationHandler }
    })
  ],
  exports: [TranslateModule, HttpClientModule]
})
export class WephoneTranslationModule {
  static instance: WephoneTranslationModule = undefined;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
  ) {
    this.setLocale();
  }

  static forRoot(): ModuleWithProviders<WephoneTranslationModule> {
    return {
      ngModule: WephoneTranslationModule,
      providers: [
        TranslationService
      ],
    };
  }

  private async setLocale(): Promise<void> {
    this.translate.addLangs(['en', 'fr', 'vi', 'pt_BR']);
    this.translate.setDefaultLang(TranslationService.defaultLang);
    let browserLang: string = await this.cookieService.get(TranslationService.cookieName);

    if (!browserLang) {
      browserLang = this.translate.getBrowserLang();
    }

    this.translate.use(browserLang.match(/en|fr|vi|pt_BR/) ? browserLang : TranslationService.defaultLang);
  }
}
