import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  private onClickYes: any;
  showButtons = ['no', 'yes'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.onClickYes = data.onConfirm;
    if (data.showButtons) {
      this.showButtons = data.showButtons;
    }
  }

  ok(): void {
    if (this.onClickYes) {
      this.onClickYes();
    }
  }
}
