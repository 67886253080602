import * as _ from 'lodash';
import { Component, Inject } from '@angular/core';
import { DialogComponentBase } from '@wephone-core-ui';
import { Colors, DialogActionButton, IFlexDialogConfig, StringUtils } from '@wephone-utils';
import { _tk, _ti } from '@wephone-translation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRecordingCall, RCAnalysisState, RCTranscriptionState, TranscriptAnalysisData } from '@wephone/pages/recording-call/recording-call.i';
import { RecordingService } from '@wephone-core/service/recording_service';


@Component({
  selector: 'app-recording-call-analysis-result',
  templateUrl: './recording-call-analysis-result.component.html',
  styleUrls: ['./recording-call-analysis-result.component.scss']
})
export class RecordingCallAnalysisResultComponent extends DialogComponentBase {
  static modalConfig: IFlexDialogConfig = {
    size: 'l'
  };

  dialogTitle = _tk('recording_call.content.transcription_detail');

  dialogRightActions: DialogActionButton[] = [
    {
      label: _tk('recording_call.content.retry_enable_analysis'),
      action: () => {
        this.relauchTranscriptionAnalysis();
      },
      visible: () => {
        return !(this.item.analysis_state === RCAnalysisState.AnalysisDone && this.item.transcription_state === RCTranscriptionState.TranscriptionDone);
      },
      icon: 'insights',
      color: Colors.PRIMARY
    },
  ];

  item: IRecordingCall;
  analysisResult: TranscriptAnalysisData;
  transcriptionText: string;

  constructor(
    dialogRef: MatDialogRef<RecordingCallAnalysisResultComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: { item: IRecordingCall },
    private readonly recordingService: RecordingService,
  ) {
    super(dialogRef);
    this.item = data.item;

    if (this.item.analysis_state === RCAnalysisState.AnalysisDone && this.item.analysis_result) {
      this.analysisResult = typeof this.item.analysis_result === 'string' ? JSON.parse(this.item.analysis_result) : this.item.analysis_result;
    }

    if (this.item.transcription_state === RCTranscriptionState.TranscriptionDone && this.item.transcription) {
      this.transcriptionText = StringUtils.alignTranscriptionText(this.item.transcription, '<br>');
    }
  }

  getKeyAnalysis(): string[] {
    return this.analysisResult && this.analysisResult.json_result ? Object.keys(this.analysisResult.json_result) : [];
  }

  getKeyAnalysisUuid(): string[] {
    return ['analysis_date', 'transcript_analysis_uuid'];
  }

  async relauchTranscriptionAnalysis(): Promise<void> {
    try {
      const item: IRecordingCall = this.item;
      await this.recordingService.setEnableAnalysis(item.id);
      item.analysis_enabled = 1;
      item.analysis_state = RCAnalysisState.NoAnalysis;
      item.transcription_state = RCTranscriptionState.NoTranscription;

      this.showInfo(_ti('recording_call.message.enable_analysis_success'));
      this.dialogRef.close(item);
    } catch (error) {
      console.error('Set Enable Transcription Analysis Error: ', error);
      this.showErrorMessage(error, _ti('recording_call.message.enable_analysis_failure'));
    }
  }

}
