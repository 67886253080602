import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IChangeAwareComponent, EditingComponent, FormService } from '@wephone-utils';
import { UserEntity } from '@wephone-core/model/entity/user';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-api',
  templateUrl: './user-api.component.html',
  styleUrls: ['./user-api.component.scss']
})
export class UserApiComponent extends EditingComponent implements OnInit, OnChanges, IChangeAwareComponent {
  @Input() user: UserEntity;
  @Output() readonly formValueChanges: EventEmitter<boolean>;

  private hasInitalized = false;
  constructor(public formService: FormService, protected fb: FormBuilder) {
    super();
    this.formValueChanges = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.initialize();
  }

  ngOnChanges(): void {
    if (!this.hasInitalized) {
      return;
    }
    // this.initForm();
    this.initialize();
  }

  async submitForm(): Promise<any> {}

  resetForm(): void {
    this.form.reset(this.getFormResetData());
    this.form.markAsPristine();
    this.onFormValueChange();
  }

  onFormValueChange(): void {
    this.formValueChanges.next(this.formHasChanged());
  }

  private getFormResetData(): any {
    return {
      alias: this.user.alias
    };
  }

  getChangeSet(): any {
    return this.formService.getChangeSet(this.form);
  }

  initialize(): void {
    this.initFormGroup();
    this.hasInitalized = true;
  }

  initFormGroup(): void {
    this.form = this.fb.group({
      alias: [this.user.alias]
    });

    this.addSubscription(
      this.form.valueChanges.subscribe(changes => {
        this.onFormValueChange();
      })
    );
  }

  isValid(): boolean {
    return this.form.valid;
  }
}
